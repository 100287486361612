import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Loader } from "./components/Common/Loader";
import { AuthenticatedRoute } from "./components/Common/RouteComponents";
// import Dashboard from "./components/Dashboard/Dashboard";
import AppLayout from "./components/AppLayout/AppLayout";
import AuditLog from "./components/AuditLogs/AuditLog";
import Queries from "./components/Queries/Queries";
import AuthRoutes from "./components/AuthLayout/AuthRoutes";
import StudyWrapper from "./components/Study/Study";
import QuestionRoutes from "./components/Questions/QuestionRoutes";
import CRF from "./components/CRF/CRF";
import Administrator from "./components/Administrators/Administrator";
import Participants from "./components/Participants/Participants";
import IncidentReporting from "./components/IncidentReporting/IncidentReporting";
import Settings from "./components/Study/Settings";
import EPro from "./components/CRF/EPro";
import DeleteAccount from "./components/DeleteAccount/DeleteAccount";

// const CMSRoutes = React.lazy(() => import("./components/CMS/CmsRoutes"));

// const Chat = React.lazy(() => import("./components/Chat/Chat"));

// const PatientDiaryRoutes = React.lazy(
//   () => import("./components/PatientDiary/PatientDiaryRoutes")
// );

// const AppointmentRoutes = React.lazy(
//   () => import("./components/Appointments/AppointmentRoutes")
// );

// const FolderDocument = React.lazy(
//   () => import("./components/Documents/FolderDocument")
// );

// const ExceriseDiary = React.lazy(
//   () => import("./components/Diary/ExerciseDiaryList")
// );

// const WebviewWrapper = React.lazy(
//   () => import("./components/CMS/Webview/WebviewWrapper")
// );

function AllRoutes() {
  return (
    <React.Suspense fallback={<Loader />}>
      <Routes>
        {/* Open routes */}
        {/* <Route path="/lesson/read/:id" element={<WebviewWrapper />} /> */}
        {/* AuthenticatedRoutes */}
        <Route path="/auth/*" element={<AuthRoutes />} />
        <Route element={<AppLayout />}>
          <Route
            path="/study"
            element={
              <AuthenticatedRoute>
                <StudyWrapper />
              </AuthenticatedRoute>
            }
          />
          <Route path="/question/*" element={<QuestionRoutes />} />
          {/* <Route path="/cms/*" element={<CMSRoutes />} /> */}
          <Route
            path="/dashboard"
            element={
              <AuthenticatedRoute>
                <Navigate to="/participants" />
              </AuthenticatedRoute>
            }
          />
          {/* <Route path="/appointments/*" element={<AppointmentRoutes />} /> */}
          {/* <Route path="/patient-diary/*" element={<PatientDiaryRoutes />} /> */}
          <Route
            path="/participants"
            element={
              <AuthenticatedRoute>
                <Participants />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/crf/:crfType/:id"
            element={
              <AuthenticatedRoute>
                <CRF />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/ePro/:id"
            element={
              <AuthenticatedRoute>
                <EPro />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/administrator"
            element={
              <AuthenticatedRoute>
                <Administrator />
              </AuthenticatedRoute>
            }
          />
          {/* <Route
            path="/diary"
            element={
              <AuthenticatedRoute>
                <ExceriseDiary />
              </AuthenticatedRoute>
            }
          /> */}
          <Route
            path="/audit-logs"
            element={
              <AuthenticatedRoute allowedRoles={["system_admin"]}>
                <AuditLog />
              </AuthenticatedRoute>
            }
          />
          {/* <Route
            path="/documents/:docId"
            element={
              <AuthenticatedRoute>
                <FolderDocument />
              </AuthenticatedRoute>
            }
          /> */}
          {/* <Route
            path="/chat/:id"
            element={
              <AuthenticatedRoute>
                <Chat />
              </AuthenticatedRoute>
            }
          /> */}
          <Route
            path="/queries"
            element={
              <AuthenticatedRoute>
                <Queries />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/adverse-events"
            element={
              <AuthenticatedRoute>
                <IncidentReporting />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <AuthenticatedRoute>
                <Settings />
              </AuthenticatedRoute>
            }
          />
        </Route>
        <Route path="/delete_your_account" element={<DeleteAccount />} />
        <Route path="/" element={<Navigate to="/auth/login" />} />
        <Route path="*" element={<Navigate to="/auth/login" />} />
      </Routes>
    </React.Suspense>
  );
}

export default AllRoutes;
