import { DotsVerticalIcon } from "@heroicons/react/solid";
import { Dropdown, Menu, Table, Modal, Button } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { errorToastMessage } from "../../helpers/toastMessage";
import http from "../../http";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setAppLoader } from "../../redux/reducers/loaderSlice";
import { setModalDetails } from "../../redux/reducers/modalSlice";
import { canEditMedicalHistory } from "../../helpers/roles";

const { confirm } = Modal;

const MedicalHistory: React.FC<any> = (props) => {
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.user.role);
  const { id } = useParams();
  const [data, setData] = useState<any[]>([]);
  const [toggle, setToggle] = useState(false);
  const [hasEditAccess] = useState(canEditMedicalHistory(role));

  useEffect(() => {
    const fetchMedicalHistory = async (id: string) => {
      try {
        dispatch(setAppLoader(true));
        const res = await http.get(`/mh/user_medical_history?userId=${id}`);
        const medicalData = res.data.data.map((med: any) => {
          return {
            id: med.id,
            title: med.title,
            startDate: med.startDate
              ? moment(med.startDate).format("DD/MM/YYYY")
              : null,
            endDate: med.endDate
              ? moment(med.endDate).format("DD/MM/YYYY")
              : null,
            isOngoing: med.isOngoing,
            comment: med.comment,
            versions: med?.versions?.map((version: any) => {
              return {
                id: version.id,
                reason: version.reason || "-",
                updatedDate: moment(version.createdAt).format(
                  "DD/MM/YYYY hh:mm A"
                ),
                user: version?.who
                  ? version?.who?.firstName + " " + version?.who?.lastName
                  : "-",
                newValue: version?.newValues,
                prevValue: version?.prevValues,
              };
            }),
          };
        });
        setData(medicalData);
        dispatch(setAppLoader(false));
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    };
    if (id) {
      fetchMedicalHistory(id);
    }
  }, [id, toggle, dispatch, setData]);

  const toggler = () => {
    setToggle((prev) => !prev);
  };

  const addMedHistory = () => {
    dispatch(
      setModalDetails({
        type: "ADD_MEDICAL_HISTORY",
        modalProps: {
          show: true,
          userId: id,
          callback: toggler,
        },
      })
    );
  };

  const editMedHistory = (data: any) => {
    const { versions, ...rest } = data;
    dispatch(
      setModalDetails({
        type: "ADD_MEDICAL_HISTORY",
        modalProps: {
          show: true,
          userId: id,
          med: rest,
          callback: toggler,
        },
      })
    );
  };

  const deleteHandler = async (id: string) => {
    try {
      dispatch(setAppLoader(true));
      await http.delete(`/mh/user_medical_history/${id}`);
      toggler();
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  const deleteHistory = (id: string) => {
    confirm({
      title: "Are you sure?",
      content: "This medical history will no longer be available.",
      onOk() {
        deleteHandler(id);
      },
      onCancel() {},
      okText: "Delete",
      cancelText: "Cancel",
    });
  };

  const viewLogs = (data: any) => {
    dispatch(
      setModalDetails({
        type: `MEDICAL_SUBMISSION_LOG`,
        modalProps: {
          show: true,
          versions: data?.versions,
        },
      })
    );
  };

  const menu = (data: any) => (
    <Menu>
      {hasEditAccess &&
        !props.locked && [
          <Menu.Item key="1" onClick={() => editMedHistory(data)}>
            Edit
          </Menu.Item>,
          <Menu.Item key="2" onClick={() => deleteHistory(data.id)}>
            Delete
          </Menu.Item>,
        ]}
      <Menu.Item key="3" onClick={() => viewLogs(data)}>
        View Logs
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "Disorder/Diagnosis/Procedure",
      dataIndex: "title",
      key: "title",
      render: (comment: string) => {
        return comment || "-";
      },
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "date",
      render: (date: string) => {
        return date || "-";
      },
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "date",
      render: (date: string) => {
        return date || "-";
      },
    },
    {
      title: "Ongoing",
      dataIndex: "isOngoing",
      key: "isOngoing",
      render: (ongoing: boolean) => {
        return ongoing ? "Yes" : "No";
      },
    },
    {
      title: "Comments",
      dataIndex: "comment",
      key: "comment",
      render: (comment: string) => {
        return comment || "-";
      },
    },
    {
      title: "",
      width: "20px",
      render: (_1: any, record: any) => {
        return (
          <span onClick={(e) => e.stopPropagation()}>
            <Dropdown
              overlay={menu(record)}
              placement="bottomRight"
              trigger={["click"]}
            >
              <DotsVerticalIcon
                className="hero-icon cp"
                style={{ color: "#71717A", marginLeft: "auto" }}
              />
            </Dropdown>
          </span>
        );
      },
    },
  ];

  return (
    <div className="crf-question-container">
      <div className="fw-semibold font-ml color-primary crf-header">
        <span>Medical History</span>
        {!props.locked && hasEditAccess && (
          <Button
            type="primary"
            style={{ height: "40px", borderRadius: "12px" }}
            className="me-4"
            onClick={addMedHistory}
          >
            <span className="fw-semibold font-sm">Add Medical History</span>
          </Button>
        )}
      </div>
      <div className="crf-subheader mb-4">
        <div className="text-prime font-sm fw-medium">
          Enter all medical history findings that have been present/active
          within the 3 years prior to enrollment; outside of 3 years enter
          history only if deemed clinically relevant by the Investigator. The
          medical history should include any history of allergic reactions to
          drugs, history of diagnosis, surgery, CT, etc.
        </div>
      </div>
      <Table
        dataSource={data}
        columns={columns}
        className="singer-custom-table"
        rowKey={(record) => record.id}
        pagination={false}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default MedicalHistory;
