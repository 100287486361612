import { Modal, Table } from "antd";
import moment from "moment";
import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { hideModal } from "../../../redux/reducers/modalSlice";
// import { frequencyMap } from "../../CRF/Medication";

const ViewMedicineVersionModal = () => {
  const dispatch = useAppDispatch();
  const { show, versions } = useAppSelector((state) => state.modal.modalProps);

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const columns: any[] = useMemo(() => {
    return [
      {
        title: "Revision Done By",
        dataIndex: "user",
        key: "user",
      },
      {
        title: "Revision Date",
        dataIndex: "updatedDate",
        key: "updatedDate",
      },
      {
        title: "Previous Value",
        dataIndex: "prevValue",
        key: "prevValue",
        render: (data: any) => {
          return (
            <>
              {data ? (
                <>
                  <div className="fw-semibold font-m text-prime mb-2">
                    {data?.name}
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">Category:</span>
                    <span>{data?.category || "-"}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">Type:</span>
                    <span>{data?.type || "-"}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      Start Date:
                    </span>
                    <span>
                      {data.startDate
                        ? moment(data.startDate).format("DD/MM/YYYY")
                        : "-"}
                    </span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">End Date:</span>
                    <span>
                      {data.endDate
                        ? moment(data.endDate).format("DD/MM/YYYY")
                        : "-"}
                    </span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      Indication:
                    </span>
                    <span>{data?.indication || "-"}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      Frequency:
                    </span>
                    <span>{data?.frequency || "-"}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">Dose:</span>
                    <span>{data?.dose || "-"}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">Unit:</span>
                    <span>{data?.unit || "-"}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      Dosage Form:
                    </span>
                    <span>{data?.dosageForm || "-"}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      Route Of Administration:
                    </span>
                    <span>{data?.route || "-"}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">Ongoing:</span>
                    <span>{data?.isOngoing ? "Yes" : "No"}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      Baseline Medication/ Therapy:
                    </span>
                    <span>{data?.isBaseline ? "Yes" : "No"}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      Continuing After Study:
                    </span>
                    <span>{data?.continueAfterStudy ? "Yes" : "No"}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">Remarks:</span>
                    <span>{data?.remarks || "-"}</span>
                  </div>
                </>
              ) : (
                "-"
              )}
            </>
          );
        },
      },
      {
        title: "New Value",
        dataIndex: "newValue",
        key: "newValue",
        render: (data: any) => {
          return (
            <>
              <div className="fw-semibold font-m text-prime mb-2">
                {data?.name}
              </div>
              <div className="mb-2">
                <span className="fw-medium text-prime me-1">Category:</span>
                <span>{data?.category || "-"}</span>
              </div>
              <div className="mb-2">
                <span className="fw-medium text-prime me-1">Type:</span>
                <span>{data?.type || "-"}</span>
              </div>
              <div className="mb-2">
                <span className="fw-medium text-prime me-1">Start Date:</span>
                <span>
                  {data.startDate
                    ? moment(data.startDate).format("DD/MM/YYYY")
                    : "-"}
                </span>
              </div>
              <div className="mb-2">
                <span className="fw-medium text-prime me-1">End Date:</span>
                <span>
                  {data.endDate
                    ? moment(data.endDate).format("DD/MM/YYYY")
                    : "-"}
                </span>
              </div>
              <div className="mb-2">
                <span className="fw-medium text-prime me-1">Indication:</span>
                <span>{data?.indication || "-"}</span>
              </div>
              <div className="mb-2">
                <span className="fw-medium text-prime me-1">Frequency:</span>
                <span>{data?.frequency || "-"}</span>
              </div>
              <div className="mb-2">
                <span className="fw-medium text-prime me-1">Dose:</span>
                <span>{data?.dose || "-"}</span>
              </div>
              <div className="mb-2">
                <span className="fw-medium text-prime me-1">Unit:</span>
                <span>{data?.unit || "-"}</span>
              </div>
              <div className="mb-2">
                <span className="fw-medium text-prime me-1">Dosage Form:</span>
                <span>{data?.dosageForm || "-"}</span>
              </div>
              <div className="mb-2">
                <span className="fw-medium text-prime me-1">
                  Route Of Administration:
                </span>
                <span>{data?.route || "-"}</span>
              </div>
              <div className="mb-2">
                <span className="fw-medium text-prime me-1">Ongoing:</span>
                <span>{data?.isOngoing ? "Yes" : "No"}</span>
              </div>
              <div className="mb-2">
                <span className="fw-medium text-prime me-1">
                  Baseline Medication/ Therapy:
                </span>
                <span>{data?.isBaseline ? "Yes" : "No"}</span>
              </div>
              <div className="mb-2">
                <span className="fw-medium text-prime me-1">
                  Continuing After Study:
                </span>
                <span>{data?.continueAfterStudy ? "Yes" : "No"}</span>
              </div>
              <div className="mb-2">
                <span className="fw-medium text-prime me-1">Remarks:</span>
                <span>{data?.remarks || "-"}</span>
              </div>
            </>
          );
        },
      },
    ];
  }, []);

  return (
    <Modal
      title={"Medication Submission Logs"}
      visible={show}
      onCancel={closeHandler}
      width={"80vw"}
      footer={null}
    >
      <Table
        dataSource={versions}
        columns={columns}
        className="singer-custom-table"
        rowKey={(record) => record.id}
        pagination={false}
      />
    </Modal>
  );
};

export default ViewMedicineVersionModal;
