import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setAppLoader } from "../../redux/reducers/loaderSlice";
import http from "../../http";
import moment from "moment";
import { errorToastMessage, toastMessage } from "../../helpers/toastMessage";
import { Button, Modal } from "antd";
import { setModalDetails } from "../../redux/reducers/modalSlice";
import { getStudyId } from "../../helpers/study";
import { canSignOffCRFs, modifyStudySettings } from "../../helpers/roles";

const confirm = Modal.confirm;

const settingsMap = [
  {
    label: "Study Name",
    key: "studyName",
  },
  {
    label: "Description",
    key: "description",
  },
  {
    label: "Start Date",
    key: "startDate",
  },
  {
    label: "End Date",
    key: "endDate",
  },
  {
    label: "Protocol Number",
    key: "protocolNumber",
  },
  {
    label: "Principal Investigator",
    key: "investigator",
  },
  {
    label: "Study Id",
    key: "studyId",
  },
  {
    label: "Site Id",
    key: "siteId",
  },
];

const Settings = () => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any>({});
  const [toggle, setToggle] = useState(false);
  const { role } = useAppSelector((state) => state.user);
  const [studyStatus, setStudyStatus] = useState("");
  const [hasEditAccess] = useState(modifyStudySettings(role));
  const [hasSignoffAccess] = useState(canSignOffCRFs(role));

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        dispatch(setAppLoader(true));
        const studyId = getStudyId();
        const res = await http.get(`/programs/${studyId}`);
        const newData = res.data.data;
        const settings = {
          studyName: newData?.title,
          description: newData?.description,
          startDate: newData?.startDate
            ? moment(newData?.startDate).format("DD/MM/YYYY")
            : null,
          endDate: newData?.endDate
            ? moment(newData?.endDate).format("DD/MM/YYYY")
            : null,
          protocolNumber: newData?.protocolNumber,
          investigator: newData?.principalnvestigator,
          studyId: newData?.studyId,
          siteId: newData?.siteId,
        };
        setData(settings);
        setStudyStatus(newData?.signerId ? "signed" : "live");
        dispatch(setAppLoader(false));
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    };
    fetchSettings();
  }, [toggle, dispatch, setData, setStudyStatus]);

  const toggler = () => {
    setToggle((prev) => !prev);
  };

  const handleButtonClick = () => {
    dispatch(
      setModalDetails({
        type: `EDIT_SETTINGS_MODAL`,
        modalProps: {
          show: true,
          data: data,
          callback: toggler,
        },
      })
    );
  };

  const handleSignoff = () => {
    const callback = async () => {
      try {
        dispatch(setAppLoader(true));
        const studyId = getStudyId();
        const res = await http.post(`programs/${studyId}/sign`);
        toastMessage("success", res.data.message);
        toggler();
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    };
    confirm({
      title: "Sign off study?",
      content:
        "Are you sure you'd like to sign off the study? This action can't be undone.",
      onOk() {
        callback();
      },
      onCancel() {},
      okText: "Yes",
      cancelText: "No",
    });
  };

  return (
    <div className="cms-wrapper">
      <div className="content-header">
        <div className="font-m fw-semibold font-l me-5 text-secondary">
          Settings
        </div>
        <span style={{ marginLeft: "auto" }}></span>
        {hasEditAccess && (
          <Button
            type="primary"
            className="d-flex align-items-center me-4"
            style={{ height: "40px", borderRadius: "12px" }}
            onClick={handleButtonClick}
          >
            <span className="fw-semibold font-sm">Edit Settings</span>
          </Button>
        )}
        {hasSignoffAccess && studyStatus === "live" && (
          <Button
            type="primary"
            className="d-flex align-items-center me-4"
            style={{ height: "40px", borderRadius: "12px" }}
            onClick={handleSignoff}
          >
            <span className="fw-semibold font-sm">Sign off Study</span>
          </Button>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "5%",
        }}
      >
        <div
          style={{
            border: "1px solid rgb(229, 231, 235)",
            borderRadius: "8px",
            backgroundColor: "#fff",
            minWidth: "35%",
            padding: "24px",
          }}
        >
          {settingsMap.map((item) => (
            <div key={item?.key}>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: 700,
                }}
              >
                {item?.label}
              </div>
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: 400,
                }}
              >
                {data?.[item.key] || "-"}
              </p>
            </div>
          ))}
          {studyStatus && (
            <div key={"status"}>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: 700,
                }}
              >
                Status
              </div>
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: 400,
                }}
              >
                {studyStatus === "live" ? "Live" : "Locked & Signed off"}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Settings;
