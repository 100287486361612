import {
  choice_types,
  date_types,
  text_types,
} from "../components/Questions/QuestionBuilder/questionTypes";

const CRFNumberTypes = (question: any, answers: any) => {
  const answer = answers[question.id];
  if (question.required) {
    if (!answer || typeof answer?.numberValue !== "number") {
      answers[question.id] = {
        numberValue: answer?.numberValue,
        remarkValue: answer?.remarkValue,
        textValue: answer?.textValue,
        error: "This is a required field",
      };
      return null;
    } else {
      return {
        questionId: question.id,
        questionType: question.type,
        response: {
          numberValue: answer.numberValue,
          remarkValue: answer.remarkValue,
          textValue: answer.textValue,
        },
      };
    }
  } else if (typeof answer?.numberValue === "number") {
    return {
      questionId: question.id,
      questionType: question.type,
      response: {
        numberValue: answer.numberValue,
        remarkValue: answer.remarkValue,
        textValue: answer.textValue,
      },
    };
  } else {
    return false;
  }
};

const CRFTextTypes = (question: any, answers: any) => {
  const answer = answers[question.id];
  if (question.required) {
    if (!answer || !answer?.textValue || !answer?.textValue?.trim()) {
      answers[question.id] = {
        textValue: answer?.textValue,
        remarkValue: answer?.remarkValue,
        error: "This is a required field",
      };
      return null;
    } else {
      return {
        questionId: question.id,
        questionType: question.type,
        response: {
          remarkValue: answer.remarkValue,
          textValue: answer.textValue,
        },
      };
    }
  } else if (answer?.textValue && answer?.textValue?.trim()) {
    return {
      questionId: question.id,
      questionType: question.type,
      response: {
        remarkValue: answer.remarkValue,
        textValue: answer.textValue,
      },
    };
  } else {
    return false;
  }
};

const CRFDateTypes = (question: any, answers: any) => {
  const answer = answers[question.id];
  if (question.required) {
    if (!answer || !answer?.dateValue) {
      answers[question.id] = {
        dateValue: answer?.dateValue,
        remarkValue: answer?.remarkValue,
        error: "This is a required field",
      };
      return null;
    } else {
      return {
        questionId: question.id,
        questionType: question.type,
        response: {
          remarkValue: answer.remarkValue,
          dateValue: answer.dateValue,
        },
      };
    }
  } else if (answer?.dateValue) {
    return {
      questionId: question.id,
      questionType: question.type,
      response: {
        remarkValue: answer.remarkValue,
        dateValue: answer.dateValue,
      },
    };
  } else {
    return false;
  }
};

const CRFMultipleChoice = (question: any, answers: any) => {
  const answer = answers[question.id];
  if (question.required) {
    if (
      !answer ||
      !answer?.questionChoiceId ||
      answer?.questionChoiceId.length === 0
    ) {
      answers[question.id] = {
        questionChoiceId: answer?.questionChoiceId,
        remarkValue: answer?.remarkValue,
        textValue: answer?.textValue,
        error: "This is a required field",
      };
      return null;
    } else {
      // const otherField = question.fields.find((choice: any) => choice.isOther);
      // if (otherField?.id === answer.questionChoiceId && !answer.textValue) {
      //   answers[question.id] = {
      //     questionChoiceId: [answer?.questionChoiceId],
      //     remarkValue: answer?.remarkValue,
      //     textValue: answer?.textValue,
      //     error: "The other label cannot be empty",
      //   };
      //   return null;
      // }
      return {
        questionId: question.id,
        questionType: question.type,
        response: {
          remarkValue: answer.remarkValue,
          questionChoiceId: answer.questionChoiceId,
          textValue: answer.textValue,
        },
      };
    }
  } else if (answer?.questionChoiceId?.length) {
    // const otherField = question.fields.find((choice: any) => choice.isOther);
    // if (otherField?.id === answer.questionChoiceId && !answer.textValue) {
    //   answers[question.id] = {
    //     questionChoiceId: [answer?.questionChoiceId],
    //     remarkValue: answer?.remarkValue,
    //     textValue: answer?.textValue,
    //     error: "The other label cannot be empty",
    //   };
    //   return null;
    // }
    return {
      questionId: question.id,
      questionType: question.type,
      response: {
        remarkValue: answer.remarkValue,
        questionChoiceId: answer.questionChoiceId,
        textValue: answer?.textValue,
      },
    };
  } else {
    return false;
  }
};

const CRFCheckbox = (question: any, answers: any) => {
  const answer = answers[question.id];
  if (question.required) {
    if (
      !answer ||
      !answer?.questionChoiceId ||
      answer?.questionChoiceId.length === 0
    ) {
      answers[question.id] = {
        questionChoiceId: answer?.questionChoiceId,
        remarkValue: answer?.remarkValue,
        // otherChoice: answer?.otherChoice,
        error: "This is a required field",
      };
      return null;
    } else {
      // const otherField = question.fields.find((choice: any) => choice.isOther);
      // if (
      //   answer.questionChoiceId.includes(otherField?.id) &&
      //   !answer?.otherChoice?.textValue
      // ) {
      //   answers[question.id] = {
      //     questionChoiceId: answer?.questionChoiceId,
      //     remarkValue: answer?.remarkValue,
      //     otherChoice: answer?.otherChoice,
      //     error: "The other label cannot be empty",
      //   };
      //   return null;
      // }
      return {
        questionId: question.id,
        questionType: question.type,
        response: {
          remarkValue: answer.remarkValue,
          questionChoiceId: answer.questionChoiceId,
          // otherChoice: answer?.otherChoice,
        },
      };
    }
  } else if (answer?.questionChoiceId?.length > 0) {
    // const otherField = question.fields.find((choice: any) => choice.isOther);
    // if (
    //   answer.questionChoiceId.includes(otherField?.id) &&
    //   !answer?.otherChoice?.textValue
    // ) {
    //   answers[question.id] = {
    //     questionChoiceId: answer?.questionChoiceId,
    //     remarkValue: answer?.remarkValue,
    //     otherChoice: answer?.otherChoice,
    //     error: "The other label cannot be empty",
    //   };
    //   return null;
    // }
    return {
      questionId: question.id,
      questionType: question.type,
      response: {
        remarkValue: answer.remarkValue,
        questionChoiceId: answer.questionChoiceId,
        // otherChoice: answer?.otherChoice,
      },
    };
  } else {
    return false;
  }
};

const CRFTimeType = (question: any, answers: any) => {
  const answer = answers[question.id];
  if (question.required) {
    if (!answer || !answer?.textValue || !answer?.textValue?.trim()) {
      answers[question.id] = {
        textValue: answer?.textValue,
        remarkValue: answer?.remarkValue,
        error: "This is a required field",
      };
      return null;
    } else {
      return {
        questionId: question.id,
        questionType: question.type,
        response: {
          remarkValue: answer.remarkValue,
          textValue: answer.textValue,
        },
      };
    }
  } else if (answer?.textValue && answer?.textValue?.trim()) {
    return {
      questionId: question.id,
      questionType: question.type,
      response: {
        remarkValue: answer.remarkValue,
        textValue: answer.textValue,
      },
    };
  } else {
    return false;
  }
};

const CRFquestionIterator = (
  question: any,
  newAnswers: any,
  requestBody: any[]
) => {
  if (question.type === "number" || question.type === "score_input") {
    const obj = CRFNumberTypes(question, newAnswers);
    if (obj) {
      requestBody.push(obj);
    } else if (obj !== false) {
      return true;
    }
  } else if (
    text_types.includes(question.type) ||
    question.type === "upload_file"
  ) {
    const obj = CRFTextTypes(question, newAnswers);
    if (obj) {
      requestBody.push(obj);
    } else if (obj !== false) {
      return true;
    }
  } else if (date_types.includes(question.type)) {
    const obj = CRFDateTypes(question, newAnswers);
    if (obj) {
      requestBody.push(obj);
    } else if (obj !== false) {
      return true;
    }
  } else if (question.type === "checkbox") {
    const obj = CRFCheckbox(question, newAnswers);
    if (obj) {
      requestBody.push(obj);
    } else if (obj !== false) {
      return true;
    }
  } else if (choice_types.includes(question.type)) {
    const obj = CRFMultipleChoice(question, newAnswers);
    if (obj) {
      requestBody.push(obj);
    } else if (obj !== false) {
      return true;
    }
  } else if (question.type === "time") {
    const obj = CRFTimeType(question, newAnswers);
    if (obj) {
      requestBody.push(obj);
    } else if (obj !== false) {
      return true;
    }
  }
};

export const submitEpro = (answers: any, questions: any[]) => {
  const newAnswers = JSON.parse(JSON.stringify(answers));
  const requestBody: any[] = [];
  let error = false;
  questions.forEach((question) => {
    if (question.type === "group") {
      question.questions.forEach((que: any) => {
        const isError = CRFquestionIterator(que, newAnswers, requestBody);
        if (isError) {
          error = true;
        }
      });
    } else {
      const isError = CRFquestionIterator(question, newAnswers, requestBody);
      if (isError) {
        error = true;
      }
    }
  });
  return { error: error, value: requestBody, newAnswers: newAnswers };
};

export const eproAnswerViewFormat = (responses: any[]) => {
  const answerObj: any = {};
  responses.forEach((response) => {
    if (response.questionId) {
      answerObj[response.questionId] = {
        dateValue: response.dateValue || undefined,
        gridValue: response.gridValue || undefined,
        remarkValue: response.remarkValue || undefined,
        textValue: response.textValue || undefined,
        numberValue:
          typeof response.numberValue === "number"
            ? response.numberValue
            : undefined,
        questionChoiceId: response.questionChoiceId || undefined,
      };
    }
  });
  return answerObj;
};
