import { Formik } from "formik";
import { toastMessage, errorToastMessage } from "../../../helpers/toastMessage";
import {
  Modal,
  Input,
  Button,
  Select,
  DatePicker,
  Checkbox,
  Switch,
} from "antd";
import { hideModal } from "../../../redux/reducers/modalSlice";
import http from "../../../http";
import { useAppDispatch } from "../../../redux/hooks";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import moment from "moment";

let yup = require("yup");

// const dosageUnits = [
//   "Kg",
//   "g",
//   "mg",
//   "mcg",
//   "L",
//   "mL",
//   "cc",
//   "mol",
//   "mmol",
//   "IU",
// ];

let schema = yup.object().shape({
  name: yup.string().required("Medication Name is Required"),
  draft: yup.bool(),
  frequency: yup.string().optional(),
  dose: yup.number().optional(),
  unit: yup.string().optional(),
  dosageForm: yup.string().optional(),
  startDate: yup.string().optional().nullable(),
  endDate: yup.string().optional().nullable(),
  indicationType: yup.string().optional(),
  category: yup.string().required("Category is Required"),
});

type Props = {
  show: boolean;
  medication: any;
  userId: string;
  callback: Function;
};

const indicationTypes = ["AE", "MH"];

const categoryTypes = ["Immunosuppressants", "Diabetes", "Antibiotics"];

const AddMedicationModal = (props: Props) => {
  const dispatch = useAppDispatch();

  const submitHandler = async (values: any) => {
    try {
      dispatch(setAppLoader(true));
      let data = {
        startDate:
          values.startDate && !values.startUnknown
            ? moment(values.startDate, "DD/MM/YYYY").toISOString()
            : null,
        endDate:
          values.endDate && !values.endUnknown && !values.isOngoing
            ? moment(values.endDate, "DD/MM/YYYY").toISOString()
            : null,
        frequency: values.frequency || null,
        indication: values.indicationType,
        draft: values.draft || false,
        name: values.name,
        userId: values.userId,
        dose: values.dose || null,
        unit: values.unit || null,
        remarks: values.remarks || "",
        type: values.type,
        category: values.category,
        route: values.route,
        dosageForm: values.dosageForm,
        continueAfterStudy: values.studyContinuation,
        isBaseline: values.isBaseline,
      };
      if (props.medication) {
        const res = await http.patch(
          `/mh/user_medications/${props.medication.id}`,
          data
        );
        dispatch(hideModal());
        dispatch(setAppLoader(false));
        toastMessage("success", res.data.message);
        props.callback();
      } else {
        const res = await http.post("/mh/user_medications", data);
        dispatch(hideModal());
        dispatch(setAppLoader(false));
        toastMessage("success", res.data.message);
        props.callback();
      }
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  const closeHandler = () => {
    dispatch(hideModal());
  };

  return (
    <Modal
      title={props.medication ? "Edit Medication" : "Add Medication"}
      visible={props.show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <div className="add-question">
        <Formik
          initialValues={{
            category: props?.medication?.category || "",
            route: props?.medication?.route || "",
            name: props?.medication?.name || "",
            type: props?.medication?.type || "",
            frequency: props?.medication?.frequency || "",
            startDate: props?.medication?.startDate || null,
            endDate: props?.medication?.endDate || null,
            dose: props?.medication?.dose || undefined,
            unit: props?.medication?.unit || "",
            dosageForm: props?.medication?.dosageForm || "",
            remarks: props?.medication?.remarks || "",
            indicationType: props?.medication?.indication || "",
            userId: props.userId,
            draft: props?.medication?.draft || false,
            studyContinuation: props?.medication?.studyContinuation || false,
            isBaseline: props?.medication?.isBaseline || false,
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleBlur,
            handleChange,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <div>
                <div>
                  <div className="text-secondary font-m fw-medium mb-1">
                    Category
                  </div>
                  <Select
                    className="custom-select-field"
                    size="large"
                    value={values.category}
                    style={{ width: "100%" }}
                    onChange={(val) => {
                      setFieldValue("category", val);
                    }}
                  >
                    {categoryTypes.map((ct) => {
                      return (
                        <Select.Option key={ct} value={ct}>
                          {ct}
                        </Select.Option>
                      );
                    })}
                    <Select.Option key="Other" value="Other">
                      Other
                    </Select.Option>
                  </Select>
                  <div className="input-error">
                    {errors.category && touched.category && errors.category}
                  </div>
                </div>
                <div>
                  <div className="text-secondary font-m fw-medium mb-1">
                    Medication/Therapy Name
                  </div>
                  <Input
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="singer-input"
                    placeholder={"Medication Name"}
                  />
                  <div className="input-error">
                    {errors.name && touched.name && errors.name}
                  </div>
                </div>
                <div>
                  <div className="text-secondary font-m fw-medium mb-1">
                    Type
                  </div>
                  <Select
                    className="custom-select-field"
                    size="large"
                    value={values.type}
                    style={{ width: "100%" }}
                    onChange={(val) => {
                      setFieldValue("type", val);
                    }}
                  >
                    <Select.Option value={"Vaccines"}>Vaccines</Select.Option>
                    <Select.Option value={"Vitamins"}>Vitamins</Select.Option>
                    <Select.Option value={"Herbal Supplements"}>
                      Herbal Supplements
                    </Select.Option>
                    <Select.Option value={"Recreational drugs"}>
                      Recreational drugs
                    </Select.Option>
                  </Select>
                  <div className="input-error">
                    {errors.type && touched.type && errors.type}
                  </div>
                </div>
                <div>
                  <div className="text-secondary font-m fw-medium mb-1">
                    Indication
                  </div>
                  <Select
                    className="custom-select-field"
                    size="large"
                    value={values.indicationType}
                    style={{ width: "100%" }}
                    onChange={(val) => {
                      setFieldValue("indicationType", val);
                    }}
                  >
                    {indicationTypes.map((ind) => {
                      return (
                        <Select.Option key={ind} value={ind}>
                          {ind}
                        </Select.Option>
                      );
                    })}
                    <Select.Option key="Other" value="Other">
                      Other
                    </Select.Option>
                  </Select>
                  <div className="input-error">
                    {errors.indicationType &&
                      touched.indicationType &&
                      errors.indicationType}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="text-secondary font-m fw-medium mb-1">
                      Dose
                    </div>
                    <Input
                      name="dose"
                      type="number"
                      value={values.dose}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="singer-input"
                      placeholder="Dose"
                      onWheel={(e) => {
                        e.currentTarget.blur();
                      }}
                    />
                    <div className="input-error">
                      {errors.dose && touched.dose && errors.dose}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-secondary font-m fw-medium mb-1">
                      Dose Units
                    </div>
                    <Input
                      name="unit"
                      value={values.unit}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="singer-input"
                      placeholder="Dose Units"
                      onWheel={(e) => {
                        e.currentTarget.blur();
                      }}
                    />
                    <div className="input-error">
                      {errors.unit && touched.unit && errors.unit}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="text-secondary font-m fw-medium mb-1">
                    Dose Form
                  </div>
                  <Input
                    name="dosageForm"
                    value={values.dosageForm}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="singer-input"
                    placeholder="Dose Form"
                    onWheel={(e) => {
                      e.currentTarget.blur();
                    }}
                  />
                  <div className="input-error">
                    {errors.dosageForm &&
                      touched.dosageForm &&
                      errors.dosageForm}
                  </div>
                </div>
                <div>
                  <div className="text-secondary font-m fw-medium mb-1">
                    Frequency
                  </div>
                  <Input
                    name="frequency"
                    value={values.frequency}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="singer-input"
                    placeholder="Frequency"
                    onWheel={(e) => {
                      e.currentTarget.blur();
                    }}
                  />
                  <div className="input-error">
                    {errors.frequency && touched.frequency && errors.frequency}
                  </div>
                </div>
                <div>
                  <div className="text-secondary font-m fw-medium mb-1">
                    Route of Administration
                  </div>
                  <Input
                    name="route"
                    value={values.route}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="singer-input"
                    placeholder="Route of Administration"
                    onWheel={(e) => {
                      e.currentTarget.blur();
                    }}
                  />
                  <div className="input-error">
                    {errors.route && touched.route && errors.route}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="text-secondary font-m fw-medium mb-1">
                      Start Date
                    </div>
                    <DatePicker
                      className="date-selector"
                      format="DD/MM/YYYY"
                      value={
                        values.startDate
                          ? moment(values.startDate, "DD/MM/YYYY")
                          : null
                      }
                      style={{
                        borderRadius: "16px",
                        height: "40px",
                      }}
                      onChange={(_1, dateString) => {
                        setFieldValue("startDate", dateString);
                      }}
                      inputReadOnly
                    />
                    <div className="input-error">
                      {errors.startDate &&
                        touched.startDate &&
                        errors.startDate}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-secondary font-m fw-medium mb-1">
                      End Date
                    </div>
                    <DatePicker
                      className="date-selector"
                      format="DD/MM/YYYY"
                      value={
                        values.endDate
                          ? moment(values.endDate, "DD/MM/YYYY")
                          : null
                      }
                      style={{
                        borderRadius: "16px",
                        height: "40px",
                      }}
                      onChange={(_1, dateString) => {
                        setFieldValue("endDate", dateString);
                      }}
                      inputReadOnly
                    />
                    <div className="input-error">
                      {errors.endDate && touched.endDate && errors.endDate}
                    </div>
                  </div>
                </div>

                <div className="d-flex align-items-center my-4">
                  <Switch
                    checked={values.isBaseline}
                    onChange={(checked) => setFieldValue("isBaseline", checked)}
                  />
                  <div className="font-sm fw-semibold ms-3">
                    Baseline Medication/ Therapy
                  </div>
                </div>
                <div className="d-flex align-items-center my-4">
                  <Switch
                    checked={values.studyContinuation}
                    onChange={(checked) =>
                      setFieldValue("studyContinuation", checked)
                    }
                  />
                  <div className="font-sm fw-semibold ms-3">
                    Continuing After Study
                  </div>
                </div>
                <div>
                  <div className="text-secondary font-m fw-medium mb-1">
                    Remarks
                  </div>
                  <Input
                    name="remarks"
                    value={values.remarks}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="singer-input"
                    placeholder="Remarks"
                  />
                  <div className="input-error">
                    {errors.remarks && touched.remarks && errors.remarks}
                  </div>
                </div>
                <div>
                  <div>
                    <Checkbox
                      name="draft"
                      checked={values.draft}
                      onChange={(e) => {
                        setFieldValue("draft", e.target.checked);
                      }}
                    >
                      Draft
                    </Checkbox>
                  </div>
                </div>
                <div className="button-container mt-4">
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ height: "40px", borderRadius: "12px" }}
                    className="me-4"
                  >
                    <span className="fw-semibold font-sm">Save</span>
                  </Button>
                  <Button
                    style={{ height: "40px", borderRadius: "12px" }}
                    className="me-4"
                    onClick={closeHandler}
                  >
                    <span className="fw-semibold font-sm">Cancel</span>
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default AddMedicationModal;
