import { Input } from "antd";
import React from "react";
import { ChoiceType, GroupType, CheckBoxType, LikertType } from "./ChoiceType";
import {
  InputType,
  DateType,
  TextType,
  FileType,
  TimeType,
} from "./SingleType";

const questionTypes: any = {
  short_text: TextType,
  long_text: TextType,
  number: InputType,
  score_input: InputType,
  multiple_choice: ChoiceType,
  yes_no: ChoiceType,
  checkbox: CheckBoxType,
  likert_scale: LikertType,
  // grid: GridType,
  date: DateType,
  date_time: DateType,
  group: GroupType,
  upload_file: FileType,
  time: TimeType,
};

type Props = {
  question: any;
  index: number;
  answers: any;
  saveAnswer: Function;
};

const QuestionBlock: React.FC<Props> = ({
  question,
  index,
  saveAnswer,
  answers,
}) => {
  const TypeComponent = questionTypes[question.type];

  if (!TypeComponent) {
    return null;
  }

  const { id } = question;
  const answer = answers[id];

  const saveRemark = (event: any) => {
    if (answer) {
      saveAnswer(id, {
        ...answer,
        remarkValue: event.target.value,
      });
    } else {
      saveAnswer(id, {
        remarkValue: event.target.value,
      });
    }
  };

  return (
    <div className="crf-question-block" key={question.id}>
      <div className="d-flex mb-2 align-items-center">
        {question.questionNo && (
          <div className="question-number fw-semibold font-m">
            {question.questionNo}
          </div>
        )}
        <div className="text-prime fw-semibold font-sm question-title">
          {question.title}
          {question.required && <span className="input-block-required">*</span>}
        </div>
      </div>
      {question.attachment && (
        <div className="question-attachment">
          <img src={question.attachment.href} alt="question-attachment" />
        </div>
      )}
      <div className="question-action-container">
        <TypeComponent
          question={question}
          groupIndex={index}
          saveAnswer={saveAnswer}
          answer={question.type === "group" ? answers : answer}
        />
        {question.remark && (
          <div className="crf-text-field mt-1">
            <div className="text-prime font-sm fw-medium mb-2">
              Enter Remarks
            </div>
            <Input
              placeholder="Type your text here"
              className="crf-number"
              maxLength={256}
              value={answer?.remarkValue}
              onChange={saveRemark}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestionBlock;
