import React from "react";
import * as yup from "yup";
import { Formik } from "formik";
import CustomButton from "../Common/CustomButton";
import AuthLayout from "../AuthLayout/AuthLayout";
import CustomInput from "../Common/CustomInput";
import { Row, Col } from "antd";
import logo from "../../assets/img/mahalo-logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../redux/hooks";
import { errorToastMessage } from "../../helpers/toastMessage";
import { resendOtp, otpVerifyAction } from "../../redux/actions/userAction";

let schema = yup.object().shape({
  code: yup.string().required("Invalid OTP"),
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SmsSecurity = () => {
  const dispatch = useAppDispatch();
  const phoneNumber = useQuery().get("phoneNumber");
  const navigate = useNavigate();

  const handlResendOtp = async () => {
    try {
      const data = {
        phoneNumber: "+" + phoneNumber?.toString().trim(),
      };
      await dispatch(resendOtp(data));
    } catch (err) {
      errorToastMessage(err as Error);
    }
  };

  const submitHandler = async (values: any, setSubmitting: Function) => {
    try {
      setSubmitting(true);
      let obj = {
        otp: values.code,
        phoneNumber: "+" + phoneNumber?.toString().trim(),
      };
      await dispatch(otpVerifyAction(obj));
      navigate("/auth/login");
    } catch (err) {
      setSubmitting(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <div className="d-block auth-layout">
      <Row className="h-100">
        <Col sm={24} md={12}>
          <div className="auth-left-container d-flex align-items-center justify-content-center h-100">
            <div className="flex-grow-1">
              <img
                src={logo}
                alt="MAHALO-logo"
                width="200px"
                className="mb-5"
              />
              <div className="px-md-4">
                <div className="mobile-screen-icon"></div>
                <h1 className="font-l fw-bold mb-5">We just sent you an SMS</h1>
                <div className="sms-details">
                  <div className="font-m mt-2">
                    To sign in, enter the security code we sent to
                  </div>
                  <div className="sms-details-phone mb-2">
                    {"+" + phoneNumber}
                  </div>
                </div>
                <Formik
                  initialValues={{ code: "" }}
                  validationSchema={schema}
                  onSubmit={(values, { setSubmitting }: any) => {
                    submitHandler(values, setSubmitting);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="input-container">
                        <div className="digit-code mb-3">
                          Type your 6-digit security code here:
                        </div>
                        <div className="floating-label">
                          <CustomInput
                            type="text"
                            name="code"
                            placeholder="Enter Code here"
                            changeEvent={handleChange}
                            className="floating-input"
                            value={values.code}
                            blurEvent={handleBlur}
                          />
                          <div className="input-error">
                            {errors.code && touched.code && errors.code}
                          </div>
                        </div>
                      </div>
                      <div className="sms-links mt-4">
                        <CustomButton
                          buttonType="button"
                          buttonText="Resend OTP"
                          className="login-button me-3"
                          disabled={isSubmitting}
                          clickHandler={handlResendOtp}
                          primary={false}
                        />
                        <CustomButton
                          buttonType="submit"
                          buttonText="Done"
                          className="login-button"
                          loading={isSubmitting}
                          primary
                        />
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={24} md={12} className="bg-primary-blue p-3 p-lg-4">
          <AuthLayout
            headerText="About Mahalo"
            paragraphText="Mahalo is a Clinical Research Software Platform (EDC System) that makes it easy for researchers to collect patient data digitally."
          />
        </Col>
      </Row>
    </div>
  );
};

export default SmsSecurity;
