import { Progress, Table } from "antd";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { canViewCRFs, hasBlindingAccess } from "../../../helpers/roles";
import Column from "antd/lib/table/Column";
import {
  CustomPaginationWithPages,
  PaginationText,
} from "../../Common/TableUtils";
import { SortOrder } from "antd/lib/table/interface";
import {
  setParticipantsPage,
  setParticipantsSort,
} from "../../../redux/reducers/participantSlice";
import { fetchPreScreenedList } from "../../../redux/actions/adminAction";

const PreScreenedList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    searchTerm,
    preScreenedParticipantCount,
    preScreenedParticipantUsers,
    toggleLoader,
    headers,
    currentPage,
    sort,
    selectedTab,
  } = useAppSelector((state) => state.participant);
  const role = useAppSelector((state) => state.user.role);
  const [expanded, setExpanded] = useState(false);
  const [hasAdminAccess] = useState(canViewCRFs(role));
  const [hasViewAccess] = useState(hasBlindingAccess(role));

  useEffect(() => {
    if (selectedTab === "pre-screened") {
      dispatch(fetchPreScreenedList(searchTerm, currentPage, sort));
    }
  }, [searchTerm, currentPage, sort, dispatch, toggleLoader, selectedTab]);

  const onTableChange = async (pagination: any, filters: any, sorters: any) => {
    if (pagination.current) {
      dispatch(setParticipantsPage(pagination.current));
    }
    // if (filters.randomizationGroup) {
    //   dispatch(setParticipantsFilter(filters.randomizationGroup[0]));
    // } else if (filters.status) {
    //   dispatch(setParticipantsFilter(filters.status[0]));
    // } else {
    //   dispatch(setParticipantsFilter(""));
    // }
    let sort = null;
    if (sorters.order && sorters.columnKey === "screenId") {
      sort = {
        orderBy: sorters["order"] === "ascend" ? "asc" : "desc",
        sortBy: "screenId",
      };
      dispatch(setParticipantsSort({ sort }));
    } else {
      sort = {
        orderBy: "asc",
        sortBy: "screenId",
      };
      dispatch(setParticipantsSort({ sort }));
    }
  };

  const crfNavigate = (patient: any) => {
    if (!hasAdminAccess) {
      return;
    }
    const crfType = "screening";
    const patientId = patient.screenId || "Patient";

    navigate(`/crf/${crfType}/${patient.id}?patientId=${patientId}`);
  };

  const toggleExpanded = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <div className="modules-wrapper">
      <Table
        dataSource={preScreenedParticipantUsers}
        showSorterTooltip={false}
        className="singer-custom-table participant-table"
        onChange={onTableChange}
        rowKey={(record) => record.id}
        pagination={{
          className: "singer-table-pagination-with-pages",
          itemRender: CustomPaginationWithPages,
          current: currentPage,
          total: preScreenedParticipantCount,
          showSizeChanger: false,
          pageSize: 8,
          hideOnSinglePage: true,
          showTotal: PaginationText,
          position: ["bottomLeft"],
          showQuickJumper: true,
        }}
        scroll={{ x: "max-content" }}
      >
        <Column
          title="Screening Id"
          dataIndex="screenId"
          key="screenId"
          width={200}
          align="center"
          sorter={true}
          fixed={"left"}
          sortOrder={
            sort?.sortBy === "screenId"
              ? sort.orderBy === "asc"
                ? ("ascend" as SortOrder)
                : ("descend" as SortOrder)
              : undefined
          }
          render={(_1, record: any) => (
            <div
              onClick={() => crfNavigate(record)}
              className="color-primary cp"
            >
              {record.screenId}
            </div>
          )}
        />
        {hasViewAccess && (
          <Column
            title="Email"
            dataIndex="email"
            key="email"
            width={200}
            align="center"
            render={(text: string) => <div>{text ? text : "-"}</div>}
          />
        )}
        <Column
          title="Created Date"
          dataIndex="createdAt"
          key="createdAt"
          align="center"
          width={200}
        />

        <Column
          title="Status"
          dataIndex="status"
          key="status"
          // filters={[
          //   {
          //     text: "Active",
          //     value: "false",
          //   },
          //   {
          //     text: "Inactive",
          //     value: "true",
          //   },
          // ]}
          // filterMultiple={false}
          // filteredValue={filter ? [filter] : null}
          width={200}
          align="center"
          render={(text: string) => (
            <div className="d-flex justify-content-center">
              <span
                className={
                  text === "active" ? "active-tag badge" : "inactive-tag badge"
                }
              >
                {text}
              </span>
            </div>
          )}
        />
        <Column
          title={() => {
            return (
              <span>
                Completion Rate
                <span
                  className="color-primary ms-3 cp"
                  onClick={toggleExpanded}
                >
                  {expanded ? "(Click to collapse)" : "(Click to expand more)"}
                </span>
              </span>
            );
          }}
          key="completion"
          dataIndex="completion"
          width={350}
          render={(percent: number) => {
            return <Progress percent={percent} size="small" />;
          }}
        />

        {expanded &&
          headers.map((header) => {
            return (
              <Column
                title={header.title}
                key={header.id}
                dataIndex={header.id}
                width={350}
                render={(percent: number, record: any) => {
                  return (
                    <span
                      className="cp"
                      onClick={() => {
                        sessionStorage.setItem("crf-phase", header.pid);
                        sessionStorage.setItem("crf-question", header.qid);
                        crfNavigate(record);
                      }}
                    >
                      <Progress percent={percent} size="small" />
                    </span>
                  );
                }}
              />
            );
          })}
      </Table>
    </div>
  );
};

export default PreScreenedList;
