import { Modal, Input, Button, Select } from "antd";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { useAppDispatch } from "../../../redux/hooks";
import { useState } from "react";
import { errorToastMessage, toastMessage } from "../../../helpers/toastMessage";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import http from "../../../http";
import moment from "moment";

type Props = {
  show: true;
  title: string;
  queries: any[];
};

const QueryCommentModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const [remark, setRemark] = useState("");
  const [selectedQuery, setSelectedQuery] = useState<any>(null);

  const changeHandler = (event: any) => {
    setRemark(event.target.value);
  };

  const submitHandler = async () => {
    try {
      if (!remark.trim()) {
        toastMessage("warning", "comment cannot be empty");
        return;
      }
      dispatch(setAppLoader(true));
      const body = {
        comment: remark,
      };
      let res = await http.post(`/queries/${selectedQuery?.id}/comment`, body);
      toastMessage("success", res.data.message);
      closeHandler();
      dispatch(setAppLoader(false));
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const handleQuerySelection = async (qid: any) => {
    try {
      dispatch(setAppLoader(true));
      let res = await http.get(`/queries/${qid}`);
      const query = {
        id: res.data.data?.id,
        remark: res.data.data?.remark,
        status: res.data.data?.status,
        createdBy: res.data.data?.createdBy
          ? res.data.data?.createdBy?.firstName +
            " " +
            res.data.data?.createdBy?.lastName
          : null,
        createdAt: moment(res.data.data?.createdAt).format(
          "DD MMM YYYY hh:mm A"
        ),
        comments: res.data.data?.comments?.map((comment: any) => {
          return {
            id: comment.id,
            createdAt: moment(comment?.createdAt).format("DD/MM/YYYY hh:mm A"),
            createdBy: comment?.commenter
              ? comment?.commenter?.firstName +
                " " +
                comment?.commenter?.lastName
              : null,
            comment: comment?.comment,
          };
        }),
      };
      setRemark("");
      setSelectedQuery(query);
      dispatch(setAppLoader(false));
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal
      title={"Comments for " + props.title}
      visible={props.show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <div>
        <div className="col-md-12 mb-4">
          <div className="text-secondary font-m fw-medium mb-1">
            Select a query
          </div>
          <Select
            size="large"
            className="custom-select-field"
            value={selectedQuery?.id}
            onChange={handleQuerySelection}
            placeholder="Select query"
          >
            {props.queries.map((query) => {
              return (
                <Select.Option key={query.id} value={query.id}>
                  {query.remark}
                </Select.Option>
              );
            })}
          </Select>
        </div>
        {selectedQuery ? (
          <>
            {selectedQuery?.comments?.length > 0 ? (
              selectedQuery?.comments?.map((comment: any) => {
                return (
                  <div className="query-comment" key={comment.id}>
                    <div className="comment-text">{comment.comment}</div>
                    <div className="comment-details">
                      <span>{"By: " + comment.createdBy}</span>
                      <span>{"Date: " + comment.createdAt}</span>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="col-md-12 mb-4 text-gray">
                No comments added yet
              </div>
            )}
            <div className="col-md-12 mb-4">
              <div className="form-group">
                <div className="text-secondary font-m fw-medium mb-1">
                  New Comment:
                </div>
                <Input.TextArea
                  name="name"
                  rows={3}
                  className="singer-input"
                  style={{ resize: "none" }}
                  value={remark}
                  onChange={changeHandler}
                />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <Button
                type="primary"
                htmlType="submit"
                style={{ height: "40px", borderRadius: "12px" }}
                className="me-4"
                onClick={submitHandler}
              >
                <span className="fw-semibold font-sm">Add comment</span>
              </Button>
              <Button
                style={{ height: "40px", borderRadius: "12px" }}
                className="me-4"
                onClick={closeHandler}
              >
                <span className="fw-semibold font-sm">Cancel</span>
              </Button>
            </div>
          </>
        ) : (
          <div style={{ height: "25vh" }}></div>
        )}
      </div>
    </Modal>
  );
};

export default QueryCommentModal;
