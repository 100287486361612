import { Modal, Input, Button } from "antd";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { useAppDispatch } from "../../../redux/hooks";
import { useState } from "react";
import { errorToastMessage, toastMessage } from "../../../helpers/toastMessage";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import http from "../../../http";
import { NewQueryIcon } from "../../Common/Icons";

type Props = {
  show: boolean;
  selectedQuestionniare: any;
  questionId: string;
  userId: string;
  fetchQueries: Function;
  title: string;
};

const ShowQueryModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const [remark, setRemark] = useState("");

  const changeHandler = (event: any) => {
    setRemark(event.target.value);
  };

  const submitHandler = async () => {
    try {
      if (!remark.trim()) {
        toastMessage("warning", "Remark cannot be empty");
        return;
      }
      dispatch(setAppLoader(true));
      const body = {
        phaseId: props.selectedQuestionniare?.phaseId,
        questionnaireId: props.selectedQuestionniare?.id,
        questionId: props.questionId,
        userId: props.userId,
        remark: remark,
      };
      let res = await http.post(`/queries`, body);
      toastMessage("success", res.data.message);
      closeHandler();
      props.fetchQueries();
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  const closeHandler = () => {
    dispatch(hideModal());
  };

  return (
    <Modal
      title={"Add query for field " + props.title}
      visible={props.show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <div>
        <div className="col-md-12 mb-4">
          <div className="text-secondary font-m fw-medium mb-1">
            Current query status
          </div>
          <div className="d-flex align-items-center">
            <NewQueryIcon />
            <span className="text-secondary font-m ms-3">Open</span>
          </div>
        </div>
        <div className="col-md-12 mb-4">
          <div className="form-group">
            <div className="text-secondary font-m fw-medium mb-1">Remark</div>
            <Input.TextArea
              name="name"
              rows={3}
              className="singer-input"
              style={{ resize: "none" }}
              value={remark}
              onChange={changeHandler}
            />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <Button
          type="primary"
          htmlType="submit"
          style={{ height: "40px", borderRadius: "12px" }}
          className="me-4"
          onClick={submitHandler}
        >
          <span className="fw-semibold font-sm">Save</span>
        </Button>
        <Button
          style={{ height: "40px", borderRadius: "12px" }}
          className="me-4"
          onClick={closeHandler}
        >
          <span className="fw-semibold font-sm">Cancel</span>
        </Button>
      </div>
    </Modal>
  );
};

export default ShowQueryModal;
