import { Checkbox, Input, Radio } from "antd";
import React from "react";
import { useAppDispatch } from "../../../redux/hooks";
import { setModalDetails } from "../../../redux/reducers/modalSlice";
import {
  InputType,
  DateType,
  TextType,
  FileType,
  TimeType,
} from "./SingleType";

// function indxToAlpha(indx: number) {
//   const index = indx % 26;
//   return String.fromCharCode(65 + index);
// }

export const ChoiceType: React.FC<any> = ({ question, saveAnswer, answer }) => {
  const { id, fields } = question;

  const saveInput = (event: any) => {
    const choiceId = event.target.value;
    if (answer && answer?.questionChoiceId?.[0] === choiceId) {
      const newAnswer = {
        ...answer,
        questionChoiceId: undefined,
      };
      // if (typeof newAnswer?.textValue !== "undefined") {
      //   delete newAnswer["textValue"];
      // }
      saveAnswer(id, newAnswer, true);
    } else if (answer) {
      const newAnswer = {
        ...answer,
        questionChoiceId: [choiceId],
      };
      // const otherChoice = fields.find((choice: any) => choice.isOther);
      // if (
      //   choiceId !== otherChoice?.id &&
      //   typeof newAnswer?.textValue !== "undefined"
      // ) {
      //   delete newAnswer["textValue"];
      // }
      if (newAnswer.error) {
        delete newAnswer["error"];
      }
      saveAnswer(id, newAnswer, true);
    } else {
      saveAnswer(
        id,
        {
          questionChoiceId: [choiceId],
        },
        true
      );
    }
  };

  // const otherInputSave = (event: any) => {
  //   if (answer) {
  //     const otherChoice = fields.find((choice: any) => choice.isOther);
  //     if (otherChoice) {
  //       const val = event.target.value;
  //       const newAnswer = {
  //         ...answer,
  //         questionChoiceId: otherChoice.id,
  //         textValue: val,
  //       };
  //       if (newAnswer.error && val) {
  //         delete newAnswer["error"];
  //       }
  //       if (!val && question.required) {
  //         newAnswer["error"] = "The other label cannot be empty";
  //       }
  //       saveAnswer(id, newAnswer, true);
  //     }
  //   } else {
  //     const otherChoice = fields.find((choice: any) => choice.isOther);
  //     if (otherChoice) {
  //       saveAnswer(
  //         id,
  //         {
  //           questionChoiceId: otherChoice.id,
  //           textValue: event.target.value,
  //         },
  //         true
  //       );
  //     }
  //   }
  // };

  return (
    <>
      <Radio.Group
        className="crf-choice"
        value={answer?.questionChoiceId?.[0]}
        // onChange={saveInput}
      >
        {fields.map((choice: any, index: number) => (
          <Radio key={choice.id} value={choice.id} onClick={saveInput}>
            {/* <div className="alpha-text font-sm fw-semibold color-primary me-5">
              {indxToAlpha(index)}
            </div> */}
            {/* {choice.isOther ? (
              <div className="crf-text-field mb-0 choice-input">
                <Input
                  placeholder="Others, Specify"
                  value={answer?.textValue}
                  onChange={otherInputSave}
                />
              </div>
            ) : ( */}
            <div className="font-sm fw-semibold color-primary choice-text">
              {choice.label}
            </div>
            {/* )} */}
          </Radio>
        ))}
      </Radio.Group>
      {answer?.error && <div className="input-error">{answer?.error}</div>}
    </>
  );
};

export const CheckBoxType: React.FC<any> = ({
  question,
  saveAnswer,
  answer,
}) => {
  const { id, fields } = question;
  const saveInput = (checkedValues: any) => {
    if (answer) {
      // const otherChoice = fields.find((choice: any) => choice.isOther);
      const newAnswer = {
        ...answer,
        questionChoiceId: checkedValues,
      };
      // if (!checkedValues.includes(otherChoice?.id) && newAnswer.otherChoice) {
      //   delete newAnswer["otherChoice"];
      // }
      // if (
      //   !checkedValues.includes(otherChoice?.id) &&
      //   newAnswer.error === "The other label cannot be empty"
      // ) {
      //   delete newAnswer["error"];
      // }
      if (checkedValues.length === 0 && question.required) {
        newAnswer["error"] = "This is a required field";
      }
      if (
        checkedValues.length > 0 &&
        newAnswer.error === "This is a required field"
      ) {
        delete newAnswer["error"];
      }
      saveAnswer(id, newAnswer);
    } else {
      saveAnswer(id, {
        questionChoiceId: checkedValues,
      });
    }
  };

  // const otherInputSave = (event: any) => {
  //   if (answer) {
  //     const otherChoice = fields.find((choice: any) => choice.isOther);
  //     const val = event.target.value;
  //     if (otherChoice) {
  //       const newAnswer = {
  //         ...answer,
  //         otherChoice: {
  //           choiceId: otherChoice.id,
  //           textValue: val,
  //         },
  //       };
  //       if (answer.questionChoiceId) {
  //         const newQuestionIds = [...answer.questionChoiceId];
  //         if (!newQuestionIds.includes(otherChoice.id)) {
  //           newQuestionIds.push(otherChoice.id);
  //         }
  //         if (val) {
  //           delete newAnswer["error"];
  //         }
  //         if (!val) {
  //           newAnswer["error"] = "The other label cannot be empty";
  //         }
  //         if (newAnswer.error === "This is a required field") {
  //           delete newAnswer["error"];
  //         }
  //         saveAnswer(id, {
  //           ...newAnswer,
  //           questionChoiceId: newQuestionIds,
  //         });
  //       } else {
  //         if (!val) {
  //           newAnswer["error"] = "The other label cannot be empty";
  //         }
  //         if (newAnswer.error === "This is a required field") {
  //           delete newAnswer["error"];
  //         }
  //         saveAnswer(id, {
  //           ...newAnswer,
  //           questionChoiceId: [otherChoice.id],
  //         });
  //       }
  //     }
  //   } else {
  //     const otherChoice = fields.find((choice: any) => choice.isOther);
  //     if (otherChoice) {
  //       saveAnswer(id, {
  //         questionChoiceId: [otherChoice.id],
  //         otherChoice: {
  //           choiceId: otherChoice.id,
  //           textValue: event.target.value,
  //         },
  //       });
  //     }
  //   }
  // };

  return (
    <>
      <Checkbox.Group
        className="crf-choice"
        value={answer?.questionChoiceId}
        onChange={saveInput}
      >
        {fields.map((choice: any, index: number) => (
          <Checkbox key={choice.id} value={choice.id}>
            {/* <div className="alpha-text font-sm fw-semibold color-primary me-5">
              {indxToAlpha(index)}
            </div> */}
            {/* {choice.isOther ? (
              <div className="crf-text-field mb-0 choice-input">
                <Input
                  placeholder="Others, Specify"
                  value={answer?.otherChoice?.textValue}
                  onChange={otherInputSave}
                />
              </div>
            ) : ( */}
            <div className="font-sm fw-semibold color-primary choice-text">
              {choice.label}
            </div>
            {/* )} */}
          </Checkbox>
        ))}
      </Checkbox.Group>
      {answer?.error && <div className="input-error">{answer?.error}</div>}
    </>
  );
};

export const LikertType: React.FC<any> = ({ question, answer, saveAnswer }) => {
  const { id, fields } = question;

  const saveInput = (value: any) => {
    if (answer && answer?.questionChoiceId?.[0] === value) {
      const newAnswer = {
        ...answer,
        questionChoiceId: undefined,
      };
      saveAnswer(id, newAnswer, true);
    } else if (answer) {
      const newAnswer = {
        ...answer,
        questionChoiceId: [value],
      };
      if (newAnswer.error) {
        delete newAnswer["error"];
      }
      saveAnswer(id, newAnswer, true);
    } else {
      saveAnswer(
        id,
        {
          questionChoiceId: [value],
        },
        true
      );
    }
  };

  return (
    <>
      <div className="crf-likert">
        {fields.map((choice: any) => (
          <div
            key={choice.id}
            onClick={() => saveInput(choice.id)}
            className="likert-option cp"
          >
            <Radio checked={choice.id === answer?.questionChoiceId?.[0]} />
            <div>{choice.label}</div>
          </div>
        ))}
      </div>
      {answer?.error && <div className="input-error">{answer?.error}</div>}
    </>
  );
};

const questionTypes: any = {
  short_text: TextType,
  long_text: TextType,
  number: InputType,
  score_input: InputType,
  multiple_choice: ChoiceType,
  yes_no: ChoiceType,
  checkbox: CheckBoxType,
  likert_scale: LikertType,
  date: DateType,
  date_time: DateType,
  upload_file: FileType,
  time: TimeType,
};

export const GroupType: React.FC<any> = ({ question, answer, saveAnswer }) => {
  const dispatch = useAppDispatch();

  const viewImage = (attachment: any) => {
    dispatch(
      setModalDetails({
        type: "VIEW_IMAGE",
        modalProps: {
          show: true,
          attachment: attachment,
        },
      })
    );
  };

  return (
    <>
      {question.questions.map((q: any) => {
        const TypeComponent = questionTypes[q.type];
        if (!TypeComponent) {
          //Only Statements will pass this
          return (
            <div className="crf-statement d-flex" key={q.id}>
              <div className="text-prime font-sm fw-medium">{q.title}</div>
              {q.attachment && (
                <span
                  className="ms-3 color-primary cp"
                  onClick={() => viewImage(q.attachment)}
                >
                  View Image
                </span>
              )}
            </div>
          );
        } else {
          const subAnswer = answer[q.id];
          return (
            <div className="crf-question-block" key={q.id}>
              <div className="d-flex mb-2 align-items-center">
                {q.questionNo && (
                  <div className="question-number fw-semibold font-m">
                    {q.questionNo}
                  </div>
                )}
                <div className="text-prime fw-semibold font-sm question-title">
                  {q.title}
                  {q.required && (
                    <span className="input-block-required">*</span>
                  )}
                </div>
              </div>
              {q.attachment && (
                <div className="question-attachment">
                  <img src={q.attachment.href} alt="question-attachment" />
                </div>
              )}
              <div className="question-action-container">
                <TypeComponent
                  question={q}
                  saveAnswer={saveAnswer}
                  answer={subAnswer}
                />
                {q.remark && (
                  <GroupRemark
                    question={q}
                    answer={subAnswer}
                    saveAnswer={saveAnswer}
                  />
                )}
              </div>
            </div>
          );
        }
      })}
    </>
  );
};

const GroupRemark: React.FC<any> = ({ question, answer, saveAnswer }) => {
  const { id } = question;
  const saveRemark = (event: any) => {
    if (answer) {
      saveAnswer(id, {
        ...answer,
        remarkValue: event.target.value,
      });
    } else {
      saveAnswer(id, {
        remarkValue: event.target.value,
      });
    }
  };

  return (
    <div className="crf-text-field mt-1">
      <div className="text-prime font-sm fw-medium mb-2">Enter Remarks</div>
      <Input
        placeholder="Type your text here"
        className="crf-number"
        maxLength={256}
        value={answer?.remarkValue}
        onChange={saveRemark}
      />
    </div>
  );
};
