import { NavLink, useNavigate } from "react-router-dom";
import {
  LogoSidebar,
  // Dashboard,
  // DashboardActive,
  Participants,
  ParticipantsActive,
  AdministratorsActive,
  Administrators,
  Content,
  ContentActive,
  Questionnaires,
  QuestionnairesActive,
  // DairyActive,
  // Dairy,
  // AppointmentActive,
  // AppointmentInActive,
  QueryActive,
  QueryInactive,
  AuditActive,
  AuditInactive,
  AdverseEvents,
  AdverseEventsActive,
} from "./SidebarIcons";
import { useAppSelector } from "../../redux/hooks";
import { useState } from "react";
import { canAmendQueries, hasAuditAccess } from "../../helpers/roles";

const Sidebar: React.FC = () => {
  const role = useAppSelector((state) => state.user.role);
  const [editAccess] = useState(canAmendQueries(role));
  const [adminAccess] = useState(hasAuditAccess(role));
  const navigate = useNavigate();

  const homeRedirect = () => {
    navigate("/");
  };

  return (
    <div className="sidebar nova-white-sidebar">
      <div className="logo-container mb-5 cp" onClick={homeRedirect}>
        <LogoSidebar />
      </div>
      <div className="main-sidebar">
        {/* <NavLink
          className={({ isActive }) => "item" + (isActive ? " active" : "")}
          to="/dashboard"
        >
          {window.location.href.includes("dashboard") ? (
            <DashboardActive />
          ) : (
            <Dashboard />
          )}
          <p className="nav-elements">Dashboard</p>
        </NavLink> */}

        {/* <NavLink
          className={({ isActive }) => "item" + (isActive ? " active" : "")}
          to="/documents"
        >
          {window.location.href.includes("documents") ? (
            <DocumentsActive />
          ) : (
            <Documents />
          )}
          <p className="nav-elements">Documents</p>
        </NavLink> */}

        <NavLink
          className={({ isActive }) => "item" + (isActive ? " active" : "")}
          to="/participants"
        >
          {window.location.href.includes("participants") ? (
            <ParticipantsActive />
          ) : (
            <Participants />
          )}
          <p className="nav-elements">Participants</p>
        </NavLink>

        <NavLink
          className={({ isActive }) => "item" + (isActive ? " active" : "")}
          to="/study"
        >
          {window.location.href.endsWith("study") ? (
            <ContentActive />
          ) : (
            <Content />
          )}
          <p className="nav-elements">Study</p>
        </NavLink>

        {editAccess && (
          <NavLink
            className={({ isActive }) => "item" + (isActive ? " active" : "")}
            to="/question"
          >
            {window.location.href.includes("question") ? (
              <QuestionnairesActive />
            ) : (
              <Questionnaires />
            )}
            <p className="nav-elements">Questionnaires</p>
          </NavLink>
        )}

        {/* <NavLink
          className={({ isActive }) => "item" + (isActive ? " active" : "")}
          to="/cms"
        >
          {window.location.pathname.startsWith("/cms") ? (
            <ContentActive />
          ) : (
            <Content />
          )}
          <p className="nav-elements">CMS</p>
        </NavLink> */}

        <NavLink
          className={({ isActive }) => "item" + (isActive ? " active" : "")}
          to="/administrator"
        >
          {window.location.href.includes("administrator") ? (
            <AdministratorsActive />
          ) : (
            <Administrators />
          )}
          <p className="nav-elements">Administrators</p>
        </NavLink>

        {/* <NavLink
          className={({ isActive }) => "item" + (isActive ? " active" : "")}
          to="/appointments"
        >
          {window.location.href.includes("appointments") ? (
            <AppointmentActive />
          ) : (
            <AppointmentInActive />
          )}
          <p className="nav-elements">Appointments</p>
        </NavLink> */}

        {/* <NavLink
          className={({ isActive }) => "item" + (isActive ? " active" : "")}
          to="/diary"
        >
          {window.location.href.includes("/diary") ? (
            <DairyActive />
          ) : (
            <Dairy />
          )}
          <p className="nav-elements">Diary</p>
        </NavLink> */}

        <NavLink
          className={({ isActive }) => "item" + (isActive ? " active" : "")}
          to="/queries"
        >
          {window.location.href.includes("/queries") ? (
            <QueryActive />
          ) : (
            <QueryInactive />
          )}
          <p className="nav-elements">Queries</p>
        </NavLink>

        {adminAccess && (
          <NavLink
            className={({ isActive }) => "item" + (isActive ? " active" : "")}
            to="/audit-logs"
          >
            {window.location.href.includes("audit-logs") ? (
              <AuditActive />
            ) : (
              <AuditInactive />
            )}
            <p className="nav-elements">Audit Trail</p>
          </NavLink>
        )}

        <NavLink
          className={({ isActive }) => "item" + (isActive ? " active" : "")}
          to="/adverse-events"
        >
          {window.location.href.includes("/adverse-events") ? (
            <AdverseEventsActive />
          ) : (
            <AdverseEvents />
          )}
          <p className="nav-elements">Adverse Events</p>
        </NavLink>

        <NavLink
          className={({ isActive }) => "item" + (isActive ? " active" : "")}
          to="/settings"
        >
          {window.location.href.includes("/settings") ? (
            <QueryActive />
          ) : (
            <QueryInactive />
          )}
          <p className="nav-elements">Settings</p>
        </NavLink>
      </div>
    </div>
  );
};

export default Sidebar;
