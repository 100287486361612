import { Modal, Button, Upload, Image } from "antd";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useEffect, useState } from "react";
import { errorToastMessage, toastMessage } from "../../../helpers/toastMessage";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import { UploadImage } from "../../../helpers/imageUpload";
import http from "../../../http";
import { canEditCRFs } from "../../../helpers/roles";

type Props = {
  show: boolean;
  qid: string;
  id: string;
};

const EproDocumentModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const [files, setFiles] = useState([]);
  const { role } = useAppSelector((state) => state.user);
  const [hasEditAccess] = useState(canEditCRFs(role));
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        dispatch(setAppLoader(true));
        const res = await http.get(
          `/questionnaires/daily/submit-response-admin/${props.qid}/files?userId=${props.id}`
        );
        setFiles(res.data.data.files || []);
        dispatch(setAppLoader(false));
      } catch (err) {
        dispatch(setAppLoader(false));
      }
    };
    fetchFiles();
  }, [toggle, setFiles, dispatch, props.qid, props.id]);

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const customRequest = (fileObj: any) => {
    const handleUpload = async (data: any[]) => {
      try {
        dispatch(setAppLoader(true));
        const res = await http.post(
          `/questionnaires/daily/submit-response-admin/${props.qid}/files?userId=${props.id}`,
          {
            files: [data[0]?.postUploadImageUrl],
          }
        );
        toastMessage("success", res.data.message);
        setToggle((prev) => !prev);
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    };
    dispatch(UploadImage(fileObj, handleUpload, "epro_image"));
  };

  const beforeUpload = (file: any) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/gif";
    if (!isJpgOrPng) {
      toastMessage("warning", "You can only upload JPG/PNG/JPEG/GIF file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 25;
    if (!isLt2M) {
      toastMessage("warning", `File must smaller than 25MB!`);
    }
    return isJpgOrPng && isLt2M;
  };

  return (
    <Modal
      title={"Epro Documents"}
      visible={props.show}
      onCancel={closeHandler}
      width={"35rem"}
      footer={null}
    >
      <div>
        <div>
          {files.length > 0 ? (
            <div className="d-flex justify-content-center mb-4">
              {files.map((file, index) => {
                return (
                  <Image
                    src={file}
                    key={index}
                    alt="epro"
                    style={{ maxHeight: "150px", objectFit: "contain" }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                );
              })}
            </div>
          ) : (
            <div className="d-flex justify-content-center text-prime fw-medium font-m mb-5">
              No Files Uploaded
            </div>
          )}
        </div>
        {hasEditAccess && (
          <div className="d-flex justify-content-center">
            <Upload
              showUploadList={false}
              beforeUpload={beforeUpload}
              customRequest={customRequest}
              accept="image/jpeg,image/png,image/jpg,image/gif"
            >
              <Button
                type="primary"
                className="d-flex align-items-center me-4"
                style={{ height: "40px", borderRadius: "12px" }}
              >
                <span className="fw-semibold font-sm">
                  {files.length > 0 ? "Replace file" : "Upload a file"}
                </span>
              </Button>
            </Upload>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default EproDocumentModal;
