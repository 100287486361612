import { useEffect, useState } from "react";
import {
  InputType,
  DateType,
  TextType,
  FileType,
  TimeType,
} from "./preview/SingleType";
import { useLocation } from "react-router-dom";
import {
  ChoiceType,
  GroupType,
  CheckBoxType,
  LikertType,
  GridType,
} from "./preview/ChoiceType";
import { useNavigate } from "react-router-dom";
import http from "../../../http";
import { useAppDispatch } from "../../../redux/hooks";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import { errorToastMessage } from "../../../helpers/toastMessage";
import { Button, Input, Tooltip } from "antd";
import { ChevronLeftIcon, CogIcon } from "@heroicons/react/solid";
import { questionResponseFormat } from "../../../helpers/question";
import { setModalDetails } from "../../../redux/reducers/modalSlice";
import QuestionPropertiesPreview from "./QuestionPropertiesPreview";

const questionTypes: any = {
  short_text: TextType,
  long_text: TextType,
  number: InputType,
  score_input: InputType,
  multiple_choice: ChoiceType,
  yes_no: ChoiceType,
  checkbox: CheckBoxType,
  likert_scale: LikertType,
  grid: GridType,
  date: DateType,
  date_time: DateType,
  group: GroupType,
  upload_file: FileType,
  time: TimeType,
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const QuestionPreview: React.FC = () => {
  const query = useQuery();
  const qid = query.get("id");
  const name = query.get("name");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [questions, setQuestions] = useState<any[]>([]);
  const [questionnaireStatus, setStatus] = useState("");
  const [isChinese, setChinese] = useState(false);
  const [showChinese, setShowChinese] = useState(false);
  const [modalProps, setModal] = useState(null);

  useEffect(() => {
    const getQuestions = async (id: string) => {
      try {
        dispatch(setAppLoader(true));
        let { data } = await http.get(`/questionnaires/${id}/questions`);
        let questions = await questionResponseFormat(
          data.data.questions,
          data.data.logicJumps
        );
        setQuestions(questions);
        setStatus(data.data.status);
        setChinese(data.data.isChinese);
        dispatch(setAppLoader(false));
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    };
    if (qid) {
      getQuestions(qid);
    }
  }, [dispatch, setQuestions, qid, setChinese]);

  const handleBack = () => {
    navigate(-1);
  };

  const viewImage = (attachment: any) => {
    dispatch(
      setModalDetails({
        type: "VIEW_IMAGE",
        modalProps: {
          show: true,
          attachment: attachment,
        },
      })
    );
  };

  const handleSettings = (arg: any) => {
    setModal(arg);
  };

  const toggleChinese = () => {
    setShowChinese((prev) => !prev);
  };

  return (
    <div className="question-preview-container">
      <div className="content-header">
        <ChevronLeftIcon
          style={{ height: "36px", width: "36px" }}
          className="me-5 text-secondary cp"
          onClick={handleBack}
        />
        <div className="font-m fw-semibold font-l me-5 text-secondary">
          {name || "Form Preview"}
        </div>
        <span style={{ marginLeft: "auto" }}></span>
        <span>
          {isChinese && (
            <Button
              type="primary"
              style={{ height: "40px", borderRadius: "12px" }}
              className="me-4"
              onClick={toggleChinese}
            >
              <span className="fw-semibold font-sm">
                {showChinese ? "Show English Text" : "Show Chinese Text"}
              </span>
            </Button>
          )}
          {questionnaireStatus === "active" && (
            <Button
              type="primary"
              style={{
                height: "40px",
                borderRadius: "12px",
                marginLeft: "auto",
              }}
              className="me-4"
              onClick={() => {
                navigate(`/question/score?id=${qid}&name=${name}`);
              }}
            >
              <span className="fw-semibold font-sm">Preview Score</span>
            </Button>
          )}
        </span>
      </div>
      <div className="preview-container">
        {questions.length > 0 ? (
          questions.map((q, index) => {
            const TypeComponent = questionTypes[q.type];
            if (!TypeComponent) {
              //Only Statements will pass this
              return (
                <div className="preview-statement d-flex" key={q.id}>
                  <div className="text-prime font-sm fw-medium">
                    {showChinese ? q.title_ch || q.title : q.title}
                  </div>
                  {q.attachment && (
                    <span
                      className="ms-3 color-primary cp"
                      onClick={() => viewImage(q.attachment)}
                    >
                      View Image
                    </span>
                  )}
                </div>
              );
            } else {
              return (
                <div className="question-preview-block" key={q.id}>
                  <div className="d-flex mb-2 align-items-center">
                    {q.questionNo && (
                      <div className="question-number fw-semibold font-m">
                        {q.questionNo}
                      </div>
                    )}
                    <div className="text-prime fw-semibold font-sm question-title">
                      {showChinese ? q.title_ch || q.title : q.title}
                      {q.required && (
                        <span className="input-block-required">*</span>
                      )}
                    </div>
                  </div>
                  {q.attachment && (
                    <div className="question-attachment">
                      <img src={q.attachment.href} alt="question-attachment" />
                    </div>
                  )}
                  <div className="question-action-container">
                    <TypeComponent
                      question={q}
                      showModal={handleSettings}
                      groupIndex={index}
                      showChinese={showChinese}
                    />
                    {q.remark && (
                      <div className="preview-text-field mt-1">
                        <div className="text-prime font-sm fw-medium mb-2">
                          Enter Remarks
                        </div>
                        <Input
                          placeholder="Type your text here"
                          className="preview-number"
                          maxLength={256}
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className="d-flex"
                    style={{ justifyContent: "flex-end", width: "100%" }}
                  >
                    <Tooltip placement="bottom" title="Configuration">
                      <CogIcon
                        className="large-hero-icon cp"
                        style={{ color: "#71717A" }}
                        onClick={() =>
                          handleSettings({
                            index: index,
                          })
                        }
                      />
                    </Tooltip>
                  </div>
                </div>
              );
            }
          })
        ) : (
          <div>No questions found</div>
        )}
      </div>
      {modalProps && (
        <QuestionPropertiesPreview
          questions={questions}
          setModal={setModal}
          modalProp={modalProps}
        />
      )}
    </div>
  );
};

export default QuestionPreview;
