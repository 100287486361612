import { useState, useEffect, useMemo, useCallback } from "react";
import {
  Menu,
  Dropdown,
  Upload,
  Tooltip,
  Button,
  Input,
  Tag,
  Avatar,
  Checkbox,
} from "antd";
import { useDispatch } from "react-redux";
import { setModalDetails } from "../../../redux/reducers/modalSlice";
import {
  deleteQuestionModule,
  activateDeactivateQuestionnaire,
  questionnaireDuplicate,
} from "../../../redux/actions/questionAction";
import {
  exportQuestionnaire,
  importQuestionnaire,
} from "../../../redux/actions/importAction";
import { useNavigate, useSearchParams } from "react-router-dom";
import { errorToastMessage, toastMessage } from "../../../helpers/toastMessage";
import { debounce } from "lodash";
import {
  ArchiveIcon,
  DotsVerticalIcon,
  DuplicateIcon,
  FilterIcon,
  PencilIcon,
  RefreshIcon,
  TrashIcon,
} from "@heroicons/react/solid";
import { UploadIcon } from "@heroicons/react/outline";
import { PictureOutlined } from "@ant-design/icons";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import { AxiosResponse } from "axios";
import http from "../../../http";
import { useAppSelector } from "../../../redux/hooks";
import { canAmendQuestions } from "../../../helpers/roles";

const StatusMap: any = {
  active: "Published",
  inactive: "Inactive",
  draft: "Draft",
  archived: "Archived",
};

const QuestionList: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const role = useAppSelector((state) => state.user.role);

  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(searchParams.get("search") || "");
  const [filter, setFilter] = useState<string[]>([]);
  const [toggleLoader, setToggleLoader] = useState(false);

  const [total, setTotal] = useState(0);
  const [data, setData] = useState<any[]>([]);

  const [hasPublishAccess] = useState(canAmendQuestions(role));

  const refreshPage = useCallback(() => {
    setToggleLoader((prev) => !prev);
  }, [setToggleLoader]);

  const deleteModule = (data: any) => {
    dispatch(deleteQuestionModule(data.id, refreshPage));
  };

  const editModule = (data: any) => {
    dispatch(
      setModalDetails({
        type: "EDIT_QUESTIONS",
        modalProps: {
          show: true,
          questionData: data,
          refreshPage,
        },
      })
    );
  };

  const exportModule = (data: any) => {
    if (data.questionCount > 0) {
      dispatch(exportQuestionnaire(data.id));
    } else {
      toastMessage("warning", "Empty Questionnaire cannot be exported");
    }
  };

  const changeStatus = (data: any) => {
    let body = {
      status: data.status === "archived" ? "active" : "archived",
    };
    dispatch(activateDeactivateQuestionnaire(data.id, body, refreshPage));
  };

  const menu = (data: any) => (
    <Menu style={{ minWidth: "225px", borderRadius: "12px" }}>
      <Menu.Item key="0" onClick={() => editModule(data)}>
        <PencilIcon className="hero-icon me-3" />
        <span className="font-sm">Edit</span>
      </Menu.Item>
      <Menu.Item key="1" onClick={() => exportModule(data)}>
        <UploadIcon className="hero-icon me-3" />
        <span className="font-sm">Export</span>
      </Menu.Item>
      <Menu.Item key="2" onClick={() => duplicateModule(data)}>
        <DuplicateIcon className="hero-icon me-3" />
        <span className="font-sm">Duplicate</span>
      </Menu.Item>
      {data.status !== "draft" && (
        <Menu.Item key="3" onClick={() => changeStatus(data)}>
          {data.status === "archived" ? (
            <RefreshIcon className="hero-icon me-3" />
          ) : (
            <ArchiveIcon className="hero-icon me-3" />
          )}
          <span className="font-sm">
            {data.status === "archived" ? "Restore" : "Archive"}
          </span>
        </Menu.Item>
      )}
      {data.status !== "active" && (
        <>
          <Menu.Divider />
          <Menu.Item key="4" danger onClick={() => deleteModule(data)}>
            <TrashIcon className="hero-icon me-3" />
            <span className="font-sm">Delete</span>
          </Menu.Item>
        </>
      )}
    </Menu>
  );

  const fetchData = useCallback(
    async (search: string, page: number, append: boolean, filter: string[]) => {
      try {
        dispatch(setAppLoader(true));
        let url = `/questionnaires?page=${page}&size=12`;
        if (search) {
          url += `&search=${search}`;
        }
        if (filter.length > 0) {
          url += `&status=${filter.join(",")}`;
        }
        const res: AxiosResponse = await http.get(url);
        if (append) {
          setData((prev) => [...prev, ...res.data.data.questionnaires]);
          setPage((prev) => prev + 1);
        } else {
          setData(res.data.data.questionnaires);
          setPage(1);
        }
        setTotal(res.data.data.count);
        dispatch(setAppLoader(false));
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    },
    [dispatch, setTotal, setPage, setData]
  );

  useEffect(() => {
    fetchData(search, 1, false, filter);
  }, [search, fetchData, filter, toggleLoader]);

  const loadMore = () => {
    fetchData(search, page + 1, true, filter);
  };

  const addQuestionnaire = () => {
    dispatch(
      setModalDetails({
        type: "ADD_QUESTIONS",
        modalProps: { show: true, refreshPage },
      })
    );
  };

  const handleFilter = (filter: string) => {
    setFilter((prev) => {
      if (prev.includes(filter)) {
        return prev.filter((fil) => fil !== filter);
      } else {
        return [...prev, filter];
      }
    });
  };

  const handleSearch = useMemo(
    () =>
      debounce((event: any) => {
        setSearch(event.target.value);
      }, 500),
    [setSearch]
  );

  const duplicateModule = (data: any) => {
    dispatch(questionnaireDuplicate(data.id, refreshPage));
  };

  useEffect(() => {
    const params = new URLSearchParams();
    if (search) {
      params.set("search", search);
    }
    setSearchParams(params, {
      replace: true,
    });
  }, [search, setSearchParams]);

  const filterMenu = (
    <Menu style={{ width: "230px", borderRadius: "8px" }}>
      <Menu.Item
        key="draft"
        className={filter.includes("draft") ? "selected" : ""}
        onClick={() => handleFilter("draft")}
      >
        <Checkbox checked={filter.includes("draft")} className="me-3" />
        Draft
      </Menu.Item>
      <Menu.Item
        key="published"
        className={filter.includes("active") ? "selected" : ""}
        onClick={() => handleFilter("active")}
      >
        <Checkbox checked={filter.includes("active")} className="me-3" />
        Published
      </Menu.Item>
      <Menu.Item
        key="archived"
        className={filter.includes("archived") ? "selected" : ""}
        onClick={() => handleFilter("archived")}
      >
        <Checkbox checked={filter.includes("archived")} className="me-3" />
        Archived
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="clear" onClick={() => setFilter([])}>
        Clear Filter
      </Menu.Item>
    </Menu>
  );

  const handleNavigation = (module: any) => {
    const editLink = `/question/builder?id=${module.id}&name=${module.name}`;
    const previewLink = `/question/preview?id=${module.id}&name=${module.name}`;
    const link =
      module.status === "active" || !hasPublishAccess ? previewLink : editLink;
    navigate(link);
  };

  const importQuestion = (file: any) => {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      var result = JSON.parse(JSON.stringify(e.target.result));
      dispatch(importQuestionnaire(JSON.parse(result), refreshPage));
    };
    reader.readAsText(file);
    return false;
  };

  return (
    <div className="cms-wrapper">
      <div className="content-header">
        <div className="font-m fw-semibold font-l me-5 text-secondary">
          Questionnaires
        </div>
        <Input
          onChange={handleSearch}
          defaultValue={search}
          className="singer-input"
          style={{ width: "300px" }}
          placeholder="Search for questionnaires"
        />
        <span style={{ marginLeft: "auto" }}></span>
        {hasPublishAccess && (
          <>
            <Tooltip title="Accepted formats: JSON">
              <Upload
                showUploadList={false}
                multiple={true}
                accept="application/json"
                beforeUpload={importQuestion}
              >
                <Button
                  type="primary"
                  style={{ height: "40px", borderRadius: "12px" }}
                  className="me-3"
                >
                  <span className="fw-semibold font-sm">Import/Upload</span>
                </Button>
              </Upload>
            </Tooltip>
            <Button
              type="primary"
              style={{ height: "40px", borderRadius: "12px" }}
              onClick={addQuestionnaire}
            >
              <span className="fw-semibold font-sm">Add Questionnaire</span>
            </Button>
          </>
        )}
      </div>

      <div className="modules-wrapper question-module-wrapper">
        <div className="modules-header">
          <div className="d-flex align-items-center">
            <div className="font-m fw-semibold text-prime">
              All {total} Questionnaires
            </div>
            <span className="ms-3 d-flex">
              {filter.map((status: string) => {
                return (
                  <Tag
                    closable
                    onClose={() => handleFilter(status)}
                    key={status}
                  >
                    {StatusMap[status]}
                  </Tag>
                );
              })}
            </span>
          </div>
          <Dropdown
            overlay={filterMenu}
            placement="bottomCenter"
            trigger={["click"]}
          >
            <Button
              className="d-flex align-items-center"
              style={{ height: "40px", borderRadius: "12px" }}
            >
              <FilterIcon className="hero-small-icon" />
              <span className="fw-semibold font-sm ms-1">Filters</span>
            </Button>
          </Dropdown>
        </div>
        {data.length > 0 ? (
          <div className="modules-content">
            {data.map((module: any) => {
              return (
                <div
                  className="singer-card cp"
                  key={module.id}
                  onClick={() => handleNavigation(module)}
                >
                  <Avatar
                    size={90}
                    src={module.imageUrl}
                    shape={"square"}
                    icon={<PictureOutlined style={{ verticalAlign: "3px" }} />}
                  />
                  <div className="education-details">
                    <div className="medium-header-text">{module.name}</div>
                    <div className="pages">
                      {module.questionCount} questions
                    </div>
                    <div
                      className={
                        module.status === "draft"
                          ? "draft status-tag"
                          : "status-tag"
                      }
                    >
                      {StatusMap[module.status]}
                    </div>
                  </div>
                  {hasPublishAccess && (
                    <div
                      className="optionsIcon"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Dropdown overlay={menu(module)} trigger={["click"]}>
                        <DotsVerticalIcon
                          className="hero-icon cp"
                          style={{ color: "#71717A", marginLeft: "auto" }}
                        />
                      </Dropdown>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <div className="no-match-found">
            {search || filter.length > 0
              ? "No matches Found"
              : "No questionnaires present"}
          </div>
        )}
        {data.length < total && (
          <div className="d-flex align-items-center justify-content-center mt-5">
            <Button
              type="primary"
              style={{ height: "40px", borderRadius: "12px" }}
              className="me-3"
              onClick={loadMore}
            >
              <span className="fw-semibold font-sm">Load More</span>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestionList;
