import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { Table } from "antd";
import { EyeIcon } from "@heroicons/react/outline";
import { CustomPagination, PaginationText } from "../Common/TableUtils";
import { errorToastMessage } from "../../helpers/toastMessage";
import { setAppLoader } from "../../redux/reducers/loaderSlice";
import http from "../../http";
import moment from "moment";
import {
  ClosedQueryIcon,
  NewQueryIcon,
  ResolvedQueryIcon,
} from "../Common/Icons";
import { canAmendQueries } from "../../helpers/roles";

const queryFilters = [
  {
    text: "Open",
    value: "open",
  },
  {
    text: "Resolved",
    value: "resolved",
  },
  {
    text: "Closed",
    value: "closed",
  },
];

const OpenStatus = () => {
  return (
    <div className="d-flex">
      <NewQueryIcon />
      <span className="ms-3 fw-bold">Open</span>
    </div>
  );
};

const ClosedStatus = () => {
  return (
    <div className="d-flex">
      <ClosedQueryIcon />
      <span className="ms-3 fw-bold">Closed</span>
    </div>
  );
};

const ResolvedStatus = () => {
  return (
    <div className="d-flex">
      <ResolvedQueryIcon />
      <span className="ms-3 fw-bold">Resolved</span>
    </div>
  );
};

const statusMap: any = {
  open: OpenStatus,
  closed: ClosedStatus,
  resolved: ResolvedStatus,
};

const Queries = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState("");
  const [data, setData] = useState<any[]>([]);
  const role = useAppSelector((state) => state.user.role);
  const [queryAccess] = useState(canAmendQueries(role));

  //   const [toggle, setToggle] = useState(false);
  //   const toggleRef = useRef<boolean>(toggle);

  const fetchDetails = useCallback(
    async (page: number, filter?: string) => {
      try {
        dispatch(setAppLoader(true));
        let url = `/queries?paginate=true&page=${page}&size=8`;
        if (filter) {
          url += `&status=${filter}`;
        }
        const res = await http.get(url);
        const rows = res.data.data.rows.map((row: any) => {
          return {
            id: row.id,
            createdAt: moment(row.createdAt).format("DD/MM/YYYY hh:mm A"),
            createdBy: row.createdBy?.firstName + " " + row.createdBy?.lastName,
            location: {
              phase: row?.phase?.name,
              step: row?.questionnaire?.name,
            },
            remark: row.remark,
            userId: row?.userId,
            user: row?.user?.participant?.subjectId || row?.user?.screenId,
            status: row.status,
            phase: row?.phase,
            phaseId: row?.phaseId,
            qid: row?.questionnaireId,
          };
        });
        setTotal(res.data.data.count);
        setData(rows);
        dispatch(setAppLoader(false));
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    },
    [dispatch, setTotal, setData]
  );

  useEffect(() => {
    const fetch = () => {
      fetchDetails(1, filter);
      setCurrent(1);
    };
    fetch();
  }, [fetchDetails, filter]);

  //   useEffect(() => {
  //     if (toggle !== toggleRef.current) {
  //       const fetch = () => {
  //         fetchDetails(current, filter);
  //       };
  //       fetch();
  //       toggleRef.current = toggle;
  //     }
  //   }, [dispatch, toggle, current, filter]);

  //   const menu = (data: any) => (
  //     <Menu style={{ width: "120px" }}>
  //       <Menu.Item key="edit">Edit</Menu.Item>
  //     </Menu>
  //   );

  const navigateToQuery = (record: any) => {
    sessionStorage.setItem("crf-phase", record.phaseId);
    sessionStorage.setItem("crf-question", record.qid);
    const crfType = record?.phase?.isScreening ? "screening" : "study";
    navigate(`/crf/${crfType}/${record.userId}?patientId=${record.user}`);
  };

  const columns = [
    {
      title: "Creation Date",
      key: "createdAt",
      dataIndex: "createdAt",
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (location: any) => {
        return (
          <div>
            <span className="fw-semibold">{"Phase: "}</span>
            <span>{location?.phase}</span>
            <span className="fw-semibold">{" Form: "}</span>
            <span>{location.step}</span>
          </div>
        );
      },
    },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: queryFilters,
      filterMultiple: false,
      filteredValue: filter ? [filter] : null,
      render: (text: any, record: any) => {
        const Icon = statusMap[text];
        return (
          <span className="d-flex">
            {Icon ? <Icon /> : null}
            {queryAccess && (
              <EyeIcon
                className="hero-icon ms-3 cp"
                onClick={() => navigateToQuery(record)}
              />
            )}
          </span>
        );
      },
    },
    // {
    //   title: "",
    //   width: "10px",
    //   className: "text-end",
    //   render: (_1: any, record: any) => {
    //     return (
    //       <span onClick={(e) => e.stopPropagation()} className="d-inline-block">
    //         <Dropdown
    //           overlay={menu(record)}
    //           placement="bottomRight"
    //           trigger={["click"]}
    //         >
    //           <DotsVerticalIcon
    //             className="hero-icon cp"
    //             style={{ color: "#71717A", marginLeft: "auto" }}
    //           />
    //         </Dropdown>
    //       </span>
    //     );
    //   },
    // },
  ];

  const onTableChange = async (pagination: any, filters: any) => {
    let page = 1;
    if (pagination.current) {
      setCurrent(pagination.current);
      page = pagination.current;
    }
    let filter = "";
    if (filters.status) {
      setFilter(filters.status[0]);
      filter = filters.status[0];
    } else {
      setFilter("");
    }
    fetchDetails(page, filter);
  };

  return (
    <div className="cms-wrapper">
      <div className="content-header">
        <div className="font-m fw-semibold font-l me-5 text-secondary">
          Queries
        </div>
      </div>
      <div className="modules-wrapper question-module-wrapper">
        <Table
          dataSource={data}
          columns={columns}
          className="singer-custom-table"
          onChange={onTableChange}
          rowKey={(record) => record.id}
          pagination={{
            current: current,
            total: total,
            showSizeChanger: false,
            pageSize: 8,
            hideOnSinglePage: true,
            className: "singer-table-pagination",
            itemRender: CustomPagination,
            showTotal: PaginationText,
            position: ["bottomLeft"],
          }}
        />
      </div>
    </div>
  );
};

export default Queries;
