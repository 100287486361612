import { Modal, Table } from "antd";
import moment from "moment";
import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { severityMap } from "../../IncidentReporting/IncidentReporting";

const ViewIncidentVersionModal = () => {
  const dispatch = useAppDispatch();
  const { show, versions } = useAppSelector((state) => state.modal.modalProps);

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const columns: any[] = useMemo(() => {
    return [
      {
        title: "Revision Done By",
        dataIndex: "user",
        key: "user",
      },
      {
        title: "Revision Date",
        dataIndex: "updatedDate",
        key: "updatedDate",
      },
      {
        title: "Reason",
        dataIndex: "reason",
        key: "reason",
      },
      {
        title: "Previous Value",
        dataIndex: "prevValue",
        key: "prevValue",
        render: (data: any) => {
          return (
            <>
              {data ? (
                <>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">Date : </span>
                    <span>
                      {data?.reportedOn
                        ? moment(data?.reportedOn).format("DD/MM/YYYY")
                        : "-"}
                    </span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      Adverse Event Description :{" "}
                    </span>
                    <span>{data?.comment || "-"}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      Start Date :{" "}
                    </span>
                    <span>
                      {data?.startDate
                        ? moment(data?.startDate).format("DD/MM/YYYY")
                        : "-"}
                    </span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      End Date :{" "}
                    </span>
                    <span>
                      {data?.endDate
                        ? moment(data?.endDate).format("DD/MM/YYYY")
                        : "-"}
                    </span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      Severity :{" "}
                    </span>
                    <span>{severityMap[data?.severity] || "-"}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      Outcome :{" "}
                    </span>
                    <span>{data.outcome || "-"}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      Relationship :{" "}
                    </span>
                    <span>{data.relationship || "-"}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      Action taken :{" "}
                    </span>
                    <span>{data.actionTaken || "-"}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      Serious :{" "}
                    </span>
                    <span>{data.serious ? "Yes" : "No"}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      Ongoing :{" "}
                    </span>
                    <span>{data.isOngoing ? "Yes" : "No"}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      AE Treatment :{" "}
                    </span>
                    <span>{data.aeTreatment || "-"}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      Assessor Initials :{" "}
                    </span>
                    <span>{data.accessorInitials || "-"}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      Assessed Date :{" "}
                    </span>
                    <span>
                      {data?.accessorInitialsDate
                        ? moment(data?.accessorInitialsDate).format(
                            "DD/MM/YYYY"
                          )
                        : "-"}
                    </span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      PI Initials :{" "}
                    </span>
                    <span>{data.piInitials || "-"}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      PI Date :{" "}
                    </span>
                    <span>
                      {data?.piInitialsDate
                        ? moment(data?.piInitialsDate).format("DD/MM/YYYY")
                        : "-"}
                    </span>
                  </div>
                </>
              ) : (
                "-"
              )}
            </>
          );
        },
      },
      {
        title: "New Value",
        dataIndex: "newValue",
        key: "newValue",
        render: (data: any) => {
          return (
            <>
              {data ? (
                <>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">Date : </span>
                    <span>
                      {data?.reportedOn
                        ? moment(data?.reportedOn).format("DD/MM/YYYY")
                        : "-"}
                    </span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      Adverse Event Description :{" "}
                    </span>
                    <span>{data?.comment || "-"}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      Start Date :{" "}
                    </span>
                    <span>
                      {data?.startDate
                        ? moment(data?.startDate).format("DD/MM/YYYY")
                        : "-"}
                    </span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      End Date :{" "}
                    </span>
                    <span>
                      {data?.endDate
                        ? moment(data?.endDate).format("DD/MM/YYYY")
                        : "-"}
                    </span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      Severity :{" "}
                    </span>
                    <span>{severityMap[data?.severity] || "-"}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      Outcome :{" "}
                    </span>
                    <span>{data.outcome || "-"}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      Relationship :{" "}
                    </span>
                    <span>{data.relationship || "-"}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      Action taken :{" "}
                    </span>
                    <span>{data.actionTaken || "-"}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      Serious :{" "}
                    </span>
                    <span>{data.serious ? "Yes" : "No"}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      Ongoing :{" "}
                    </span>
                    <span>{data.isOngoing ? "Yes" : "No"}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      AE Treatment :{" "}
                    </span>
                    <span>{data.aeTreatment || "-"}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      Assessor Initials :{" "}
                    </span>
                    <span>{data.accessorInitials || "-"}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      Assessed Date :{" "}
                    </span>
                    <span>
                      {data?.accessorInitialsDate
                        ? moment(data?.accessorInitialsDate).format(
                            "DD/MM/YYYY"
                          )
                        : "-"}
                    </span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      PI Initials :{" "}
                    </span>
                    <span>{data.piInitials || "-"}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      PI Date :{" "}
                    </span>
                    <span>
                      {data?.piInitialsDate
                        ? moment(data?.piInitialsDate).format("DD/MM/YYYY")
                        : "-"}
                    </span>
                  </div>
                </>
              ) : (
                "-"
              )}
            </>
          );
        },
      },
    ];
  }, []);

  return (
    <Modal
      title={"Event Submission Logs"}
      visible={show}
      onCancel={closeHandler}
      width={"80vw"}
      footer={null}
    >
      <Table
        dataSource={versions}
        columns={columns}
        className="singer-custom-table"
        rowKey={(record) => record.id}
        pagination={false}
      />
    </Modal>
  );
};

export default ViewIncidentVersionModal;
