import { Modal, Table } from "antd";
import moment from "moment";
import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { hideModal } from "../../../redux/reducers/modalSlice";

const ViewMedicalHistoryVersionModal = () => {
  const dispatch = useAppDispatch();
  const { show, versions } = useAppSelector((state) => state.modal.modalProps);

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const columns: any[] = useMemo(() => {
    return [
      {
        title: "Revision Done By",
        dataIndex: "user",
        key: "user",
      },
      {
        title: "Revision Date",
        dataIndex: "updatedDate",
        key: "updatedDate",
      },
      {
        title: "Reason",
        dataIndex: "reason",
        key: "reason",
      },
      {
        title: "Previous Value",
        dataIndex: "prevValue",
        key: "prevValue",
        render: (data: any) => {
          return (
            <>
              {data ? (
                <>
                  <div className="fw-semibold font-m text-prime mb-2">
                    {data?.title}
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">
                      Start Date:
                    </span>
                    <span>
                      {data.startUnknown
                        ? "Unknown" +
                          (data.startUnknownYear
                            ? " - " + data.startUnknownYear
                            : "")
                        : data.startDate
                        ? moment(data.startDate).format("DD/MM/YYYY")
                        : "-"}
                    </span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">End Date:</span>
                    <span>
                      {data.endDate
                        ? moment(data.endDate).format("DD/MM/YYYY")
                        : "-"}
                    </span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">Ongoing:</span>
                    <span>{data?.isOngoing ? "Yes" : "No"}</span>
                  </div>
                  <div className="mb-2">
                    <span className="fw-medium text-prime me-1">Comments:</span>
                    <span>{data?.comment || "-"}</span>
                  </div>
                </>
              ) : (
                "-"
              )}
            </>
          );
        },
      },
      {
        title: "New Value",
        dataIndex: "newValue",
        key: "newValue",
        render: (data: any) => {
          return (
            <>
              <div className="fw-semibold font-m text-prime mb-2">
                {data?.title}
              </div>
              <div className="mb-2">
                <span className="fw-medium text-prime me-1">Start Date:</span>
                <span>
                  {data.startDate
                    ? moment(data.startDate).format("DD/MM/YYYY")
                    : "-"}
                </span>
              </div>
              <div className="mb-2">
                <span className="fw-medium text-prime me-1">End Date:</span>
                <span>
                  {data.endDate
                    ? moment(data.endDate).format("DD/MM/YYYY")
                    : "-"}
                </span>
              </div>
              <div className="mb-2">
                <span className="fw-medium text-prime me-1">Ongoing:</span>
                <span>{data?.isOngoing ? "Yes" : "No"}</span>
              </div>
              <div className="mb-2">
                <span className="fw-medium text-prime me-1">Comments:</span>
                <span>{data?.comment || "-"}</span>
              </div>
            </>
          );
        },
      },
    ];
  }, []);

  return (
    <Modal
      title={"Medication Submission Logs"}
      visible={show}
      onCancel={closeHandler}
      width={"60rem"}
      footer={null}
    >
      <Table
        dataSource={versions}
        columns={columns}
        className="singer-custom-table"
        rowKey={(record) => record.id}
        pagination={false}
      />
    </Modal>
  );
};

export default ViewMedicalHistoryVersionModal;
