import { Formik } from "formik";
import { toastMessage, errorToastMessage } from "../../../helpers/toastMessage";
import { Modal, Input, Button, DatePicker, Switch } from "antd";
import { hideModal } from "../../../redux/reducers/modalSlice";
import http from "../../../http";
import { useAppDispatch } from "../../../redux/hooks";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";

let yup = require("yup");

let schema = yup.object().shape({
  reason: yup.string().when("id", {
    is: (id: string) => !!id,
    then: (schema: any) => schema.required("Reason is Required"),
  }),
  id: yup.string(),
  title: yup.string().required("Disorder/Diagnosis/Procedure is required"),
  startDate: yup.string().optional().nullable(),
  endDate: yup.string().optional().nullable(),
  comment: yup.string().optional().nullable(),
  isOngoing: yup.boolean().optional(),
});

type Props = {
  show: boolean;
  med: any;
  userId: string;
  callback: Function;
};

const AddMedicalHistoryModal = (props: Props) => {
  const dispatch = useAppDispatch();

  const submitHandler = async (values: any) => {
    try {
      dispatch(setAppLoader(true));
      const endDate = values.endDate
        ? moment(values.endDate, "DD/MM/YYYY").toISOString()
        : null;
      const startDate = values.startDate
        ? moment(values.startDate, "DD/MM/YYYY").toISOString()
        : null;
      const body: any = {
        reason: values.reason || undefined,
        title: values.title,
        startDate: startDate,
        endDate: endDate,
        isOngoing: values.isOngoing,
        comment: values.comment || null,
      };
      let res;
      if (values.id) {
        res = await http.patch(`/mh/user_medical_history/${values.id}`, body);
      } else {
        body.userId = props.userId;
        res = await http.post("/mh/user_medical_history", body);
      }
      dispatch(hideModal());
      dispatch(setAppLoader(false));
      toastMessage("success", res.data.message);
      props.callback();
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  const closeHandler = () => {
    dispatch(hideModal());
  };

  return (
    <Modal
      title={`${props?.med?.id ? "Edit" : "Add"} Medical History`}
      visible={props.show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <div className="add-question">
        <Formik
          initialValues={{
            id: props?.med?.id || "",
            comment: props?.med?.comment || "",
            title: props?.med?.title || "",
            startDate: props?.med?.startDate || null,
            endDate: props?.med?.endDate || null,
            isOngoing: props?.med?.isOngoing || false,
            reason: "",
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleBlur,
            handleChange,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <div>
                <div>
                  <div className="text-secondary font-m fw-medium mb-1">
                    Disorder/Diagnosis/Procedure
                  </div>
                  <Input
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="singer-input"
                    placeholder={"Disorder/Diagnosis/Procedure Name"}
                  />
                  <div className="input-error">
                    {errors.title && touched.title && errors.title}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="text-secondary font-m fw-medium mb-1">
                      Start Date
                    </div>
                    <DatePicker
                      className="date-selector"
                      format="DD/MM/YYYY"
                      value={
                        values.startDate
                          ? moment(values.startDate, "DD/MM/YYYY")
                          : null
                      }
                      style={{
                        borderRadius: "16px",
                        height: "40px",
                      }}
                      onChange={(_1, dateString) => {
                        setFieldValue("startDate", dateString);
                      }}
                      inputReadOnly
                    />
                    <div className="input-error">
                      {errors.startDate &&
                        touched.startDate &&
                        errors.startDate}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-secondary font-m fw-medium mb-1">
                      End Date
                    </div>
                    <DatePicker
                      className="date-selector"
                      format="DD/MM/YYYY"
                      value={
                        values.endDate
                          ? moment(values.endDate, "DD/MM/YYYY")
                          : null
                      }
                      style={{
                        borderRadius: "16px",
                        height: "40px",
                      }}
                      onChange={(_1, dateString) => {
                        setFieldValue("endDate", dateString);
                      }}
                      inputReadOnly
                    />
                    <div className="input-error">
                      {errors.endDate && touched.endDate && errors.endDate}
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <div className="text-secondary font-m fw-medium mb-1">
                      Comment
                    </div>
                    <TextArea
                      name="comment"
                      rows={3}
                      value={values.comment}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="singer-input"
                    />
                    <div className="input-error">
                      {touched?.comment && errors?.comment}
                    </div>
                  </div>
                  {values.id && (
                    <div>
                      <div className="text-secondary font-m fw-medium mb-1">
                        Reason for Edit
                      </div>
                      <Input
                        name="reason"
                        value={values.reason}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="singer-input"
                      />
                      <div className="input-error">
                        {touched?.reason && errors?.reason}
                      </div>
                    </div>
                  )}
                </div>
                <div className="d-flex align-items-center my-4">
                  <Switch
                    checked={values.isOngoing}
                    onChange={(checked) => setFieldValue("isOngoing", checked)}
                  />
                  <div className="font-sm fw-semibold ms-3">Ongoing</div>
                </div>
                <div className="button-container mt-4">
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ height: "40px", borderRadius: "12px" }}
                    className="me-4"
                  >
                    <span className="fw-semibold font-sm">Save</span>
                  </Button>
                  <Button
                    style={{ height: "40px", borderRadius: "12px" }}
                    className="me-4"
                    onClick={closeHandler}
                  >
                    <span className="fw-semibold font-sm">Cancel</span>
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default AddMedicalHistoryModal;
