import { DotsVerticalIcon } from "@heroicons/react/solid";
import { Dropdown, Menu, Table, Modal, Button } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { errorToastMessage } from "../../helpers/toastMessage";
import http from "../../http";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setAppLoader } from "../../redux/reducers/loaderSlice";
import { setModalDetails } from "../../redux/reducers/modalSlice";
import { severityMap } from "../IncidentReporting/IncidentReporting";
import { canEditAdverseEvents } from "../../helpers/roles";

const { confirm } = Modal;

const IncidentReporting: React.FC<any> = (props) => {
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.user.role);
  const { id } = useParams();
  const [data, setData] = useState<any[]>([]);
  const [toggle, setToggle] = useState(false);
  const [hasEditAccess] = useState(canEditAdverseEvents(role));

  useEffect(() => {
    const fetchIncidents = async (id: string) => {
      try {
        dispatch(setAppLoader(true));
        const res = await http.get(`/mh/incident-reports/${id}`);
        const incidents = res.data.data.map((incident: any) => {
          return {
            id: incident.id,
            reportedOn: incident.reportedOn
              ? moment(incident.reportedOn).format("DD/MM/YYYY")
              : null,
            startDate: incident.startDate
              ? moment(incident.startDate).format("DD/MM/YYYY")
              : null,
            endDate: incident.endDate
              ? moment(incident.endDate).format("DD/MM/YYYY")
              : null,
            outcome: incident.outcome,
            relationship: incident.relationship,
            actionTaken: incident.actionTaken,
            serious: incident.serious || false,
            isOngoing: incident.isOngoing || false,
            aeTreatment: incident.aeTreatment,
            accessorInitials: incident.accessorInitials,
            accessorInitialsDate: incident.accessorInitialsDate
              ? moment(incident.accessorInitialsDate).format("DD/MM/YYYY")
              : null,
            piInitials: incident.piInitials,
            piInitialsDate: incident.piInitialsDate
              ? moment(incident.piInitialsDate).format("DD/MM/YYYY")
              : null,
            draft: incident.draft || false,
            comment: incident.comment,
            severity: incident?.severity,
            versions: incident?.versions?.map((version: any) => {
              return {
                id: version.id,
                reason: version.reason || "-",
                updatedDate: moment(version.createdAt).format(
                  "DD/MM/YYYY hh:mm A"
                ),
                user: version?.who
                  ? version?.who?.firstName + " " + version?.who?.lastName
                  : "-",
                newValue: version?.newValues,
                prevValue: version?.prevValues,
              };
            }),
          };
        });
        setData(incidents);
        dispatch(setAppLoader(false));
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    };
    if (id) {
      fetchIncidents(id);
    }
  }, [id, toggle, dispatch, setData]);

  const toggler = () => {
    setToggle((prev) => !prev);
  };

  const addIncident = () => {
    dispatch(
      setModalDetails({
        type: "ADD_INCIDENT",
        modalProps: {
          show: true,
          userId: id,
          callback: toggler,
        },
      })
    );
  };

  const editIncident = (data: any) => {
    const { versions, ...rest } = data;
    dispatch(
      setModalDetails({
        type: "ADD_INCIDENT",
        modalProps: {
          show: true,
          userId: id,
          incident: rest,
          callback: toggler,
        },
      })
    );
  };

  const deleteHandler = async (id: string) => {
    try {
      dispatch(setAppLoader(true));
      await http.delete(`/mh/tracking/incident/${id}`);
      toggler();
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  const deleteIncident = (id: string) => {
    confirm({
      title: "Are you sure?",
      content: "This incident will no longer be available.",
      onOk() {
        deleteHandler(id);
      },
      onCancel() {},
      okText: "Delete",
      cancelText: "Cancel",
    });
  };

  const viewLogs = (data: any) => {
    dispatch(
      setModalDetails({
        type: `INCIDENT_SUBMISSION_LOG`,
        modalProps: {
          show: true,
          versions: data?.versions,
        },
      })
    );
  };

  const menu = (data: any) => (
    <Menu>
      {hasEditAccess &&
        !props.locked && [
          <Menu.Item key="1" onClick={() => editIncident(data)}>
            Edit
          </Menu.Item>,
          <Menu.Item key="2" onClick={() => deleteIncident(data.id)}>
            Delete
          </Menu.Item>,
        ]}
      <Menu.Item key="3" onClick={() => viewLogs(data)}>
        View Logs
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "Date Reported",
      dataIndex: "reportedOn",
      key: "date",
      render: (date: string) => {
        return date || "-";
      },
    },
    {
      title: "Adverse Event Description",
      dataIndex: "comment",
      key: "comment",
      render: (comment: string) => {
        return comment || "-";
      },
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "date",
      render: (date: string) => {
        return date || "-";
      },
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "date",
      render: (date: string) => {
        return date || "-";
      },
    },
    {
      title: "Severity",
      dataIndex: "severity",
      key: "severity",
      render: (severity: string) => {
        return severityMap[severity] || "-";
      },
    },
    {
      title: "Outcome",
      dataIndex: "outcome",
      key: "outcome",
      render: (outcome: string) => {
        return outcome || "-";
      },
    },
    {
      title: "Relationship",
      dataIndex: "relationship",
      key: "relationship",
      render: (relationship: string) => {
        return relationship || "-";
      },
    },
    {
      title: "Action Taken",
      dataIndex: "actionTaken",
      key: "actionTaken",
      render: (actionTaken: string) => {
        return actionTaken || "-";
      },
    },
    {
      title: "Serious",
      dataIndex: "serious",
      key: "serious",
      render: (serious: string) => {
        return serious ? "Yes" : "No";
      },
    },
    {
      title: "Ongoing",
      dataIndex: "isOngoing",
      key: "isOngoing",
      render: (ongoing: boolean) => {
        return ongoing ? "Yes" : "No";
      },
    },
    {
      title: "AE Treatment",
      dataIndex: "aeTreatment",
      key: "aeTreatment",
      render: (aeTreatment: string) => {
        return aeTreatment || "-";
      },
    },
    {
      title: "Assessor Initials",
      dataIndex: "accessorInitials",
      key: "accessorInitials",
      render: (accessorInitials: string) => {
        return accessorInitials || "-";
      },
    },
    {
      title: "Assessed Date",
      dataIndex: "accessorInitialsDate",
      key: "accessorInitialsDate",
      render: (accessorInitialsDate: string) => {
        return accessorInitialsDate || "-";
      },
    },
    {
      title: "PI Initials",
      dataIndex: "piInitials",
      key: "piInitials",
      render: (piInitials: string) => {
        return piInitials || "-";
      },
    },
    {
      title: "PI Date",
      dataIndex: "piInitialsDate",
      key: "piInitialsDate",
      render: (piInitialsDate: string) => {
        return piInitialsDate || "-";
      },
    },
    {
      title: "",
      width: "20px",
      render: (_1: any, record: any) => {
        return (
          <span onClick={(e) => e.stopPropagation()}>
            <Dropdown
              overlay={menu(record)}
              placement="bottomRight"
              trigger={["click"]}
            >
              <DotsVerticalIcon
                className="hero-icon cp"
                style={{ color: "#71717A", marginLeft: "auto" }}
              />
            </Dropdown>
          </span>
        );
      },
    },
  ];

  return (
    <div className="crf-question-container">
      <div className="fw-semibold font-ml color-primary crf-header">
        <span>Adverse Events</span>
        {!props.locked && hasEditAccess && (
          <Button
            type="primary"
            style={{ height: "40px", borderRadius: "12px" }}
            className="me-4"
            onClick={addIncident}
          >
            <span className="fw-semibold font-sm">Report Event</span>
          </Button>
        )}
      </div>
      <Table
        dataSource={data}
        columns={columns}
        className="singer-custom-table"
        rowKey={(record) => record.id}
        pagination={false}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default IncidentReporting;
