import { DotsVerticalIcon } from "@heroicons/react/solid";
import {
  Badge,
  Checkbox,
  DatePicker,
  Dropdown,
  Input,
  Menu,
  Radio,
  Select,
} from "antd";
import moment from "moment";
import React from "react";
import { useAppDispatch } from "../../../redux/hooks";
import { setModalDetails } from "../../../redux/reducers/modalSlice";
import { QueryIcon } from "../../Common/Icons";
import {
  InputType,
  DateType,
  TextType,
  FileType,
  TimeType,
} from "./SingleType";

// function indxToAlpha(indx: number) {
//   const index = indx % 26;
//   return String.fromCharCode(65 + index);
// }

export const ChoiceType: React.FC<any> = ({
  question,
  saveAnswer,
  answer,
  showChinese,
}) => {
  const { id, fields } = question;

  const saveInput = (event: any) => {
    const choiceId = event.target.value;
    if (answer && answer?.questionChoiceId === choiceId) {
      const newAnswer = {
        ...answer,
        questionChoiceId: undefined,
      };
      if (typeof newAnswer?.textValue !== "undefined") {
        delete newAnswer["textValue"];
      }
      saveAnswer(id, newAnswer, true);
    } else if (answer) {
      const newAnswer = {
        ...answer,
        questionChoiceId: choiceId,
      };
      const otherChoice = fields.find((choice: any) => choice.isOther);
      if (
        choiceId !== otherChoice?.id &&
        typeof newAnswer?.textValue !== "undefined"
      ) {
        delete newAnswer["textValue"];
      }
      if (newAnswer.error) {
        delete newAnswer["error"];
      }
      saveAnswer(id, newAnswer, true);
    } else {
      saveAnswer(
        id,
        {
          questionChoiceId: choiceId,
        },
        true
      );
    }
  };

  const otherInputSave = (event: any) => {
    if (answer) {
      const otherChoice = fields.find((choice: any) => choice.isOther);
      if (otherChoice) {
        const val = event.target.value;
        const newAnswer = {
          ...answer,
          questionChoiceId: otherChoice.id,
          textValue: val,
        };
        if (newAnswer.error && val) {
          delete newAnswer["error"];
        }
        if (!val && question.required) {
          newAnswer["error"] = "The other label cannot be empty";
        }
        saveAnswer(id, newAnswer, true);
      }
    } else {
      const otherChoice = fields.find((choice: any) => choice.isOther);
      if (otherChoice) {
        saveAnswer(
          id,
          {
            questionChoiceId: otherChoice.id,
            textValue: event.target.value,
          },
          true
        );
      }
    }
  };

  return (
    <>
      <Radio.Group
        className="crf-choice"
        value={answer?.questionChoiceId}
        // onChange={saveInput}
      >
        {fields.map((choice: any, index: number) => (
          <Radio key={choice.id} value={choice.id} onClick={saveInput}>
            {/* <div className="alpha-text font-sm fw-semibold color-primary me-5">
              {indxToAlpha(index)}
            </div> */}
            {choice.isOther ? (
              <div className="crf-text-field mb-0 choice-input">
                <Input
                  placeholder="Others, Specify"
                  value={answer?.textValue}
                  onChange={otherInputSave}
                />
              </div>
            ) : (
              <div className="font-sm fw-semibold color-primary choice-text">
                {showChinese ? choice.label_ch || choice.label : choice.label}
              </div>
            )}
          </Radio>
        ))}
      </Radio.Group>
      {answer?.error && <div className="input-error">{answer?.error}</div>}
    </>
  );
};

export const CheckBoxType: React.FC<any> = ({
  question,
  saveAnswer,
  answer,
  showChinese,
}) => {
  const { id, fields } = question;
  const saveInput = (checkedValues: any) => {
    if (answer) {
      const otherChoice = fields.find((choice: any) => choice.isOther);
      const newAnswer = {
        ...answer,
        questionChoiceIds: checkedValues,
      };
      if (!checkedValues.includes(otherChoice?.id) && newAnswer.otherChoice) {
        delete newAnswer["otherChoice"];
      }
      if (
        !checkedValues.includes(otherChoice?.id) &&
        newAnswer.error === "The other label cannot be empty"
      ) {
        delete newAnswer["error"];
      }
      if (checkedValues.length === 0 && question.required) {
        newAnswer["error"] = "This is a required field";
      }
      if (
        checkedValues.length > 0 &&
        newAnswer.error === "This is a required field"
      ) {
        delete newAnswer["error"];
      }
      saveAnswer(id, newAnswer);
    } else {
      saveAnswer(id, {
        questionChoiceIds: checkedValues,
      });
    }
  };

  const otherInputSave = (event: any) => {
    if (answer) {
      const otherChoice = fields.find((choice: any) => choice.isOther);
      const val = event.target.value;
      if (otherChoice) {
        const newAnswer = {
          ...answer,
          otherChoice: {
            choiceId: otherChoice.id,
            textValue: val,
          },
        };
        if (answer.questionChoiceIds) {
          const newQuestionIds = [...answer.questionChoiceIds];
          if (!newQuestionIds.includes(otherChoice.id)) {
            newQuestionIds.push(otherChoice.id);
          }
          if (val) {
            delete newAnswer["error"];
          }
          if (!val) {
            newAnswer["error"] = "The other label cannot be empty";
          }
          if (newAnswer.error === "This is a required field") {
            delete newAnswer["error"];
          }
          saveAnswer(id, {
            ...newAnswer,
            questionChoiceIds: newQuestionIds,
          });
        } else {
          if (!val) {
            newAnswer["error"] = "The other label cannot be empty";
          }
          if (newAnswer.error === "This is a required field") {
            delete newAnswer["error"];
          }
          saveAnswer(id, {
            ...newAnswer,
            questionChoiceIds: [otherChoice.id],
          });
        }
      }
    } else {
      const otherChoice = fields.find((choice: any) => choice.isOther);
      if (otherChoice) {
        saveAnswer(id, {
          questionChoiceIds: [otherChoice.id],
          otherChoice: {
            choiceId: otherChoice.id,
            textValue: event.target.value,
          },
        });
      }
    }
  };

  return (
    <>
      <Checkbox.Group
        className="crf-choice"
        value={answer?.questionChoiceIds}
        onChange={saveInput}
      >
        {fields.map((choice: any, index: number) => (
          <Checkbox key={choice.id} value={choice.id}>
            {/* <div className="alpha-text font-sm fw-semibold color-primary me-5">
              {indxToAlpha(index)}
            </div> */}
            {choice.isOther ? (
              <div className="crf-text-field mb-0 choice-input">
                <Input
                  placeholder="Others, Specify"
                  value={answer?.otherChoice?.textValue}
                  onChange={otherInputSave}
                />
              </div>
            ) : (
              <div className="font-sm fw-semibold color-primary choice-text">
                {showChinese ? choice.label_ch || choice.label : choice.label}
              </div>
            )}
          </Checkbox>
        ))}
      </Checkbox.Group>
      {answer?.error && <div className="input-error">{answer?.error}</div>}
    </>
  );
};

export const LikertType: React.FC<any> = ({
  question,
  answer,
  saveAnswer,
  showChinese,
}) => {
  const { id, fields } = question;

  const saveInput = (value: any) => {
    if (answer && answer?.questionChoiceId === value) {
      const newAnswer = {
        ...answer,
        questionChoiceId: undefined,
      };
      saveAnswer(id, newAnswer, true);
    } else if (answer) {
      const newAnswer = {
        ...answer,
        questionChoiceId: value,
      };
      if (newAnswer.error) {
        delete newAnswer["error"];
      }
      saveAnswer(id, newAnswer, true);
    } else {
      saveAnswer(
        id,
        {
          questionChoiceId: value,
        },
        true
      );
    }
  };

  return (
    <>
      <div className="crf-likert">
        {fields.map((choice: any) => (
          <div
            key={choice.id}
            onClick={() => saveInput(choice.id)}
            className="likert-option cp"
          >
            <Radio checked={choice.id === answer?.questionChoiceId} />
            <div>
              {showChinese ? choice.label_ch || choice.label : choice.label}
            </div>
          </div>
        ))}
      </div>
      {answer?.error && <div className="input-error">{answer?.error}</div>}
    </>
  );
};

export const GridType: React.FC<any> = ({
  question,
  answer,
  saveAnswer,
  showChinese,
}) => {
  const { id, properties } = question;
  const { rows, columns } = properties;

  const saveInput = (value: any, row: number, column: number, type: string) => {
    let arr = [];
    if (answer?.gridValue) {
      arr = answer.gridValue;
      // arr[row][column] = value;
      const modRow = arr[row];
      let modCol: any = modRow[column];
      modCol[type] = value;
    } else {
      arr = new Array(rows.length).fill(0).map(() => {
        return new Array(columns.length).fill(0).map(() => {
          return {};
        });
      });
      const modRow = arr[row];
      let modCol: any = modRow[column];
      modCol[type] = value;
    }
    if (answer) {
      const newAnswer = {
        ...answer,
        gridValue: arr,
      };
      if (newAnswer.error) {
        delete newAnswer["error"];
      }
      saveAnswer(id, newAnswer);
    } else {
      saveAnswer(id, {
        gridValue: arr,
      });
    }
  };

  const renderCol = (column: any, rowIndex: number, colIndex: number) => {
    switch (column.type) {
      case "short_text":
        return (
          <Input
            className="crf-number"
            placeholder="Type your text here"
            onChange={(ev) =>
              saveInput(ev.target.value, rowIndex, colIndex, "textValue")
            }
            value={answer?.gridValue?.[rowIndex]?.[colIndex]?.textValue}
          />
        );
      case "number":
        return (
          <Input
            className="crf-number"
            type="number"
            value={answer?.gridValue?.[rowIndex]?.[colIndex]?.numberValue}
            onChange={(ev) =>
              saveInput(ev.target.value, rowIndex, colIndex, "numberValue")
            }
            placeholder="Enter your value here"
          />
        );
      case "date":
        return (
          <DatePicker
            className="grid-datepicker"
            format="DD/MM/YYYY"
            onChange={(_1, dateString) => {
              const val = moment.utc(dateString, "DD/MM/YYYY").toISOString();
              saveInput(val, rowIndex, colIndex, "dateValue");
            }}
            value={
              answer?.gridValue?.[rowIndex]?.[colIndex]?.dateValue
                ? moment.utc(
                    answer?.gridValue?.[rowIndex]?.[colIndex]?.dateValue
                  )
                : null
            }
          />
        );
      case "checkbox":
        return (
          <Select
            className="grid-selector"
            size="large"
            allowClear
            style={{ textAlign: "left" }}
            onChange={(val) => saveInput(val, rowIndex, colIndex, "textValue")}
            value={answer?.gridValue?.[rowIndex]?.[colIndex]?.textValue}
          >
            {column.options.map((opt: any, index: number) => {
              let text = "";
              if (opt) {
                const split = opt.split("|");
                if (showChinese && split[1]) {
                  text = split[1];
                } else {
                  text = split[0];
                }
              } else {
                text = "Option " + (index + 1);
              }
              return (
                <Select.Option key={"opt" + index} value={opt}>
                  {text}
                </Select.Option>
              );
            })}
          </Select>
        );
      case "score_input":
        return (
          <Input
            className="crf-number"
            type="number"
            placeholder="Type your value here"
            onChange={(ev) =>
              saveInput(ev.target.value, rowIndex, colIndex, "numberValue")
            }
            value={answer?.gridValue?.[rowIndex]?.[colIndex]?.numberValue}
          />
        );
    }
  };

  return (
    <>
      <table className="grid-crf mb-1">
        <thead>
          <tr>
            <th></th>
            {columns.map((col: any, idx: number) => {
              return (
                <th key={idx}>
                  {(showChinese ? col.label_ch || col.label : col.label) ||
                    "Column " + (idx + 1)}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {rows.map((row: any, rowIdx: number) => {
            return (
              <tr key={rowIdx}>
                <td key={"row" + rowIdx}>
                  {(showChinese ? row.label_ch || row.label : row.label) ||
                    "Row " + (rowIdx + 1)}
                </td>
                {columns.map((col: any, colIdx: number) => {
                  return (
                    <td key={"col" + colIdx}>
                      {renderCol(col, rowIdx, colIdx)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {answer?.error && <div className="input-error">{answer?.error}</div>}
    </>
  );
};

const questionTypes: any = {
  short_text: TextType,
  long_text: TextType,
  number: InputType,
  score_input: InputType,
  multiple_choice: ChoiceType,
  yes_no: ChoiceType,
  checkbox: CheckBoxType,
  likert_scale: LikertType,
  date: DateType,
  date_time: DateType,
  upload_file: FileType,
  grid: GridType,
  time: TimeType,
};

export const GroupType: React.FC<any> = ({
  question,
  answer,
  saveAnswer,
  hideQuestions,
  showChinese,
  selectedQuestionniare,
  userId,
  fetchQueries,
  query,
}) => {
  const dispatch = useAppDispatch();

  const viewImage = (attachment: any) => {
    dispatch(
      setModalDetails({
        type: "VIEW_IMAGE",
        modalProps: {
          show: true,
          attachment: attachment,
        },
      })
    );
  };

  const openQuery = (ques: any) => {
    dispatch(
      setModalDetails({
        type: "SHOW_QUERY",
        modalProps: {
          show: true,
          selectedQuestionniare: selectedQuestionniare,
          questionId: ques.id,
          userId: userId,
          fetchQueries: fetchQueries,
          title: ques.title,
        },
      })
    );
  };

  const viewQuery = (question: any, query: any) => {
    dispatch(
      setModalDetails({
        type: "VIEW_QUERY",
        modalProps: {
          show: true,
          userId: userId,
          fetchQueries: fetchQueries,
          title: question.title,
          queries: query,
        },
      })
    );
  };

  const commentQuery = (question: any, query: any) => {
    dispatch(
      setModalDetails({
        type: "QUERY_COMMENT",
        modalProps: {
          show: true,
          title: question.title,
          queries: query,
        },
      })
    );
  };

  const menu = (ques: any, query: any) => (
    <Menu>
      <Menu.Item key="1" onClick={() => openQuery(ques)}>
        Add Query
      </Menu.Item>
      {query && (
        <>
          <Menu.Item key="2" onClick={() => viewQuery(question, query)}>
            View Queries
          </Menu.Item>
          <Menu.Item key="3" onClick={() => commentQuery(question, query)}>
            View Comments
          </Menu.Item>
        </>
      )}
    </Menu>
  );

  return (
    <>
      {question.questions.map((q: any) => {
        const TypeComponent = questionTypes[q.type];
        if (!TypeComponent) {
          //Only Statements will pass this
          return (
            <div className="crf-statement d-flex" key={q.id}>
              <div className="text-prime font-sm fw-medium">
                {showChinese ? q.title_ch || q.title : q.title}
              </div>
              {q.attachment && (
                <span
                  className="ms-3 color-primary cp"
                  onClick={() => viewImage(q.attachment)}
                >
                  View Image
                </span>
              )}
            </div>
          );
        } else if (hideQuestions.includes(q.id)) {
          return null;
        } else {
          const subAnswer = answer[q.id];
          const subQuery = query?.[q.id];
          return (
            <div className="crf-question-block" key={q.id}>
              <div className="d-flex mb-2 align-items-center">
                {q.questionNo && (
                  <div className="question-number fw-semibold font-m">
                    {q.questionNo}
                  </div>
                )}
                <div className="text-prime fw-semibold font-sm question-title">
                  {showChinese ? q.title_ch || q.title : q.title}
                  {q.required && (
                    <span className="input-block-required">*</span>
                  )}
                </div>
                {subQuery &&
                  subQuery?.filter((q: any) => q.status !== "closed").length >
                    0 && (
                    <Badge
                      count={
                        subQuery.filter((q: any) => q.status !== "closed")
                          .length
                      }
                      className="me-3"
                    >
                      <QueryIcon />
                    </Badge>
                  )}
                <Dropdown
                  overlay={() => menu(q, subQuery)}
                  placement="bottomRight"
                  trigger={["click"]}
                >
                  <DotsVerticalIcon
                    className="hero-icon cp"
                    style={{ color: "#71717A", marginLeft: "auto" }}
                  />
                </Dropdown>
              </div>
              {q.attachment && (
                <div className="question-attachment">
                  <img src={q.attachment.href} alt="question-attachment" />
                </div>
              )}
              <div className="question-action-container">
                <TypeComponent
                  question={q}
                  saveAnswer={saveAnswer}
                  answer={subAnswer}
                  showChinese={showChinese}
                />
                {q.remark && (
                  <GroupRemark
                    question={q}
                    answer={subAnswer}
                    saveAnswer={saveAnswer}
                  />
                )}
              </div>
            </div>
          );
        }
      })}
    </>
  );
};

const GroupRemark: React.FC<any> = ({ question, answer, saveAnswer }) => {
  const { id } = question;
  const saveRemark = (event: any) => {
    if (answer) {
      saveAnswer(id, {
        ...answer,
        remarkValue: event.target.value,
      });
    } else {
      saveAnswer(id, {
        remarkValue: event.target.value,
      });
    }
  };

  return (
    <div className="crf-text-field mt-1">
      <div className="text-prime font-sm fw-medium mb-2">Enter Remarks</div>
      <Input
        placeholder="Type your text here"
        className="crf-number"
        maxLength={256}
        value={answer?.remarkValue}
        onChange={saveRemark}
      />
    </div>
  );
};
