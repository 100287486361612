import logo from "../../assets/img/mahalo-logo.svg";

const DeleteAccount = () => {
  return (
    <>
      <div
        className="content-header"
        style={{
          position: "static",
          width: "100%",
          height: "103px",
        }}
      >
        <img
          src={logo}
          alt="logo"
          style={{
            maxWidth: "140px",
            maxHeight: "140px",
          }}
        />
      </div>
      <div
        style={{
          height: "40px",
          width: "100%",
          backgroundColor: "#f2f2f2",
        }}
      />
      <div className="delete-account-wrapper">
        <div className="policy-container">
          <div className="policy-bordered-container">
            <p className="title">Steps for Deleting your Account</p>
            <div
              className="flex-container"
              style={{
                marginRight: "10%",
                marginBottom: "80px",
              }}
            >
              <img
                src="/step_1.png"
                alt="step-1"
                style={{
                  width: "323px",
                  height: "609px",
                  marginRight: "20%",
                  marginBottom: "10px",
                  maxWidth: "100%",
                }}
              />
              <div>
                <p className="subtitle">Step 1</p>
                <p className="info">
                  From the home screen Tap on{" "}
                  <span
                    style={{
                      fontWeight: 600,
                      color: "#5091CD",
                    }}
                  >
                    Menu
                  </span>
                </p>
              </div>
            </div>
            <div
              className="flex-container"
              style={{
                marginBottom: "80px",
              }}
            >
              <div>
                <p className="subtitle">Step 2</p>
                <p className="info">
                  Tap on the{" "}
                  <span
                    style={{
                      fontWeight: 600,
                      color: "#D80000",
                    }}
                  >
                    Delete Account{" "}
                  </span>
                  Option in the bottom of the screen.
                </p>
              </div>
              <img
                src="/step_2.png"
                alt="step-2"
                style={{
                  width: "340px",
                  height: "626px",
                  marginLeft: "auto",
                  marginTop: "10px",
                  maxWidth: "100%",
                }}
              />
            </div>
            <div className="flex-container">
              <img
                src="/step_3.png"
                alt="step-3"
                style={{
                  width: "282px",
                  height: "611px",
                  marginRight: "20%",
                  marginBottom: "10px",
                  maxWidth: "100%",
                }}
              />
              <div>
                <p className="subtitle">Step 3</p>
                <p className="info">
                  On confirming{" "}
                  <span
                    style={{
                      fontWeight: 600,
                      color: "#D80000",
                    }}
                  >
                    Delete{" "}
                  </span>
                  , All data related to this account such as <b>Username</b> ,
                  <b> Password </b>
                  and <b>Email</b> will be deleted.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteAccount;
