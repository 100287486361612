import { Modal, Input, Button, Select } from "antd";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { useAppDispatch } from "../../../redux/hooks";
import { useState } from "react";
import { errorToastMessage, toastMessage } from "../../../helpers/toastMessage";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import http from "../../../http";
import moment from "moment";

type Props = {
  show: true;
  userId: string;
  fetchQueries: Function;
  title: string;
  queries: any[];
};

const ViewQueryModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const [remark, setRemark] = useState("");
  const [status, setStatus] = useState<any>(null);
  const [selectedQuery, setSelectedQuery] = useState<any>(null);

  const changeHandler = (event: any) => {
    setRemark(event.target.value);
  };

  const submitHandler = async () => {
    try {
      if (!remark.trim()) {
        toastMessage("warning", "Remark cannot be empty");
        return;
      }
      if (!status) {
        toastMessage("warning", "Please select a status");
        return;
      }
      dispatch(setAppLoader(true));
      const body = {
        status: status,
        remark: remark,
      };
      let res = await http.patch(`/queries/${selectedQuery?.id}`, body);
      toastMessage("success", res.data.message);
      closeHandler();
      props.fetchQueries();
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const handleQuerySelection = async (qid: any) => {
    try {
      dispatch(setAppLoader(true));
      let res = await http.get(`/queries/${qid}`);
      const query = {
        id: res.data.data?.id,
        remark: res.data.data?.remark,
        status: res.data.data?.status,
        createdBy: res.data.data?.createdBy
          ? res.data.data?.createdBy?.firstName +
            " " +
            res.data.data?.createdBy?.lastName
          : null,
        createdAt: moment(res.data.data?.createdAt).format(
          "DD MMM YYYY hh:mm A"
        ),
      };
      setStatus(null);
      setRemark("");
      setSelectedQuery(query);
      dispatch(setAppLoader(false));
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  const statusHandler = (status: string) => {
    setStatus(status);
  };

  return (
    <Modal
      title={"Queries for " + props.title}
      visible={props.show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <div>
        <div className="col-md-12 mb-4">
          <div className="text-secondary font-m fw-medium mb-1">
            Select a query
          </div>
          <Select
            size="large"
            className="custom-select-field"
            value={selectedQuery?.id}
            onChange={handleQuerySelection}
            placeholder="Select query"
          >
            {props.queries.map((query) => {
              return (
                <Select.Option key={query.id} value={query.id}>
                  {query.remark}
                </Select.Option>
              );
            })}
          </Select>
        </div>
        {selectedQuery ? (
          <>
            <div className="col-md-12 mb-4">
              <div className="text-secondary font-m fw-medium mb-1">
                Change status
              </div>
              <Select
                size="large"
                className="custom-select-field"
                value={status}
                onChange={statusHandler}
                placeholder="Select status"
              >
                {selectedQuery?.status !== "open" && (
                  <Select.Option key="open" value="open">
                    Open
                  </Select.Option>
                )}
                {!(
                  selectedQuery?.status === "resolved" ||
                  selectedQuery?.status === "closed"
                ) && (
                  <Select.Option key="resolved" value="resolved">
                    Resolved
                  </Select.Option>
                )}
                {selectedQuery?.status !== "closed" && (
                  <Select.Option key="closed" value="closed">
                    Closed
                  </Select.Option>
                )}
              </Select>
            </div>
            <div className="col-md-12 mb-4">
              <div className="form-group">
                <div className="text-secondary font-m fw-medium mb-1">
                  Remark
                </div>
                <Input.TextArea
                  name="name"
                  rows={3}
                  className="singer-input"
                  style={{ resize: "none" }}
                  value={remark}
                  onChange={changeHandler}
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-6 font-m fw-medium">Added by</div>
              <div className="col-md-6 font-m fw-medium">Date</div>
            </div>
            <div className="row mb-4">
              <div className="col-md-6 font-m">{selectedQuery?.createdBy}</div>
              <div className="col-md-6 font-m">{selectedQuery?.createdAt}</div>
            </div>
            <div className="row mb-4">
              <div className="col-md-6 font-m fw-medium">Remark</div>
              <div className="col-md-6 font-m fw-medium">Status</div>
            </div>
            <div className="row mb-4">
              <div className="col-md-6 font-m">{selectedQuery?.remark}</div>
              <div
                className="col-md-6 font-m"
                style={{ textTransform: "capitalize" }}
              >
                {selectedQuery?.status}
              </div>
            </div>
            <div className="d-flex align-items-center">
              <Button
                type="primary"
                htmlType="submit"
                style={{ height: "40px", borderRadius: "12px" }}
                className="me-4"
                onClick={submitHandler}
              >
                <span className="fw-semibold font-sm">Save</span>
              </Button>
              <Button
                style={{ height: "40px", borderRadius: "12px" }}
                className="me-4"
                onClick={closeHandler}
              >
                <span className="fw-semibold font-sm">Cancel</span>
              </Button>
            </div>
          </>
        ) : (
          <div style={{ height: "25vh" }}></div>
        )}
      </div>
    </Modal>
  );
};

export default ViewQueryModal;
