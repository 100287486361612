import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setAppLoader } from "../../redux/reducers/loaderSlice";
// import http from "../../http";
import { ChevronLeftIcon } from "@heroicons/react/solid";
import { errorToastMessage, toastMessage } from "../../helpers/toastMessage";
import moment from "moment";
import { questionResponseFormat } from "../../helpers/question";
import http from "../../http";
import { canEditCRFs, canReconcileCRFs } from "../../helpers/roles";
import { setModalDetails } from "../../redux/reducers/modalSlice";
import { Button, Dropdown, Menu, Modal } from "antd";
import QuestionBlock from "./questionTypesV2/QuestionBlock";
import { eproAnswerViewFormat, submitEpro } from "../../helpers/epro";
import { DotsVerticalIcon } from "@heroicons/react/outline";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const confirm = Modal.confirm;

const EPro = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any>([]);
  const patientId = useQuery().get("patientId");
  const [selectedPhase, setSelectedPhase] = useState<string>("");
  const [selected, setSelected] = useState<any>(null);
  const { id } = useParams();
  const [questions, setQuestions] = useState<any[]>([]);

  const [phaseToggle, setPhaseToggle] = useState(false);
  const [formDirty, setFormDirty] = useState<any>(false);
  const [answers, setAnswers] = useState<any>({});

  const { role } = useAppSelector((state) => state.user);

  const [locked, setLocked] = useState(false);

  const viewImage = (attachment: any) => {
    dispatch(
      setModalDetails({
        type: "VIEW_IMAGE",
        modalProps: {
          show: true,
          attachment: attachment,
        },
      })
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setAppLoader(true));
        const res = await http.get(`/questionnaires/daily/summary/${id}`);
        const newData = res.data.data.reduce((acc: any, currentValue: any) => {
          const existingDate: any = acc.find(
            (item: any) =>
              item.date === moment(currentValue.startsOn).format("DD/MM/YYYY")
          );
          if (existingDate) {
            existingDate.entries.push({
              id: currentValue.id,
              title: currentValue.title,
              date: existingDate.date,
              completedAt: currentValue.completedAt,
              questionnaireType: currentValue.questionnaireType,
              editable: !currentValue.dataVerifierId && canEditCRFs(role),
              dataVerified:
                currentValue.completedAt && currentValue.dataVerifierId,
              dataVerify:
                canReconcileCRFs(role) &&
                currentValue.completedAt &&
                !currentValue.dataVerifierId,
            });
          } else {
            acc.push({
              id: moment(currentValue.startsOn).format("DD/MM/YYYY"),
              date: moment(currentValue.startsOn).format("DD/MM/YYYY"),
              entries: [
                {
                  id: currentValue.id,
                  title: currentValue.title,
                  date: moment(currentValue.startsOn).format("DD/MM/YYYY"),
                  completedAt: currentValue.completedAt,
                  questionnaireType: currentValue.questionnaireType,
                  editable: !currentValue.dataVerifierId && canEditCRFs(role),
                  dataVerified:
                    currentValue.completedAt && currentValue.dataVerifierId,
                  dataVerify:
                    canReconcileCRFs(role) && currentValue.completedAt,
                },
              ],
            });
          }
          return acc;
        }, []);
        setData(newData);
        setLocked(false);
        setSelectedPhase((prev: any) => (prev ? prev : newData[0]?.id));
        setSelected((prev: any) => {
          let queryQuestionnaire = null;
          const queryPhase = newData.find(
            (phase: any) => phase.date === prev?.date
          );
          if (queryPhase) {
            queryQuestionnaire = queryPhase.entries.find(
              (ques: any) => ques.id === prev?.id
            );
          }
          return queryQuestionnaire || newData[0]?.entries?.[0] || null;
        });
        if (newData.length === 0) dispatch(setAppLoader(false));
      } catch (err) {
        errorToastMessage(err as Error);
        dispatch(setAppLoader(false));
      }
    };

    fetchData();
  }, [dispatch, setData, setLocked, phaseToggle, id, role]);

  useEffect(() => {
    const fetchQues = async () => {
      try {
        dispatch(setAppLoader(true));
        setQuestions([]);
        setAnswers({});
        setFormDirty(false);
        const res = await http.get(
          `/questionnaires/daily/questions/${selected.questionnaireType}`
        );
        let questions = await questionResponseFormat(res.data.data, []);
        setQuestions(questions);
        if (selected.completedAt) {
          const res = await http.get(
            `/questionnaires/daily/summary/${id}/${selected.id}/responses`
          );
          const newAnswer = eproAnswerViewFormat(res.data.data);
          setAnswers(newAnswer);
        }
        dispatch(setAppLoader(false));
      } catch (err) {
        errorToastMessage(err as Error);
        dispatch(setAppLoader(false));
      }
    };
    if (selected) {
      fetchQues();
    }
  }, [dispatch, setData, selected, id]);

  const handleBack = () => {
    navigate(-1);
  };

  const saveAnswer = (key: string, answerObj: any) => {
    if (selected?.editable && !locked) {
      setAnswers((ans: any) => {
        const newAns = {
          ...ans,
          [key]: answerObj,
        };
        return newAns;
      });
      if (selected?.completedAt) {
        setFormDirty(true);
      }
    }
  };

  const submitRequest = async (
    value: any,
    reason?: string,
    draft?: boolean
  ) => {
    try {
      const body: any = {
        responses: value,
      };
      if (reason) {
        body.remark = reason;
      }
      // if (draft) {
      //   body.draft = true;
      //   body.remark = body.remark || "draft";
      // }
      const res = await http.post(
        `/questionnaires/daily/submit-response-admin/${selected?.id}?userId=${id}`,
        body
      );
      toastMessage("success", res.data.message);
      setPhaseToggle((prev) => !prev);
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  const revertChanges = () => {
    setSelected((prev: any) => {
      return { ...prev };
    });
  };

  const submitHandler = async (draft?: boolean) => {
    try {
      dispatch(setAppLoader(true));
      const { value, error, newAnswers } = submitEpro(answers, questions);
      if (error && !draft) {
        setAnswers(newAnswers);
        toastMessage("warning", "Please enter values in all necessary fields");
        dispatch(setAppLoader(false));
      } else {
        if (value.length < 1) {
          toastMessage("warning", "Please submit a response");
          dispatch(setAppLoader(false));
          return;
        }
        if (selected?.completedAt) {
          dispatch(setAppLoader(false));
          dispatch(
            setModalDetails({
              type: "GCP_FORM_SUBMISSION",
              modalProps: {
                show: true,
                isDraft: draft,
                value: value,
                successCallback: submitRequest,
                failureCallback: revertChanges,
              },
            })
          );
        } else {
          submitRequest(value, undefined, draft);
        }
      }
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setAppLoader(false));
    }
  };

  const showLog = async (qid: string) => {
    dispatch(
      setModalDetails({
        type: "VIEW_EPRO_LOG",
        modalProps: {
          show: true,
          qid: qid,
          id: id,
        },
      })
    );
  };

  const showFiles = async (qid: string) => {
    dispatch(
      setModalDetails({
        type: "VIEW_EPRO_FILES",
        modalProps: {
          show: true,
          qid: qid,
          id: id,
        },
      })
    );
  };

  const handleReconcile = async () => {
    const callback = async () => {
      try {
        dispatch(setAppLoader(true));
        const res = await http.post(
          `/questionnaires/daily/verify/${selected.id}`
        );
        toastMessage("success", res.data.message);
        setPhaseToggle((prev) => !prev);
        dispatch(setAppLoader(false));
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    };
    confirm({
      title: "Data verify",
      content:
        "Are you sure you'd like to verify this data? This action can't be undone.",
      onOk() {
        callback();
      },
      onCancel() {},
      okText: "Yes",
      cancelText: "No",
    });
  };

  const questionMenu = (question: any) => (
    <Menu>
      <Menu.Item key="2" onClick={() => showFiles(question.id)}>
        View Documents
      </Menu.Item>
      <Menu.Item key="1" onClick={() => showLog(question.id)}>
        View Submission log
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="crf-builder">
      <div className="content-header">
        <ChevronLeftIcon
          style={{ height: "36px", width: "36px" }}
          className="me-5 text-secondary cp"
          onClick={handleBack}
        />
        <div className="font-m fw-semibold font-l me-5 text-secondary">
          {patientId || "Patient Id"}
        </div>
        <span style={{ marginLeft: "auto" }}></span>
        {formDirty && !locked && selected?.editable && (
          <span
            className="fw-semibold font-m me-3"
            style={{ color: "#f05252" }}
          >
            Please submit your changes
          </span>
        )}
      </div>
      <div className="crf-container">
        {data.length > 0 ? (
          <>
            <div className="crf-sider">
              {data.map((item: any) => {
                const selection = () => {
                  setSelectedPhase((prev) =>
                    prev === item.id ? null : item.id
                  );
                };
                return (
                  <div
                    className="phase-container"
                    key={item.id}
                    onClick={selection}
                  >
                    <div className="block">
                      <div className="color-primary font-m fw-semibold mb-2">
                        {item?.date}
                      </div>
                    </div>
                    {selectedPhase === item.id &&
                      item?.entries?.map((visit: any) => {
                        const isSelected = visit.id === selected?.id;
                        return (
                          <div
                            key={visit.id}
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelected(visit);
                            }}
                            className={
                              isSelected
                                ? "selected-block block ques-block"
                                : "block ques-block"
                            }
                          >
                            <div>
                              <div className="form-name">{visit?.title}</div>
                              <div
                                className={
                                  visit.completedAt
                                    ? "form-progress completed"
                                    : "form-progress"
                                }
                              >
                                {visit.completedAt
                                  ? visit?.dataVerified
                                    ? "Data Verified"
                                    : "Completed"
                                  : "Not started"}
                              </div>
                            </div>
                            {visit.completedAt && (
                              <span onClick={(e) => e.stopPropagation()}>
                                <Dropdown
                                  overlay={questionMenu(visit)}
                                  trigger={["click"]}
                                >
                                  <DotsVerticalIcon
                                    className="hero-icon cp"
                                    style={{
                                      color: "#71717A",
                                      marginLeft: "auto",
                                    }}
                                  />
                                </Dropdown>
                              </span>
                            )}
                          </div>
                        );
                      })}
                  </div>
                );
              })}
            </div>
            {questions.length > 0 ? (
              <div className="crf-question-container">
                {questions.map((q, index) => {
                  if (q.type === "statement") {
                    return (
                      <div className="crf-statement d-flex" key={q.id}>
                        <div className="text-prime font-sm fw-medium">
                          {q.title}
                        </div>
                        {q.attachment && (
                          <span
                            className="ms-3 color-primary cp"
                            onClick={() => viewImage(q.attachment)}
                          >
                            View Image
                          </span>
                        )}
                      </div>
                    );
                  } else {
                    return (
                      <QuestionBlock
                        index={index}
                        question={q}
                        key={q.id}
                        answers={answers}
                        saveAnswer={saveAnswer}
                      />
                    );
                  }
                })}
                <div className="d-flex align-items-center mt-5">
                  {selected?.editable && !locked && (
                    <>
                      <Button
                        type="primary"
                        style={{ height: "40px", borderRadius: "12px" }}
                        className="me-4"
                        onClick={() => submitHandler()}
                      >
                        <span className="fw-semibold font-sm">Submit</span>
                      </Button>
                      {/* {!selected?.completedAt && (
                      <Button
                        type="primary"
                        style={{ height: "40px", borderRadius: "12px" }}
                        className="me-4"
                        onClick={() => submitHandler(true)}
                      >
                        <span className="fw-semibold font-sm">
                          Save as Draft
                        </span>
                      </Button>
                    )} */}
                      <Button
                        style={{ height: "40px", borderRadius: "12px" }}
                        className="me-4"
                        onClick={handleBack}
                      >
                        <span className="fw-semibold font-sm">Cancel</span>
                      </Button>
                    </>
                  )}
                  {selected?.dataVerify && (
                    <Button
                      type="primary"
                      style={{ height: "40px", borderRadius: "12px" }}
                      className="me-4"
                      onClick={handleReconcile}
                    >
                      <span className="fw-semibold font-sm">Verify Data</span>
                    </Button>
                  )}
                </div>
              </div>
            ) : (
              <div className="no-crf-message font-ml fw-semibold text-prime">
                Please select a ePro to proceed
              </div>
            )}
          </>
        ) : (
          <div className="no-crf-message font-ml fw-semibold text-prime">
            No Epros found
          </div>
        )}
      </div>
    </div>
  );
};

export default EPro;
