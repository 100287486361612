import {
  // Badge,
  Dropdown,
  Menu,
  Progress,
  Table,
  Modal,
  // Tooltip
} from "antd";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import {
  setParticipantsPage,
  setParticipantsSort,
  setSelectedParticipants,
  toggleParticipantLoader,
} from "../../../redux/reducers/participantSlice";
import {
  CustomPaginationWithPages,
  PaginationText,
} from "../../Common/TableUtils";
import Column from "antd/lib/table/Column";
import { LockIcon, UnlockIcon } from "../../Common/Icons";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import {
  // canAmendAppointments,
  canViewCRFs,
  canLockParticipant,
  hasBlindingAccess,
} from "../../../helpers/roles";
import { fetchScreenedList } from "../../../redux/actions/adminAction";
import { errorToastMessage, toastMessage } from "../../../helpers/toastMessage";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
// import { setModalDetails } from "../../../redux/reducers/modalSlice";
import http from "../../../http";
import { SortOrder } from "antd/lib/table/interface";
// import { InfoCircleOutlined } from "@ant-design/icons";
const confirm = Modal.confirm;

const ScreenedList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    searchTerm,
    screenedParticipantCount,
    screenedParticipantUsers,
    toggleLoader,
    selectedParticipants,
    // proHeaders,
    crfHeaders,
    currentPage,
    sort,
    selectedTab,
  } = useAppSelector((state) => state.participant);

  const role = useAppSelector((state) => state.user.role);
  // const [proExpanded, setProExpanded] = useState(false);
  const [crfExpanded, setCrfExpanded] = useState(false);
  const [hasCRFAccess] = useState(canViewCRFs(role));
  const [hasLockAccess] = useState(canLockParticipant(role));
  const [hasViewAccess] = useState(hasBlindingAccess(role));
  // const [exportAccess] = useState(canExportData(role));

  useEffect(() => {
    if (selectedTab === "screened") {
      dispatch(fetchScreenedList(searchTerm, currentPage, sort));
    }
  }, [searchTerm, currentPage, sort, dispatch, toggleLoader, selectedTab]);

  const onTableChange = async (pagination: any, filters: any, sorters: any) => {
    if (pagination.current) {
      dispatch(setParticipantsPage(pagination.current));
    }

    // if (filters.randomizationGroup) {
    //   dispatch(setParticipantsFilter(filters.randomizationGroup[0]));
    // } else if (filters.status) {
    //   dispatch(setParticipantsFilter(filters.status[0]));
    // } else {
    //   dispatch(setParticipantsFilter(""));
    // }
    let sort = null;
    if (sorters.order && sorters.columnKey === "screenId") {
      sort = {
        orderBy: sorters["order"] === "ascend" ? "asc" : "desc",
        sortBy: "screenId",
      };
      dispatch(setParticipantsSort({ sort }));
    } else if (sorters.order && sorters.columnKey === "subjectId") {
      sort = {
        orderBy: sorters["order"] === "ascend" ? "asc" : "desc",
        sortBy: "subjectId",
      };
      dispatch(setParticipantsSort({ sort }));
    } else {
      sort = {
        orderBy: "asc",
        sortBy: "screenId",
      };
      dispatch(setParticipantsSort({ sort }));
    }
  };

  const crfNavigate = (patient: any, screeningOnly?: boolean) => {
    if (!hasCRFAccess) {
      return;
    }
    const crfType = screeningOnly ? "screening" : "study";
    const patientId = patient.subjectId || "Patient";
    navigate(`/crf/${crfType}/${patient.id}?patientId=${patientId}`);
  };

  const toggleCrfExpanded = () => {
    setCrfExpanded((prev) => !prev);
  };

  // const toggleProExpanded = () => {
  //   setProExpanded((prev) => !prev);
  // };

  const rowSelection = (selectedRowKeys: any[]) => {
    dispatch(setSelectedParticipants(selectedRowKeys));
  };

  // const viewAppointment = (data: any) => {
  //   window.open(
  //     `/appointments?name=${data.subjectId}&inviteeId=${data.id}`,
  //     "_blank"
  //   );
  // };

  const viewDailyDairy = (patient: any) => {
    const patientId = patient.subjectId || "Patient";
    navigate(`/ePro/${patient.id}?patientId=${patientId}`);
  };

  // const scheduleAppointment = (data: any) => {
  //   dispatch(
  //     setModalDetails({
  //       type: "SCHEDULE_APPOINTMENT",
  //       modalProps: {
  //         show: true,
  //         invitees: [data.id],
  //       },
  //     })
  //   );
  // };

  const lockToggle = (data: any) => {
    const callback = async () => {
      try {
        if (hasLockAccess) {
          dispatch(setAppLoader(true));
          const res = await http.post(
            `/participants/${data.id}/${data.isLocked ? "unlock" : "lock"}`
          );
          toastMessage("success", res.data.message);
          dispatch(toggleParticipantLoader());
        }
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    };

    confirm({
      title: data.isLocked ? "Unlock Participant" : "Lock Participant",
      content: `Are you sure you want to ${
        data.isLocked ? "Unlock the Participant?" : "Lock the Participant?"
      }`,
      onOk() {
        callback();
      },
      onCancel() {},
      okText: "Yes",
      cancelText: "No",
    });
  };

  const resetPassword = async (data: any) => {
    try {
      if (hasViewAccess) {
        dispatch(setAppLoader(true));
        const res = await http.post(`/users/${data.id}/reset-password`);
        toastMessage("success", res.data.message);
        dispatch(toggleParticipantLoader());
      }
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  const markComplete = (data: any) => {
    const callback = async () => {
      try {
        if (hasLockAccess) {
          dispatch(setAppLoader(true));
          const res = await http.post(`/participants/${data.id}/mark-complete`);
          toastMessage("success", res.data.message);
          dispatch(toggleParticipantLoader());
        }
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    };

    confirm({
      title: "Mark Participant as Complete",
      content: `Are you sure you want to mark the Participant Complete?`,
      onOk() {
        callback();
      },
      onCancel() {},
      okText: "Yes",
      cancelText: "No",
    });
  };

  // const deregisterDevice = async (data: any) => {
  //   try {
  //     if (hasAdminAccess) {
  //       dispatch(setAppLoader(true));
  //       const res = await http.post(`/participants/${data.id}/deregister`);
  //       toastMessage("success", res.data.message);
  //       dispatch(toggleParticipantLoader());
  //     }
  //   } catch (err) {
  //     dispatch(setAppLoader(false));
  //     errorToastMessage(err as Error);
  //   }
  // };

  const menu = (data: any) => (
    <Menu>
      {/* {exportAccess && (
        <Menu.Item key="export" onClick={() => exportCRF(data.id)}>
          Export
        </Menu.Item>
      )}
      {hasAdminAccess && (
        <Menu.Item key="study-remove" onClick={() => removeFromStudy(data)}>
          Set Study Status
        </Menu.Item>
      )}
      {hasAdminAccess && (
        <Menu.Item key="nickname" onClick={() => setNickname(data)}>
          Set Participant details
        </Menu.Item>
      )} */}
      {/* {apptAccess && (
        <Menu.Item key="appointment" onClick={() => scheduleAppointment(data)}>
          Schedule an appointment
        </Menu.Item>
      )} */}
      {/* <Menu.Item key="docs" onClick={() => viewDocument(data.id)}>
        View Documents
      </Menu.Item>
      <Menu.Item key="view food diary" onClick={() => viewDiary(data)}>
        View Food Diary
      </Menu.Item>
      <Menu.Item
        key="view exercise diary"
        onClick={() => viewDiary(data, true)}
      >
        View Exercise Diary
      </Menu.Item> */}
      {/* <Menu.Item key="view appointments" onClick={() => viewAppointment(data)}>
        View Appointments
      </Menu.Item> */}
      {/* <Menu.Item key="chat" onClick={() => chat(data)}>
        Chat
      </Menu.Item> */}
      <Menu.Item key="view daily-dairy" onClick={() => viewDailyDairy(data)}>
        View Daily Dairy
      </Menu.Item>
      {!data.isComplete && hasLockAccess && (
        <Menu.Item key="complete" onClick={() => markComplete(data)}>
          Mark User as Complete
        </Menu.Item>
      )}
      {hasViewAccess && (
        <Menu.Item key="reset-password" onClick={() => resetPassword(data)}>
          Reset Password
        </Menu.Item>
      )}
      {/* {hasAdminAccess && data.isDeviceRegistered && (
        <Menu.Item key="deregister" onClick={() => deregisterDevice(data)}>
          De-Register Device
        </Menu.Item>
      )} */}
      {/* {subGroupAccess &&
        data?.randomizationGroup === "SLI" &&
        !data.subgroup && (
          <Menu.Item key="assign" onClick={() => assignGroup(data)}>
            Assign Group
          </Menu.Item>
        )} */}
      {/* <Menu.Item key="login-code" onClick={() => viewLoginCode(data)}>
        View Login code
      </Menu.Item> */}
    </Menu>
  );

  return (
    <div className="modules-wrapper">
      <Table
        dataSource={screenedParticipantUsers}
        rowSelection={{
          selectedRowKeys: selectedParticipants,
          onChange: rowSelection,
          preserveSelectedRowKeys: true,
        }}
        showSorterTooltip={false}
        className="singer-custom-table participant-table"
        onChange={onTableChange}
        rowKey={(record) => record.id}
        pagination={{
          className: "singer-table-pagination-with-pages",
          itemRender: CustomPaginationWithPages,
          current: currentPage,
          total: screenedParticipantCount,
          showSizeChanger: false,
          pageSize: 8,
          hideOnSinglePage: true,
          showTotal: PaginationText,
          position: ["bottomLeft"],
          showQuickJumper: true,
        }}
        scroll={{ x: "max-content" }}
      >
        <Column
          title="Subject Id"
          dataIndex="subjectId"
          key="subjectId"
          width={200}
          align="center"
          fixed="left"
          // sorter={true}
          // sortOrder={
          //   sort?.sortBy === "subjectId"
          //     ? sort.orderBy === "asc"
          //       ? ("ascend" as SortOrder)
          //       : ("descend" as SortOrder)
          //     : undefined
          // }
          render={(_1, record: any) => {
            // let errorText = "";
            // if (record.error === 0) {
            //   errorText = "Recruitment Error";
            // } else if (record.error === 1) {
            //   errorText = "Dropout";
            // } else if (record.error === 2) {
            //   errorText = "Non-Compliant";
            // }
            // errorText += record.errorReason ? " - " + record.errorReason : "";
            return (
              <span>
                <span
                  onClick={() => crfNavigate(record)}
                  className="color-primary cp"
                >
                  {record.subjectId}
                </span>
                {/* {errorText && (
                  <Tooltip title={errorText}>
                    <span className="ms-2 cp">
                      <Badge
                        className="mb-1"
                        count={
                          <InfoCircleOutlined
                            style={{
                              color: record.error === 2 ? "#E3A008" : "#f5222d",
                            }}
                          />
                        }
                      />
                    </span>
                  </Tooltip>
                )} */}
              </span>
            );
          }}
        />
        <Column
          title="Screening Id"
          dataIndex="screenId"
          key="screenId"
          width={200}
          align="center"
          sorter={true}
          sortOrder={
            sort?.sortBy === "screenId"
              ? sort.orderBy === "asc"
                ? ("ascend" as SortOrder)
                : ("descend" as SortOrder)
              : undefined
          }
          render={(_1, record: any) => (
            <div
              onClick={() => crfNavigate(record, true)}
              className="color-primary cp"
            >
              {record.screenId}
            </div>
          )}
        />
        {hasViewAccess && (
          <Column
            title="Email"
            dataIndex="email"
            key="email"
            width={200}
            align="center"
            render={(text: string) => <div>{text ? text : "-"}</div>}
          />
        )}

        {/* <Column
          title="Nickname"
          dataIndex="nickname"
          key="nickname"
          width={200}
          align="center"
          render={(text: string) => <div>{text ? text : "-"}</div>}
        /> */}

        <Column
          title="Created Date"
          dataIndex="createdAt"
          key="createdAt"
          align="center"
          width={200}
        />
        <Column
          title="Status"
          dataIndex="isComplete"
          key="complete"
          align="center"
          width={200}
          render={(text: string) => (
            <div className="d-flex justify-content-center">
              <span className={text ? "active-tag badge" : "neutral-tag badge"}>
                {text ? "Complete" : "Inprogress"}
              </span>
            </div>
          )}
        />
        <Column
          title={<LockIcon />}
          dataIndex="isLocked"
          key="isLocked"
          width={100}
          render={(isLocked, record: any) => {
            return isLocked ? (
              <span
                className={hasLockAccess ? "cp" : ""}
                onClick={() => {
                  if (hasLockAccess) lockToggle(record);
                }}
              >
                <LockIcon />
              </span>
            ) : (
              <span
                className={hasLockAccess ? "cp" : ""}
                onClick={() => {
                  if (hasLockAccess) lockToggle(record);
                }}
              >
                <UnlockIcon />
              </span>
            );
          }}
          align="center"
        />
        {hasCRFAccess && (
          <Column
            title=""
            key="action"
            width="10px"
            render={(_1, record: any) => {
              return (
                <span onClick={(e) => e.stopPropagation()}>
                  <Dropdown
                    overlay={menu(record)}
                    placement="bottomRight"
                    trigger={["click"]}
                  >
                    <DotsVerticalIcon
                      className="hero-icon cp"
                      style={{ color: "#71717A" }}
                    />
                  </Dropdown>
                </span>
              );
            }}
          />
        )}
        <>
          {/* <Column
            title={() => {
              return (
                <span>
                  E-pro Completion Rate
                  <span
                    className="color-primary ms-3 cp"
                    onClick={toggleProExpanded}
                  >
                    {proExpanded
                      ? "(Click to collapse)"
                      : "(Click to expand more)"}
                  </span>
                </span>
              );
            }}
            key="eproCompletion"
            dataIndex="eproCompletion"
            width={350}
            render={(percent: number) => {
              return <Progress percent={percent} size="small" />;
            }}
          />
          {proExpanded &&
            proHeaders.map((header) => {
              return (
                <Column
                  title={header.title}
                  key={header.id}
                  dataIndex={header.id}
                  width={350}
                  render={(percent: number) => {
                    return <Progress percent={percent} size="small" />;
                  }}
                />
              );
            })} */}
          <Column
            title={() => {
              return (
                <span>
                  E-Crf Completion Rate
                  <span
                    className="color-primary ms-3 cp"
                    onClick={toggleCrfExpanded}
                  >
                    {crfExpanded
                      ? "(Click to collapse)"
                      : "(Click to expand more)"}
                  </span>
                </span>
              );
            }}
            key="crfCompletion"
            dataIndex="crfCompletion"
            width={350}
            render={(percent: number) => {
              return <Progress percent={percent} size="small" />;
            }}
          />
          {crfExpanded &&
            crfHeaders.map((header) => {
              return (
                <Column
                  title={header.title}
                  key={header.id}
                  dataIndex={header.id}
                  width={350}
                  render={(percent: number, record: any) => {
                    return (
                      <span
                        className="cp"
                        onClick={() => {
                          sessionStorage.setItem("crf-phase", header.pid);
                          sessionStorage.setItem("crf-question", header.qid);
                          crfNavigate(record, header.isScreening);
                        }}
                      >
                        <Progress percent={percent} size="small" />
                      </span>
                    );
                  }}
                />
              );
            })}
        </>
      </Table>
    </div>
  );
};

export default ScreenedList;
