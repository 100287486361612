import React from "react";
import { Button, DatePicker, Input, Modal } from "antd";
import { Formik } from "formik";
import * as yup from "yup";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { hideModal } from "../../../redux/reducers/modalSlice";
import { errorToastMessage, toastMessage } from "../../../helpers/toastMessage";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import { AxiosResponse } from "axios";
import http from "../../../http";
import moment from "moment";
import { getStudyId } from "../../../helpers/study";

const schema = yup.object().shape({
  studyName: yup.string().required("Study Name is Required"),
  description: yup.string().required("Description is Required"),
  protocolNumber: yup.string().required("Protocol Number is Required"),
  investigator: yup.string().required("Principal Investigator is Required"),
  studyId: yup.string().required("Study Id is Required"),
  siteId: yup.string().required("Site Id is Required"),
  startDate: yup.string().required("Start Date is Required"),
  endDate: yup.string().required("End Date is Required"),
});

const EditSettingsModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { show, callback, data } = useAppSelector(
    (state) => state.modal.modalProps
  );
  const closeHandler = () => {
    dispatch(hideModal());
  };

  const submitHandler = async (values: any) => {
    try {
      dispatch(setAppLoader(true));
      const studyId = getStudyId();
      const res: AxiosResponse = await http.put(`/programs/${studyId}`, {
        title: values.studyName,
        description: values.description,
        startDate: values.startDate,
        endDate: values.endDate,
        protocolNumber: values.protocolNumber,
        principalnvestigator: values.investigator,
        studyId: values.studyId,
        siteId: values.siteId,
      });
      toastMessage("success", res.data.message);
      callback();
      dispatch(setAppLoader(false));
      dispatch(hideModal());
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setAppLoader(false));
    }
  };

  return (
    <Modal
      title={`Edit Settings`}
      visible={show}
      onCancel={closeHandler}
      closable={true}
      width={"38rem"}
      footer={null}
    >
      <div className="add-project-owner">
        <Formik
          initialValues={{
            studyName: data?.studyName || "",
            description: data?.description || "",
            startDate: data?.startDate || null,
            endDate: data?.endDate || null,
            protocolNumber: data?.protocolNumber || "",
            investigator: data?.investigator || "",
            studyId: data?.studyId || "",
            siteId: data?.siteId || "",
          }}
          validationSchema={schema}
          enableReinitialize={true}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit} className="m-0">
              <div className="row">
                <div className="col-md-12">
                  <div className="text-secondary font-m fw-medium mb-1">
                    Study Name
                  </div>
                  <Input
                    name="studyName"
                    value={values.studyName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="singer-input"
                  />
                  <div className="input-error text-left mt-1">
                    {errors.studyName && touched.studyName && errors.studyName}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="text-secondary font-m fw-medium mb-1">
                    Description
                  </div>
                  <Input
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="singer-input"
                  />
                  <div className="input-error text-left mt-1">
                    {errors.description &&
                      touched.description &&
                      errors.description}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="text-secondary font-m fw-medium mb-1">
                    Start Date
                  </div>
                  <DatePicker
                    className="date-selector"
                    format="DD/MM/YYYY"
                    value={
                      values.startDate
                        ? moment(values.startDate, "DD/MM/YYYY")
                        : null
                    }
                    style={{
                      borderRadius: "16px",
                      height: "40px",
                    }}
                    onChange={(date) => {
                      setFieldValue("startDate", date);
                    }}
                    inputReadOnly
                  />
                  <div className="input-error text-left mt-1">
                    {errors.startDate && touched.startDate && errors.startDate}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="text-secondary font-m fw-medium mb-1">
                    End Date
                  </div>
                  <DatePicker
                    className="date-selector"
                    format="DD/MM/YYYY"
                    value={
                      values.endDate
                        ? moment(values.endDate, "DD/MM/YYYY")
                        : null
                    }
                    style={{
                      borderRadius: "16px",
                      height: "40px",
                    }}
                    onChange={(date) => {
                      setFieldValue("endDate", date);
                    }}
                    inputReadOnly
                  />
                  <div className="input-error text-left mt-1">
                    {errors.endDate && touched.endDate && errors.endDate}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="text-secondary font-m fw-medium mb-1">
                    Protocol Number
                  </div>
                  <Input
                    name="protocolNumber"
                    value={values.protocolNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="singer-input"
                  />
                  <div className="input-error text-left mt-1">
                    {errors.protocolNumber &&
                      touched.protocolNumber &&
                      errors.protocolNumber}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="text-secondary font-m fw-medium mb-1">
                    Principal Investigator
                  </div>
                  <Input
                    name="investigator"
                    value={values.investigator}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="singer-input"
                  />
                  <div className="input-error text-left mt-1">
                    {errors.investigator &&
                      touched.investigator &&
                      errors.investigator}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="text-secondary font-m fw-medium mb-1">
                    Study Id
                  </div>
                  <Input
                    name="studyId"
                    value={values.studyId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="singer-input"
                  />
                  <div className="input-error text-left mt-1">
                    {errors.studyId && touched.studyId && errors.studyId}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="text-secondary font-m fw-medium mb-1">
                    Site Id
                  </div>
                  <Input
                    name="siteId"
                    value={values.siteId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="singer-input"
                  />
                  <div className="input-error text-left mt-1">
                    {errors.siteId && touched.siteId && errors.siteId}
                  </div>
                </div>
              </div>
              <div>
                <Button
                  htmlType="submit"
                  style={{ height: "40px", borderRadius: "12px" }}
                  className="me-4"
                  type="primary"
                >
                  <span className="fw-semibold font-sm">Save</span>
                </Button>
                <Button
                  onClick={closeHandler}
                  style={{ height: "40px", borderRadius: "12px" }}
                  htmlType="button"
                >
                  <span className="fw-semibold font-sm">Cancel</span>
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default EditSettingsModal;
