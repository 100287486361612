import { DotsVerticalIcon } from "@heroicons/react/solid";
import { Dropdown, Menu, Table, Modal, Button } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { errorToastMessage } from "../../helpers/toastMessage";
import http from "../../http";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setAppLoader } from "../../redux/reducers/loaderSlice";
import { setModalDetails } from "../../redux/reducers/modalSlice";
import { canEditMedication } from "../../helpers/roles";

const { confirm } = Modal;

export const frequencyMap: any = {
  1: "Daily",
  2: "Multiple Times/Week",
  3: "Once/Week",
  4: "Rarely",
  5: "No longer taking",
  6: "PRN",
};

const CRFMedication: React.FC<any> = (props) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [data, setData] = useState<any[]>([]);
  const [toggle, setToggle] = useState(false);

  const role = useAppSelector((state) => state.user.role);
  const [hasEditAccess] = useState(canEditMedication(role));

  useEffect(() => {
    const fetchMedications = async (id: string) => {
      try {
        dispatch(setAppLoader(true));
        const res = await http.get(`mh/user_medications?userId=${id}`);
        const meds = res.data.data.map((med: any) => {
          return {
            id: med.id,
            name: med.name,
            frequency: med.frequency,
            startDate: med.startDate
              ? moment(med.startDate).format("DD/MM/YYYY")
              : null,
            endDate: med.endDate
              ? moment(med.endDate).format("DD/MM/YYYY")
              : null,
            indication: med.indication,
            draft: med.draft,
            dose: med.dose ? parseFloat(med.dose || 0) : null,
            unit: med.unit,
            dosageForm: med.dosageForm,
            remarks: med.remarks,
            type: med.type,
            category: med.category,
            route: med.route,
            studyContinuation: med.continueAfterStudy || false,
            isBaseline: med.isBaseline || false,
            versions: med?.versions?.map((version: any) => {
              return {
                id: version.id,
                updatedDate: moment(version.createdAt).format(
                  "DD/MM/YYYY hh:mm A"
                ),
                user: version?.who
                  ? version?.who?.firstName + " " + version?.who?.lastName
                  : "-",
                newValue: version?.newValues,
                prevValue: version?.prevValues,
              };
            }),
          };
        });
        setData(meds);
        dispatch(setAppLoader(false));
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    };
    if (id) {
      fetchMedications(id);
    }
  }, [id, toggle, dispatch, setData]);

  const toggler = () => {
    setToggle((prev) => !prev);
  };

  const addMedication = () => {
    dispatch(
      setModalDetails({
        type: "ADD_MEDICATION",
        modalProps: {
          show: true,
          userId: id,
          callback: toggler,
        },
      })
    );
  };

  const editMedication = (data: any) => {
    const { versions, ...rest } = data;
    dispatch(
      setModalDetails({
        type: "ADD_MEDICATION",
        modalProps: {
          show: true,
          medication: rest,
          userId: id,
          callback: toggler,
        },
      })
    );
  };

  const deleteHandler = async (id: string) => {
    try {
      dispatch(setAppLoader(true));
      await http.delete(`/mh/user_medications/${id}`);
      toggler();
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  const deleteMedication = (id: string) => {
    confirm({
      title: "Are you sure?",
      content: "This medication will no longer be available.",
      onOk() {
        deleteHandler(id);
      },
      onCancel() {},
      okText: "Delete",
      cancelText: "Cancel",
    });
  };

  const viewLogs = (data: any) => {
    dispatch(
      setModalDetails({
        type: `MEDICATION_SUBMISSION_LOG`,
        modalProps: {
          show: true,
          versions: data?.versions,
        },
      })
    );
  };

  const menu = (data: any) => (
    <Menu>
      {hasEditAccess &&
        !props.locked && [
          <Menu.Item key="0" onClick={() => editMedication(data)}>
            Edit
          </Menu.Item>,
          <Menu.Item key="2" onClick={() => deleteMedication(data.id)}>
            Delete
          </Menu.Item>,
        ]}
      <Menu.Item key="1" onClick={() => viewLogs(data)}>
        View logs
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "Medication/Therapy Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type: string) => {
        return type || "-";
      },
    },
    {
      title: "Indication",
      dataIndex: "indication",
      key: "indication",
      render: (indication: string) => {
        return indication || "-";
      },
    },
    {
      title: "Dose",
      dataIndex: "dose",
      key: "dose",
      render: (text: string) => {
        return text || "-";
      },
    },
    {
      title: "Dose Units",
      dataIndex: "unit",
      key: "unit",
      render: (text: string) => {
        return text || "-";
      },
    },
    {
      title: "Dose Form",
      dataIndex: "dosageForm",
      key: "dosageForm",
      render: (text: string) => {
        return text || "-";
      },
    },
    {
      title: "Frequency",
      dataIndex: "frequency",
      key: "frequency",
      render: (frequency: number) => {
        return frequency || "-";
      },
    },
    {
      title: "Route Of Administration",
      dataIndex: "route",
      key: "route",
      render: (route: number) => {
        return route || "-";
      },
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (date: string) => {
        return date || "-";
      },
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (date: string) => {
        return date || "-";
      },
    },
    {
      title: "Baseline Medication/Therapy",
      dataIndex: "isBaseline",
      key: "isBaseline",
      render: (isBaseline: boolean) => {
        return isBaseline ? "Yes" : "No";
      },
    },
    {
      title: "Continuing After Study",
      dataIndex: "studyContinuation",
      key: "studyContinuation",
      render: (studyContinuation: boolean) => {
        return studyContinuation ? "Yes" : "No";
      },
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      render: (text: string) => {
        return text || "-";
      },
    },
    {
      title: "",
      width: "20px",
      render: (_1: any, record: any) => {
        return (
          <span onClick={(e) => e.stopPropagation()}>
            <Dropdown
              overlay={menu(record)}
              placement="bottomRight"
              trigger={["click"]}
            >
              <DotsVerticalIcon
                className="hero-icon cp"
                style={{ color: "#71717A", marginLeft: "auto" }}
              />
            </Dropdown>
          </span>
        );
      },
    },
  ];

  return (
    <div className="crf-question-container">
      <div className="fw-semibold font-ml color-primary crf-header">
        <span>Concomitant Medication</span>
        {!props.locked && hasEditAccess && (
          <Button
            type="primary"
            style={{ height: "40px", borderRadius: "12px" }}
            className="me-4"
            onClick={addMedication}
          >
            <span className="fw-semibold font-sm">Add Medication</span>
          </Button>
        )}
      </div>
      <Table
        dataSource={data}
        columns={columns}
        className="singer-custom-table"
        rowKey={(record) => record.id}
        pagination={false}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default CRFMedication;
