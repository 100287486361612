import { Formik } from "formik";
import { toastMessage, errorToastMessage } from "../../../helpers/toastMessage";
import { Modal, Input, Button, Select, DatePicker, Checkbox } from "antd";
import { hideModal } from "../../../redux/reducers/modalSlice";
import http from "../../../http";
import { useAppDispatch } from "../../../redux/hooks";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";

let yup = require("yup");

let schema = yup.object().shape({
  date: yup.string().when("draft", {
    is: false,
    then: (schema: any) =>
      schema.required("Date is Required").typeError("Date is Required"),
    otherwise: (schema: any) => schema.nullable(),
  }),
  reason: yup.string().when("id", {
    is: (id: string) => !!id,
    then: (schema: any) => schema.required("Reason is Required"),
  }),
  comment: yup.string().required("Description is Required"),
  id: yup.string(),
  draft: yup.bool(),
  severity: yup.string().optional(),
  isOngoing: yup.string().optional(),
  outcome: yup.string().optional(),
  relationship: yup.string().optional(),
  actionTaken: yup.string().optional(),
  aeTreatment: yup.string().optional(),
  accessorInitials: yup.string().optional(),
  accessorInitialsDate: yup.string().optional().nullable(),
  piInitials: yup.string().optional(),
  piInitialsDate: yup.string().optional().nullable(),
  startDate: yup.string().optional().nullable(),
  endDate: yup.string().optional().nullable(),
  serious: yup.string().optional(),
});

type Props = {
  show: boolean;
  incident: any;
  userId: string;
  callback: Function;
};

const severityTypes = [
  { label: "Mild (Grade 1)", value: "mild" },
  { label: "Moderate (Grade 2)", value: "moderate" },
  { label: "Severe (Grade 3)", value: "severe" },
  {
    label: "Potential Life Threatening (Grade 4)",
    value: "potential_life_threatening",
  },
  { label: "Death (Fatal)", value: "death" },
];

const outcomeTypes = [
  "Fatal",
  "Not recovered / Not resolved",
  "Recovered w/o sequelae",
  "Recovered w/ sequelae",
  "Recovering / Resolving",
  "Unknown",
];

const relationTypes = [
  "Definitely Related",
  "Probably Related",
  "Possibly Related",
  "Not Related",
];

const actionTakenTypes = [
  "Dose Increased",
  "Dose not changed",
  "Dose reduced",
  "Dose interrupted",
  "Drug withdrawn",
  "Not applicable",
  "Unknown",
];

const AETreatmentTypes = [
  "None",
  "Medication (report on CM log)",
  "Non-medication Treatment (report on CM Log)",
];

const AddIncidentModal = (props: Props) => {
  const dispatch = useAppDispatch();

  const submitHandler = async (values: any) => {
    try {
      dispatch(setAppLoader(true));
      const date = values.date
        ? moment(values.date, "DD/MM/YYYY").toISOString()
        : null;
      const endDate = values.endDate
        ? moment(values.endDate, "DD/MM/YYYY").toISOString()
        : null;
      const startDate = values.startDate
        ? moment(values.startDate, "DD/MM/YYYY").toISOString()
        : null;
      const accessorInitialsDate = values.accessorInitialsDate
        ? moment(values.accessorInitialsDate, "DD/MM/YYYY").toISOString()
        : null;
      const piInitialsDate = values.piInitialsDate
        ? moment(values.piInitialsDate, "DD/MM/YYYY").toISOString()
        : null;
      const body: any = {
        reportedOn: date,
        comment: values.comment || "",
        reason: values.reason || undefined,
        draft: values.draft,
        severity: values.severity,
        participantId: props.userId,
        outcome: values.outcome || "",
        relationship: values.relationship || "",
        actionTaken: values.actionTaken || "",
        aeTreatment: values.aeTreatment || "",
        accessorInitials: values.accessorInitials || "",
        accessorInitialsDate: accessorInitialsDate,
        piInitials: values.piInitials || "",
        piInitialsDate: piInitialsDate,
        startDate: startDate,
        endDate: endDate,
        serious: values.serious === "yes" ? true : false || false,
        isOngoing: values.isOngoing === "yes" ? true : false || false,
      };
      let res;
      if (values.id) {
        res = await http.patch(`/mh/tracking/incident/${values.id}`, body);
      } else {
        res = await http.post("/mh/tracking/incident", body);
      }
      dispatch(hideModal());
      dispatch(setAppLoader(false));
      toastMessage("success", res.data.message);
      props.callback();
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  const closeHandler = () => {
    dispatch(hideModal());
  };

  return (
    <Modal
      title={`${props?.incident?.id ? "Edit" : "Add"} Event`}
      visible={props.show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <div className="add-question">
        <Formik
          initialValues={{
            id: props?.incident?.id || "",
            date: props?.incident?.reportedOn || null,
            comment: props?.incident?.comment || "",
            severity: props?.incident?.severity || "",
            draft: props?.incident?.draft || false,
            outcome: props?.incident?.outcome || "",
            relationship: props?.incident?.relationship || "",
            actionTaken: props?.incident?.actionTaken || "",
            aeTreatment: props?.incident?.aeTreatment || "",
            accessorInitials: props?.incident?.accessorInitials || "",
            accessorInitialsDate: props?.incident?.accessorInitialsDate || null,
            piInitials: props?.incident?.piInitials || "",
            piInitialsDate: props?.incident?.piInitialsDate || null,
            startDate: props?.incident?.startDate || null,
            endDate: props?.incident?.endDate || null,
            serious: props?.incident?.serious === true ? "yes" : "no",
            isOngoing: props?.incident?.isOngoing === true ? "yes" : "no",
            reason: "",
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleBlur,
            handleChange,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <div>
                <div>
                  <div>
                    <div className="text-secondary font-m fw-medium mb-1">
                      Date Reported
                    </div>
                    <DatePicker
                      className="date-selector"
                      format="DD/MM/YYYY"
                      placeholder="Select Date"
                      value={
                        values.date ? moment(values.date, "DD/MM/YYYY") : null
                      }
                      style={{
                        borderRadius: "16px",
                        height: "40px",
                      }}
                      onChange={(_1, dateString) => {
                        setFieldValue(`date`, dateString);
                      }}
                      inputReadOnly
                    />
                    <div className="input-error">
                      {touched?.date && errors?.date}
                    </div>
                  </div>
                  <div>
                    <div className="text-secondary font-m fw-medium mb-1">
                      Adverse Event Description
                    </div>
                    <TextArea
                      name="comment"
                      rows={3}
                      value={values.comment}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="singer-input"
                    />
                    <div className="input-error">
                      {touched?.comment && errors?.comment}
                    </div>
                  </div>
                  {values.id && (
                    <div>
                      <div className="text-secondary font-m fw-medium mb-1">
                        Reason for Edit
                      </div>
                      <Input
                        name="reason"
                        value={values.reason}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="singer-input"
                      />
                      <div className="input-error">
                        {touched?.reason && errors?.reason}
                      </div>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="text-secondary font-m fw-medium mb-1">
                      Start Date
                    </div>
                    <DatePicker
                      className="date-selector"
                      format="DD/MM/YYYY"
                      value={
                        values.startDate
                          ? moment(values.startDate, "DD/MM/YYYY")
                          : null
                      }
                      style={{
                        borderRadius: "16px",
                        height: "40px",
                      }}
                      onChange={(_1, dateString) => {
                        setFieldValue("startDate", dateString);
                      }}
                      inputReadOnly
                    />
                    <div className="input-error">
                      {errors.startDate &&
                        touched.startDate &&
                        errors.startDate}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-secondary font-m fw-medium mb-1">
                      End Date
                    </div>
                    <DatePicker
                      className="date-selector"
                      format="DD/MM/YYYY"
                      value={
                        values.endDate
                          ? moment(values.endDate, "DD/MM/YYYY")
                          : null
                      }
                      style={{
                        borderRadius: "16px",
                        height: "40px",
                      }}
                      onChange={(_1, dateString) => {
                        setFieldValue("endDate", dateString);
                      }}
                      inputReadOnly
                    />
                    <div className="input-error">
                      {errors.endDate && touched.endDate && errors.endDate}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="text-secondary font-m fw-medium mb-1">
                    Ongoing
                  </div>
                  <Select
                    className="custom-select-field"
                    size="large"
                    style={{ width: "100%" }}
                    value={values.isOngoing}
                    onChange={(val) => {
                      setFieldValue(`isOngoing`, val);
                    }}
                  >
                    <Select.Option key={"yes"} value={"yes"}>
                      Yes
                    </Select.Option>
                    <Select.Option key={"no"} value={"no"}>
                      No
                    </Select.Option>
                  </Select>
                  <div className="input-error">
                    {touched?.isOngoing && errors?.isOngoing}
                  </div>
                </div>
                <div>
                  <div className="text-secondary font-m fw-medium mb-1">
                    Severity / Grade
                  </div>
                  <Select
                    className="custom-select-field"
                    size="large"
                    style={{ width: "100%" }}
                    value={values.severity}
                    onChange={(val) => {
                      setFieldValue(`severity`, val);
                    }}
                  >
                    {severityTypes.map((type) => {
                      return (
                        <Select.Option
                          key={type.value}
                          value={type.value}
                          // style={{ textTransform: "capitalize" }}
                        >
                          {type.label}
                        </Select.Option>
                      );
                    })}
                  </Select>
                  <div className="input-error">
                    {touched?.severity && errors?.severity}
                  </div>
                </div>
                <div>
                  <div className="text-secondary font-m fw-medium mb-1">
                    Outcome
                  </div>
                  <Select
                    className="custom-select-field"
                    size="large"
                    style={{ width: "100%" }}
                    value={values.outcome}
                    onChange={(val) => {
                      setFieldValue(`outcome`, val);
                    }}
                  >
                    {outcomeTypes.map((type) => {
                      return (
                        <Select.Option key={type} value={type}>
                          {type}
                        </Select.Option>
                      );
                    })}
                  </Select>
                  <div className="input-error">
                    {touched?.outcome && errors?.outcome}
                  </div>
                </div>
                <div>
                  <div className="text-secondary font-m fw-medium mb-1">
                    Relationship to Study Treatment
                  </div>
                  <Select
                    className="custom-select-field"
                    size="large"
                    style={{ width: "100%" }}
                    value={values.relationship}
                    onChange={(val) => {
                      setFieldValue(`relationship`, val);
                    }}
                  >
                    {relationTypes.map((type) => {
                      return (
                        <Select.Option key={type} value={type}>
                          {type}
                        </Select.Option>
                      );
                    })}
                  </Select>
                  <div className="input-error">
                    {touched?.relationship && errors?.relationship}
                  </div>
                </div>
                <div>
                  <div className="text-secondary font-m fw-medium mb-1">
                    Action Taken with Study Treatment
                  </div>
                  <Select
                    className="custom-select-field"
                    size="large"
                    style={{ width: "100%" }}
                    value={values.actionTaken}
                    onChange={(val) => {
                      setFieldValue(`actionTaken`, val);
                    }}
                  >
                    {actionTakenTypes.map((type) => {
                      return (
                        <Select.Option key={type} value={type}>
                          {type}
                        </Select.Option>
                      );
                    })}
                  </Select>
                  <div className="input-error">
                    {touched?.actionTaken && errors?.actionTaken}
                  </div>
                </div>
                <div>
                  <div className="text-secondary font-m fw-medium mb-1">
                    Serious
                  </div>
                  <Select
                    className="custom-select-field"
                    size="large"
                    style={{ width: "100%" }}
                    value={values.serious}
                    onChange={(val) => {
                      setFieldValue(`serious`, val);
                    }}
                  >
                    <Select.Option key={"yes"} value={"yes"}>
                      Yes
                    </Select.Option>
                    <Select.Option key={"no"} value={"no"}>
                      No
                    </Select.Option>
                  </Select>
                  <div className="input-error">
                    {touched?.serious && errors?.serious}
                  </div>
                </div>
                <div>
                  <div className="text-secondary font-m fw-medium mb-1">
                    AE Treatment
                  </div>
                  <Select
                    className="custom-select-field"
                    size="large"
                    style={{ width: "100%" }}
                    value={values.aeTreatment}
                    onChange={(val) => {
                      setFieldValue(`aeTreatment`, val);
                    }}
                  >
                    {AETreatmentTypes.map((type) => {
                      return (
                        <Select.Option key={type} value={type}>
                          {type}
                        </Select.Option>
                      );
                    })}
                  </Select>
                  <div className="input-error">
                    {touched?.aeTreatment && errors?.aeTreatment}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="text-secondary font-m fw-medium mb-1">
                      Assessor Initials
                    </div>
                    <Input
                      name="accessorInitials"
                      value={values.accessorInitials}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="singer-input"
                      placeholder="Assessor Initials"
                    />
                    <div className="input-error">
                      {errors.accessorInitials &&
                        touched.accessorInitials &&
                        errors.accessorInitials}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-secondary font-m fw-medium mb-1">
                      Assessed Date
                    </div>
                    <DatePicker
                      className="date-selector"
                      format="DD/MM/YYYY"
                      value={
                        values.accessorInitialsDate
                          ? moment(values.accessorInitialsDate, "DD/MM/YYYY")
                          : null
                      }
                      style={{
                        borderRadius: "16px",
                        height: "40px",
                      }}
                      onChange={(_1, dateString) => {
                        setFieldValue("accessorInitialsDate", dateString);
                      }}
                      inputReadOnly
                    />
                    <div className="input-error">
                      {errors.accessorInitialsDate &&
                        touched.accessorInitialsDate &&
                        errors.accessorInitialsDate}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="text-secondary font-m fw-medium mb-1">
                      PI Initials
                    </div>
                    <Input
                      name="piInitials"
                      value={values.piInitials}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="singer-input"
                      placeholder="PI Initials"
                    />
                    <div className="input-error">
                      {errors.piInitials &&
                        touched.piInitials &&
                        errors.piInitials}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-secondary font-m fw-medium mb-1">
                      PI Date
                    </div>
                    <DatePicker
                      className="date-selector"
                      format="DD/MM/YYYY"
                      value={
                        values.piInitialsDate
                          ? moment(values.piInitialsDate, "DD/MM/YYYY")
                          : null
                      }
                      style={{
                        borderRadius: "16px",
                        height: "40px",
                      }}
                      onChange={(_1, dateString) => {
                        setFieldValue("piInitialsDate", dateString);
                      }}
                      inputReadOnly
                    />
                    <div className="input-error">
                      {errors.piInitialsDate &&
                        touched.piInitialsDate &&
                        errors.piInitialsDate}
                    </div>
                  </div>
                </div>
                {/* <div className="mb-3">
                  <div className="text-secondary font-m fw-medium mb-1">
                    File Upload
                  </div>
                  <Dragger
                    multiple={false}
                    accept="*"
                    beforeUpload={beforeUpload}
                    customRequest={(file) =>
                      handleImageUpload(file, setFieldValue)
                    }
                    showUploadList={false}
                    className="singer-drag-drop"
                  >
                    {values.fileUrl ? (
                      <span>File available. Drop Files to change</span>
                    ) : (
                      <>
                        <span className="ms-3">Drop Files to upload</span>
                      </>
                    )}
                  </Dragger>
                </div> */}
                <div>
                  <div>
                    <Checkbox
                      name="draft"
                      checked={values.draft}
                      onChange={(e) => {
                        setFieldValue("draft", e.target.checked);
                      }}
                    >
                      Draft
                    </Checkbox>
                  </div>
                </div>
                <div className="button-container mt-4">
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ height: "40px", borderRadius: "12px" }}
                    className="me-4"
                  >
                    <span className="fw-semibold font-sm">Save</span>
                  </Button>
                  <Button
                    style={{ height: "40px", borderRadius: "12px" }}
                    className="me-4"
                    onClick={closeHandler}
                  >
                    <span className="fw-semibold font-sm">Cancel</span>
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default AddIncidentModal;
