import { Table } from "antd";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { errorToastMessage } from "../../helpers/toastMessage";
import http from "../../http";
import { useAppDispatch } from "../../redux/hooks";
import { setAppLoader } from "../../redux/reducers/loaderSlice";
import { CustomPagination, PaginationText } from "../Common/TableUtils";

// const roles = ["All Users", "Admin", "Therapist", "User"];

// const eventTypes = [
//   "All Event Types",
//   "Field created",
//   "Report Step created",
//   "Field edited",
//   "Field deleted",
//   "Report Step deleted",
//   "Report Step edited",
// ];

const AuditLog = () => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any>([]);
  // const [eventType, setEventType] = useState("All Event Types");
  // const [role, setRole] = useState("All Users");
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);

  const fetchData = useCallback(
    async (page: number) => {
      try {
        dispatch(setAppLoader(true));
        const res = await http.get(`/programs/audit-logs?size=10&page=${page}`);
        const rows = res.data.data.rows.map((row: any) => {
          return {
            id: row.id,
            action: row.table + " - " + row.actionType,
            userName: row.userName,
            date: moment(row.createdAt).format("DD/MM/YYYY hh:mm:ss A"),
            newValues:
              Object.keys(row.newValues).length > 0
                ? JSON.stringify(row.newValues)
                : "-",
            oldValues:
              Object.keys(row.prevValues).length > 0
                ? JSON.stringify(row.prevValues)
                : "-",
          };
        });
        setCurrentPage(page);
        setData(rows);
        setTotal(res.data.data.count);
        dispatch(setAppLoader(false));
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    },
    [setData, setCurrentPage, setTotal, dispatch]
  );

  useEffect(() => {
    fetchData(1);
  }, [fetchData]);

  const columns = useMemo(() => {
    return [
      {
        title: "User",
        key: "userName",
        dataIndex: "userName",
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
      },
      {
        title: "Old Values",
        dataIndex: "oldValues",
        key: "oldValues",
      },
      {
        title: "New Values",
        dataIndex: "newValues",
        key: "newValues",
      },
    ];
  }, []);

  const onTableChange = async (pagination: any, filters: any) => {
    if (pagination.current) {
      fetchData(pagination.current);
    }
  };

  return (
    <div className="cms-wrapper">
      <div className="content-header">
        <div className="font-m fw-semibold font-l me-5 text-secondary">
          Audit Log
        </div>
        <span style={{ marginLeft: "auto" }}></span>
      </div>
      <div className="modules-wrapper question-module-wrapper">
        <Table
          dataSource={data}
          columns={columns}
          className="singer-custom-table"
          rowKey={(record) => record.id}
          onChange={onTableChange}
          pagination={{
            total: total,
            current: currentPage,
            showSizeChanger: false,
            pageSize: 10,
            hideOnSinglePage: true,
            className: "singer-table-pagination",
            itemRender: CustomPagination,
            showTotal: PaginationText,
            position: ["bottomLeft"],
          }}
        />
      </div>
    </div>
  );
};

export default AuditLog;

/* <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ margin: "0px 10px" }}>Filter on</span>
          <Select
            style={{ width: "250px" }}
            size="large"
            value={role}
            onChange={(val) => setRole(val)}
          >
            {roles.map((role: string) => {
              return (
                <Select.Option key={role} value={role}>
                  {role}
                </Select.Option>
              );
            })}
          </Select>
          <span style={{ margin: "0px 10px" }}>Filter on Event Type</span>
          <Select
            style={{ width: "250px" }}
            size="large"
            value={eventType}
            onChange={(val) => setEventType(val)}
          >
            {eventTypes.map((event: string) => {
              return (
                <Select.Option key={event} value={event}>
                  {event}
                </Select.Option>
              );
            })}
          </Select>
        </div> */
