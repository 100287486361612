import { Modal, Table } from "antd";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { errorToastMessage } from "../../../helpers/toastMessage";
import http from "../../../http";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import { hideModal } from "../../../redux/reducers/modalSlice";

const EproSubmissionLogModal = () => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any[]>([]);
  const { show, qid, id } = useAppSelector((state) => state.modal.modalProps);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        dispatch(setAppLoader(true));
        const res = await http.get(
          `/questionnaires/daily/summary/${id}/${qid}/versions`
        );
        const responses = res.data.data.map((instance: any) => {
          return {
            id: instance.id,
            version: instance.version,
            isDraft: instance.remark === "draft",
            completedAt: moment(instance.completedAt).format("DD/MM/YYYY"),
            user: instance?.who
              ? instance?.who?.firstName + " " + instance?.who?.lastName
              : "-",
          };
        });
        setData(responses);
        dispatch(setAppLoader(false));
      } catch (err) {
        errorToastMessage(err as Error);
        dispatch(setAppLoader(false));
      }
    };
    fetchDetails();
  }, [setData, dispatch, qid, id]);

  const closeHandler = () => {
    dispatch(hideModal());
  };

  const columns: any[] = useMemo(() => {
    return [
      {
        title: "Admin user",
        dataIndex: "user",
        key: "user",
        align: "center",
      },
      {
        title: "Completed Date",
        dataIndex: "completedAt",
        key: "completedAt",
      },
      {
        title: "Version",
        dataIndex: "version",
        key: "version",
      },
    ];
  }, []);

  return (
    <Modal
      title={"CRF Submission Logs"}
      visible={show}
      onCancel={closeHandler}
      width={"45rem"}
      footer={null}
    >
      <Table
        dataSource={data}
        columns={columns}
        className="singer-custom-table"
        rowKey={(record) => record.id}
        pagination={false}
      />
    </Modal>
  );
};

export default EproSubmissionLogModal;
