import { Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { errorToastMessage } from "../../helpers/toastMessage";
import http from "../../http";
import { useAppDispatch } from "../../redux/hooks";
import { setAppLoader } from "../../redux/reducers/loaderSlice";
import { CustomPagination, PaginationText } from "../Common/TableUtils";
import { useNavigate } from "react-router-dom";

export const severityMap: any = {
  mild: "Mild (Grade 1)",
  moderate: "Moderate (Grade 2)",
  severe: "Severe (Grade 3)",
  potential_life_threatening: "Potential Life Threatening (Grade 4)",
  death: "Death (Fatal)",
};

const IncidentReporting: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any[]>([]);
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchIncidents = async () => {
      try {
        dispatch(setAppLoader(true));
        const res = await http.get(
          `/mh/incident-reports?page=${current}&size=8`
        );
        const incidents = res.data.data.rows.map((incident: any) => {
          return {
            id: incident.id,
            participantId: incident?.participantId,
            subjectId: incident?.participant?.participant?.subjectId,
            type: incident.type,
            reportedOn: incident.reportedOn
              ? moment(incident.reportedOn).format("DD/MM/YYYY")
              : null,
            startDate: incident.startDate
              ? moment(incident.startDate).format("DD/MM/YYYY")
              : null,
            endDate: incident.endDate
              ? moment(incident.endDate).format("DD/MM/YYYY")
              : null,
            outcome: incident.outcome || "-",
            relationship: incident.relationship || "-",
            actionTaken: incident.actionTaken || "-",
            serious: incident.serious || false,
            isOngoing: incident.isOngoing || false,
            aeTreatment: incident.aeTreatment || "-",
            accessorInitials: incident.accessorInitials || "-",
            accessorInitialsDate: incident.accessorInitialsDate
              ? moment(incident.accessorInitialsDate).format("DD/MM/YYYY")
              : null,
            piInitials: incident.piInitials || "-",
            piInitialsDate: incident.piInitialsDate
              ? moment(incident.piInitialsDate).format("DD/MM/YYYY")
              : null,
            draft: incident.draft || false,
            comment: incident.comment,
            status: incident?.status,
            severity: incident?.severity,
          };
        });
        setTotal(res.data.data.count);
        setData(incidents);
        dispatch(setAppLoader(false));
      } catch (err) {
        dispatch(setAppLoader(false));
        errorToastMessage(err as Error);
      }
    };
    fetchIncidents();
  }, [dispatch, setData, current]);

  const crfNavigate = (patient: any) => {
    navigate(
      `/crf/study/${patient?.participantId}?patientId=${patient?.subjectId}`
    );
  };

  const columns = [
    {
      title: "Subject Id",
      dataIndex: "subjectId",
      key: "subjectId",
      render: (_1: any, record: any) => {
        return (
          <span
            onClick={() => crfNavigate(record)}
            className="color-primary cp"
          >
            {record?.subjectId || "-"}
          </span>
        );
      },
    },
    {
      title: "Date Reported",
      dataIndex: "reportedOn",
      key: "date",
      render: (date: string) => {
        return date || "-";
      },
    },
    {
      title: "Adverse Event Description",
      dataIndex: "comment",
      key: "comment",
      render: (comment: string) => {
        return comment || "-";
      },
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "date",
      render: (date: string) => {
        return date || "-";
      },
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "date",
      render: (date: string) => {
        return date || "-";
      },
    },
    {
      title: "Severity",
      dataIndex: "severity",
      key: "severity",
      render: (severity: string) => {
        return severityMap[severity] || "-";
      },
    },
    {
      title: "Outcome",
      dataIndex: "outcome",
      key: "outcome",
      render: (outcome: string) => {
        return outcome || "-";
      },
    },
    {
      title: "Relationship",
      dataIndex: "relationship",
      key: "relationship",
      render: (relationship: string) => {
        return relationship || "-";
      },
    },
    {
      title: "Action Taken",
      dataIndex: "actionTaken",
      key: "actionTaken",
      render: (actionTaken: string) => {
        return actionTaken || "-";
      },
    },
    {
      title: "Serious",
      dataIndex: "serious",
      key: "serious",
      render: (serious: string) => {
        return serious ? "Yes" : "No";
      },
    },
    {
      title: "Ongoing",
      dataIndex: "isOngoing",
      key: "isOngoing",
      render: (ongoing: boolean) => {
        return ongoing ? "Yes" : "No";
      },
    },
    {
      title: "AE Treatment",
      dataIndex: "aeTreatment",
      key: "aeTreatment",
      render: (aeTreatment: string) => {
        return aeTreatment || "-";
      },
    },
    {
      title: "Assessor Initials",
      dataIndex: "accessorInitials",
      key: "accessorInitials",
      render: (accessorInitials: string) => {
        return accessorInitials || "-";
      },
    },
    {
      title: "Assessed Date",
      dataIndex: "accessorInitialsDate",
      key: "accessorInitialsDate",
      render: (accessorInitialsDate: string) => {
        return accessorInitialsDate || "-";
      },
    },
    {
      title: "PI Initials",
      dataIndex: "piInitials",
      key: "piInitials",
      render: (piInitials: string) => {
        return piInitials || "-";
      },
    },
    {
      title: "PI Date",
      dataIndex: "piInitialsDate",
      key: "piInitialsDate",
      render: (piInitialsDate: string) => {
        return piInitialsDate || "-";
      },
    },
  ];

  const onTableChange = async (pagination: any) => {
    if (pagination.current) {
      setCurrent(pagination.current);
    }
  };

  return (
    <div className="cms-wrapper">
      <div className="content-header">
        <div className="font-m fw-semibold font-l me-5 text-secondary">
          Adverse Events
        </div>
      </div>
      <div className="modules-wrapper question-module-wrapper">
        <Table
          dataSource={data}
          columns={columns}
          className="singer-custom-table"
          rowKey={(record) => record.id}
          onChange={onTableChange}
          scroll={{ x: "max-content" }}
          pagination={{
            current: current,
            total: total,
            showSizeChanger: false,
            pageSize: 8,
            hideOnSinglePage: true,
            className: "singer-table-pagination",
            itemRender: CustomPagination,
            showTotal: PaginationText,
            position: ["bottomLeft"],
          }}
        />
      </div>
    </div>
  );
};

export default IncidentReporting;
