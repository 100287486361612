type Props = {
  phases: any[];
  selectedPhase: any;
  selectPhase: Function;
};

const Phases: React.FC<Props> = ({ phases, selectPhase, selectedPhase }) => {
  return (
    <>
      {phases.map((phase) => {
        return (
          <div
            className={
              selectedPhase === phase.id
                ? "builder-block selected"
                : "builder-block"
            }
            onClick={() => selectPhase(phase.id)}
            key={phase.id}
          >
            <div className="text-truncate ms-2 font-m fw-medium">
              {phase.name}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Phases;
