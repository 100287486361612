import React, { useEffect, useMemo, useState } from "react";
import { Button, Dropdown, Input, Menu } from "antd";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { debounce } from "lodash";
import {
  setParticipantSearchTerm,
  // setParticipantSearchType,
  setSelectedParticipants,
} from "../../redux/reducers/participantSlice";
// import { addPrescreenedParticipant } from "../../redux/actions/adminAction";
import { PlusIcon } from "@heroicons/react/solid";
import { setModalDetails } from "../../redux/reducers/modalSlice";
import {
  canAddParticipant,
  // canAmendAppointments,
  // hasSubGroupAccess,
  canExportData,
} from "../../helpers/roles";

// type Props = {
//   type: string;
// };

const ParticpantHeader: React.FC = () => {
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.user.role);
  const { searchTerm, selectedParticipants, selectedTab } = useAppSelector(
    (state) => state.participant
  );
  const [search, setSearch] = useState<string>("");
  const [hasAdminAccess] = useState(canAddParticipant(role));
  // const [hasGroupAccess] = useState(hasSubGroupAccess(role));
  // const [apptAccess] = useState(canAmendAppointments(role));
  const [exportAccess] = useState(canExportData(role));

  useEffect(() => {
    setSearch(searchTerm);
  }, [searchTerm]);

  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        dispatch(setParticipantSearchTerm(val));
      }, 500),
    [dispatch]
  );

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    setSearch(val);
    modifySearchTerm(val);
  };

  const handleButtonClick = () => {
    // if (type === "pre-screened") {
    // dispatch(addPrescreenedParticipant());
    // }
    dispatch(
      setModalDetails({
        type: "ADD_PARTICIPANT",
        modalProps: {
          show: true,
        },
      })
    );
  };

  const exportCRF = () => {
    dispatch(
      setModalDetails({
        type: "CRF_EXPORT",
        modalProps: {
          show: true,
          participants: selectedParticipants,
        },
      })
    );
  };

  // const handleGroup = () => {
  //   dispatch(
  //     setModalDetails({
  //       type: "ADD_PARTICIPANT_GROUP",
  //       modalProps: {
  //         show: true,
  //       },
  //     })
  //   );
  // };

  // const scheduleAppointment = () => {
  //   dispatch(
  //     setModalDetails({
  //       type: "SCHEDULE_APPOINTMENT",
  //       modalProps: {
  //         show: true,
  //         invitees: selectedParticipants,
  //       },
  //     })
  //   );
  // };

  const menu = () => (
    <Menu style={{ minWidth: "175px", borderRadius: "12px" }}>
      {exportAccess && (
        <Menu.Item key="1" onClick={exportCRF}>
          <span>Export</span>
        </Menu.Item>
      )}
      {/* {apptAccess && (
        <Menu.Item key="2" onClick={scheduleAppointment}>
          <span>Schedule Appointment</span>
        </Menu.Item>
      )} */}
    </Menu>
  );

  const clearSelection = () => {
    dispatch(setSelectedParticipants([]));
  };

  // const typeChangeHandler = (type: string) => {
  //   dispatch(
  //     setParticipantSearchType({
  //       type,
  //     })
  //   );
  // };

  // useEffect(() => {
  //   if (type === "pre-screened") {
  //     dispatch(
  //       setParticipantSearchType({
  //         type: "screenId",
  //       })
  //     );
  //   }
  // }, [type, dispatch]);

  return (
    <div className="content-header">
      <div className="font-m fw-semibold font-l me-5 text-secondary">
        Participants
      </div>
      <div className="d-flex align-items-center">
        <Input
          onChange={changeHandler}
          defaultValue={search}
          className="singer-input"
          style={{ width: "300px" }}
          placeholder="Search for participants"
        />
        {/* {type !== "group" && (
        <Select
          defaultValue="screenId"
          style={{
            width: "150px",
          }}
          value={searchType}
          onChange={typeChangeHandler}
          size="large"
          className="custom-select-field left-none"
        >
          <Select.Option value="screenId">Screening Id</Select.Option>
          {type === "screened" && (
              <>
          <Select.Option value="subjectId">Subject Id</Select.Option>
          <Select.Option value="nickname">Nickname</Select.Option>
           </> 
             )} 
        </Select>
    )}  */}
      </div>
      <span style={{ marginLeft: "auto" }}></span>
      {selectedParticipants.length > 0 && (
        <>
          <Button
            type="primary"
            className="d-flex align-items-center me-4"
            style={{ height: "40px", borderRadius: "12px" }}
            onClick={clearSelection}
          >
            <span className="fw-semibold font-sm">Clear selection</span>
          </Button>
          {exportAccess && (
            <Dropdown
              overlay={menu}
              placement="bottomCenter"
              trigger={["click"]}
            >
              <Button
                type="primary"
                className="d-flex align-items-center me-4"
                style={{ height: "40px", borderRadius: "12px" }}
              >
                <span className="fw-semibold font-sm">Actions</span>
              </Button>
            </Dropdown>
          )}
        </>
      )}
      {selectedTab === "pre-screened" && hasAdminAccess && (
        <Button
          type="primary"
          onClick={handleButtonClick}
          className="d-flex align-items-center me-4"
          style={{ height: "40px", borderRadius: "12px" }}
        >
          <PlusIcon className="hero-small-icon me-2" />
          <span className="fw-semibold font-sm">Add Participant</span>
        </Button>
      )}
      {/* {type === "group" && hasGroupAccess && (
        <Button
          type="primary"
          onClick={handleGroup}
          className="d-flex align-items-center me-4"
          style={{ height: "40px", borderRadius: "12px" }}
        >
          <PlusIcon className="hero-small-icon me-2" />
          <span className="fw-semibold font-sm">Add Group</span>
        </Button>
      )} */}
    </div>
  );
};

export default ParticpantHeader;
